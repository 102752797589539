import { CircularProgress, Container } from "@mui/material";
import { useOrders } from "features/orders/useOrders";
import { useEffect } from "react";
import { CenterContainer, SectionSubTitle, SectionTitle } from "./style";
import { useUser } from "features/user/useUser";
import SearchOrders from "./components/SearchOrders";
import OrdersList from "./components/OrdersList";
import LoadMoreBtn from "components/LoadMoreBtn";
import { useTranslation } from "react-i18next";

export default function HistoryPage() {
  const { user } = useUser();
  const { t } = useTranslation();

  const {
    fetchOrders,
    loadingOrders,
    hasMore,
    error,
    revoke,
    fetchNextPageOfOrders,
    loadingNextPage,
  } = useOrders();

  useEffect(() => {
    fetchOrders();
    return () => revoke();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="md">
      {user?.role === "user" && (
        <SectionTitle>{t("trip_history")}</SectionTitle>
      )}

      {user?.role !== "user" && <SearchOrders />}

      {loadingOrders && (
        <CenterContainer>
          <CircularProgress color="info" size={100} />
        </CenterContainer>
      )}

      {!loadingOrders && !error && (
        <>
          <OrdersList />

          {hasMore && (
            <LoadMoreBtn
              handleClick={fetchNextPageOfOrders}
              loading={loadingNextPage}
            />
          )}
        </>
      )}

      {error && (
        <CenterContainer>
          <SectionSubTitle>{t("no_trips_found")}</SectionSubTitle>
        </CenterContainer>
      )}
    </Container>
  );
}
