import { styled } from "@mui/material/styles";
import { Box, Paper, Typography } from "@mui/material";
import { green, orange, grey, teal } from "@mui/material/colors";

export const SearchFormWrapper = styled(Paper)(({ theme: { spacing } }) => ({
  backgroundColor: "white",
  padding: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
  marginTop: spacing(2),
  marginBottom: spacing(2),
}));

type TPaymantStatusContainer = {
  props: { paymentStatus: string };
};

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const SearchContainer = styled(Paper)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [breakpoints.up("sm")]: { flexDirection: "row", alignItems: "center" },

    justifyContent: "space-between",
    padding: spacing(2),
    gap: spacing(2),
    borderRadius: spacing(1),

    marginTop: spacing(2),
    marginBottom: spacing(3),
    border: `1px solid ${grey[400]}`,
  })
);

export const CartContainer = styled(Paper)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: spacing(2),
    gap: spacing(2),
    borderRadius: spacing(1),
    border: `1px solid ${grey[400]}`,
  })
);

export const CenterContainer = styled(Box)(({ theme: { spacing } }) => ({
  textAlign: "center",
  marginTop: spacing(2),
}));

export const ColumnWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  gap: 2,
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const HeadWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  gap: 2,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const FormWrapp = styled("form")(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  gap: spacing(1),
}));

export const OrderTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: grey[600],
  fontSize: 12,
  fontWeight: 400,
}));

export const Title = styled(Typography)(({ theme: { spacing } }) => ({
  color: grey[600],
  fontSize: 14,
  "@media (min-width:600px)": {
    fontSize: 16,
  },

  fontWeight: 500,
}));

export const PaymantStatusContainer = styled(Box)<TPaymantStatusContainer>(
  ({ theme: { spacing }, props }) => ({
    padding: spacing(1),
    borderRadius: spacing(1),
    textAlign: "center",
    background:
      props.paymentStatus === "nopay"
        ? green[600]
        : props.paymentStatus === "paid"
        ? teal[500]
        : props.paymentStatus === "pending"
        ? orange[800]
        : undefined,
  })
);

export const PaymantStatusText = styled(Typography)(
  ({ theme: { spacing } }) => ({
    color: "#ffff",
    fontSize: 12,
    "@media (min-width:600px)": {
      fontSize: 14,
    },
    fontWeight: 500,
    span: { fontWeight: 500 },
  })
);

export const OrderTextWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  gap: spacing(1),
}));

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  "@media (min-width:600px)": {
    fontSize: 20,
  },
  marginTop: spacing(5),
  marginBottom: spacing(2),
}));

export const SectionSubTitle = styled(Typography)({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 14,
  "@media (min-width:600px)": {
    fontSize: 16,
  },
});

export const OrderSubText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  textWrap: "wrap",
  wordBreak: "break-all",
  "@media (min-width:600px)": {
    fontSize: 16,
  },
  fontWeight: 400,
}));

export const OrderWrapp = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
}));
