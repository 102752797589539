import { IBusesData } from "features/buses/types";
import { Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import WifiIcon from "@mui/icons-material/Wifi";
import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
import { grey } from "@mui/material/colors";
import dayjs from "dayjs";

import {
  ButtonContainer,
  CardContainer,
  CheckButton,
  DateText,
  DateWrapp,
  PointText,
  PriceBtnText,
  PriceText,
  RowTooltip,
  TimeDiff,
  TimeText,
  TooltipContainer,
} from "./style";
import { useTickets } from "features/tickets/useTickets";
import { IBusTo } from "features/tickets/types";
import { useSearchForm } from "features/searchform/useShopForm";
import { findePrice } from "./helpers";

interface IBusCartProps {
  busData: IBusesData;
  handleSelect?: (data: IBusTo) => void;
  adminCard?: boolean;
}

export default function BusCart({
  busData,
  handleSelect,
  adminCard,
}: IBusCartProps) {
  const { ticketsTo } = useTickets();
  const { adultCount, childrenCount } = useSearchForm();

  const matches = useMediaQuery("(min-width:900px)");
  const { t, i18n } = useTranslation();

  const {
    lineid,
    number,
    name,
    prices,
    routeId,
    busId,
    pointFromId,
    departureDate,
    departureTime,
    pointToId,
    arrivalDate,
    arrivalTime,
    parts,
    freeSeats,
    discounts,
  } = busData;

  const date1 = dayjs(`${departureDate}T${departureTime}:00`);
  const date2 = dayjs(`${arrivalDate}T${arrivalTime}:00`);

  const diffDate = date2.diff(date1, "hours", true);
  const hours = Math.floor(diffDate);
  const minutes = Math.round((diffDate - hours) * 60);

  return (
    <Grid>
      <CardContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={5}>
                <DateWrapp>
                  <TimeText>{departureTime}</TimeText>
                  <DateText>
                    {dayjs(departureDate)
                      .locale(i18n.language)
                      .format("DD MMM")}
                  </DateText>
                </DateWrapp>
                <PointText>
                  <span>{t(`cityKey-${pointFromId}`)}</span>,{" "}
                  {t(`pointKey-${pointFromId}`)}
                </PointText>
              </Grid>
              <Grid item xs={6} sm={6} md={5}>
                <DateWrapp>
                  <TimeText>{arrivalTime}</TimeText>
                  <DateText>
                    {dayjs(arrivalDate).locale(i18n.language).format("DD MMM")}
                  </DateText>
                </DateWrapp>

                <PointText>
                  <span>{t(`cityKey-${pointToId}`)}</span>,{" "}
                  {t(`pointKey-${pointToId}`)}
                </PointText>
              </Grid>
              {matches && (
                <Grid item xs={2} sx={{ textAlign: "end" }}>
                  <PriceText>
                    {ticketsTo
                      ? (ticketsTo.price - (ticketsTo.price * 20) / 100)
                          .toString()
                          .replace(/^(\d)(\d+)/, "$1 $2")
                      : findePrice(prices)
                          .toString()
                          .replace(/^(\d)(\d+)/, "$1 $2")}
                    <span>UAH</span>
                  </PriceText>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              sx={{
                alignItems: "center",
              }}
            >
              <TooltipContainer item xs={6}>
                <Tooltip
                  title={
                    <RowTooltip>
                      <RowTooltip>
                        <Typography sx={{ fontSize: 12 }}>
                          {t("free_wifi")}
                        </Typography>
                        <WifiIcon fontSize="small" sx={{ color: grey[400] }} />
                      </RowTooltip>
                      <RowTooltip>
                        <Typography sx={{ fontSize: 12 }}>
                          {t("220vt")}{" "}
                        </Typography>
                        <PowerOutlinedIcon
                          fontSize="small"
                          sx={{ color: grey[400] }}
                        />
                      </RowTooltip>
                    </RowTooltip>
                  }
                >
                  <RowTooltip>
                    <WifiIcon fontSize="small" sx={{ color: grey[400] }} />
                    <PowerOutlinedIcon
                      fontSize="small"
                      sx={{ color: grey[400] }}
                    />
                  </RowTooltip>
                </Tooltip>
                <Tooltip title={t("travel_time")}>
                  <RowTooltip>
                    <TimeDiff>
                      {`${hours.toString().padStart(2, "0")} ${t(
                        "travel_time_hou"
                      )} ${minutes.toString().padStart(2, "0")} ${t(
                        "travel_time_min"
                      )}`}
                    </TimeDiff>
                  </RowTooltip>
                </Tooltip>
              </TooltipContainer>
              <ButtonContainer item xs={6}>
                <CheckButton
                  variant="contained"
                  color="info"
                  disabled={
                    !freeSeats || adultCount + childrenCount > freeSeats.length
                  }
                  endIcon={matches && freeSeats && <ArrowForwardIosIcon />}
                  onClick={() => {
                    handleSelect &&
                      handleSelect({
                        lineid,
                        number,
                        name,
                        prices,
                        routeId,
                        busId,
                        pointFromId,
                        departureDate,
                        departureTime,
                        pointToId,
                        arrivalDate,
                        arrivalTime,
                        parts,
                        freeSeats,
                        discounts,
                        price: findePrice(prices)
                      });
                  }}
                >
                  {!freeSeats ||
                  adultCount + childrenCount > freeSeats.length ? (
                    t("sold_out")
                  ) : matches ? (
                    ticketsTo ? (
                      t("choose_ticket")
                    ) : (
                      t("keep")
                    )
                  ) : (
                    <PriceBtnText>
                      {ticketsTo
                        ? (ticketsTo.price - (ticketsTo.price * 20) / 100)
                            .toString()
                            .replace(/^(\d)(\d+)/, "$1 $2")
                        : findePrice(prices)
                            .toString()
                            .replace(/^(\d)(\d+)/, "$1 $2")}
                      <span>UAH</span>
                    </PriceBtnText>
                  )}
                </CheckButton>
              </ButtonContainer>
            </Grid>
          </Grid>
        </Grid>
      </CardContainer>
    </Grid>
  );
}
