import { useTickets } from "features/tickets/useTickets";
import {
  ButtonChoiseSeats,
  ButtonChoiseSeatsContainer,
  ButtonChoiseSeatsTitle,
  StyledArrowForwardIosIcon,
  StyledCheckCircleOutlineOutlinedIcon,
  StyledErrorOutlineIcon,
} from "../style";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface IChoiseSeatsBtnProps {
  isAllSeatsFilled: boolean;

  toggleDrawer: (
    inOpen: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

function ChoiseSeatsBtn({
  toggleDrawer,
  isAllSeatsFilled,
}: IChoiseSeatsBtnProps) {
  const { ticketsFrom } = useTickets();
  const { t } = useTranslation();

  return (
    <ButtonChoiseSeats
      size="small"
      variant="outlined"
      fullWidth
      color="info"
      disableRipple
      onClick={toggleDrawer(true)}
    >
      <ButtonChoiseSeatsContainer>
        <ButtonChoiseSeatsTitle>{t("choise_seats_btn")}</ButtonChoiseSeatsTitle>

        {!!ticketsFrom ? (
          isAllSeatsFilled ? (
            <StyledCheckCircleOutlineOutlinedIcon fontSize="medium" />
          ) : (
            <StyledErrorOutlineIcon fontSize="medium" />
          )
        ) : isAllSeatsFilled ? (
          <StyledCheckCircleOutlineOutlinedIcon fontSize="medium" />
        ) : (
          <StyledErrorOutlineIcon fontSize="medium" />
        )}
      </ButtonChoiseSeatsContainer>
      <StyledArrowForwardIosIcon />
    </ButtonChoiseSeats>
  );
}
export default memo(ChoiseSeatsBtn);
