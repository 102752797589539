import { Box, Container } from "@mui/material";
import BusRoutes from "components/BusRoutes";
import Main from "./components/Main";
import Seo from "components/Seo";

export default function FaqPage() {
  return (
    <>
      <Seo
        title={"seo.faq.title"}
        description={"seo.faq.description"}
        canonicalLink="faq"
      />
      <Box component="section">
        <Container maxWidth="lg">
          <Main />
        </Container>
      </Box>
      <Box component={"section"}>
        <Container maxWidth="lg">
          <BusRoutes />
        </Container>
      </Box>
    </>
  );
}
