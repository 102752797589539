import { IconButton, Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useOrders } from "features/orders/useOrders";

interface IDeleteBtnProps {
  paymentId: string;
}

export default function DeleteBtn({ paymentId }: IDeleteBtnProps) {
  const [loading, setLoading] = useState(false);
  const { deleteOrderById } = useOrders();

  const handlClick = (paymentId: string) => {
    setLoading(true);
    deleteOrderById(paymentId);
  };

  return (
    <Box>
      <IconButton
        disabled={loading}
        onClick={() => handlClick(paymentId)}
        color="success"
      >
        {!loading ? (
          <DeleteOutlineIcon />
        ) : (
          <CircularProgress color="secondary" size={24} />
        )}
      </IconButton>
    </Box>
  );
}
