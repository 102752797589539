import { Box, Container } from "@mui/material";
import { StyledBox, SectionTitle } from "./style";
import { useTranslation } from "react-i18next";
import Seo from "components/Seo";

export default function PrivacyPolicyPage() {
  const { t } = useTranslation();
  return (
    <>
      <Seo
        title={"seo.privacypolicy.title"}
        description={"seo.privacypolicy.description"}
        canonicalLink="privacypolicy"
      />

      <Box component={"section"}>
        <Container maxWidth="lg">
          <SectionTitle as={"h1"}>{t("privacy_policy_title")}</SectionTitle>
          <StyledBox>
            <ul>
              <li>
                <p>{t("privacy_policy_intro")}</p>
              </li>
              <li>
                <p>
                  <b>{t("definitions_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <p>{t("definitions.site_administration")}</p>
                  </li>
                  <li>
                    <p>{t("definitions.personal_data")}</p>
                  </li>
                  <li>
                    <p>{t("definitions.processing_of_personal_data")}</p>
                  </li>
                  <li>
                    <p>{t("definitions.confidentiality_of_personal_data")}</p>
                  </li>
                  <li>
                    <p>{t("definitions.website_user")}</p>
                  </li>

                  <li>
                    <p>{t("definitions.cookies")}</p>
                  </li>
                  <li>
                    <p>{t("definitions.ip_address")}</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>{t("general_provisions_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <p>{t("general_provisions.use_of_site")}</p>
                  </li>
                  <li>
                    <p>{t("general_provisions.disagreement_with_policy")}</p>
                  </li>
                  <li>
                    <p>{t("general_provisions.policy_scope")}</p>
                  </li>
                  <li>
                    <p>{t("general_provisions.administration_data_check")}</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>{t("subject_of_policy_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <p>
                      {t("subject_of_policy.obligations_of_administration")}
                    </p>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <p>{t("subject_of_policy.user_provided_data")}</p>
                      </li>
                      <li>
                        <p>{t("subject_of_policy.user_name")}</p>
                      </li>
                      <li>
                        <p>{t("subject_of_policy.user_phone")}</p>
                      </li>
                      <li>
                        <p>{t("subject_of_policy.user_email")}</p>
                      </li>
                      <li>
                        <p>{t("subject_of_policy.user_address")}</p>
                      </li>
                      <li>
                        <p>{t("subject_of_policy.user_photo")}</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <p>{t("subject_of_policy.auto_collected_data")}</p>
                      </li>
                      <li>
                        <p>{t("subject_of_policy.cookies_disabling")}</p>
                      </li>
                      <li>
                        <p>{t("subject_of_policy.ip_address_statistic")}</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>{t("subject_of_policy.other_personal_data")}</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>{t("purpose_of_data_collection_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <p>{t("purpose_of_data_collection.user_identification")}</p>
                  </li>
                  <li>
                    <p>
                      {t("purpose_of_data_collection.personal_data_access")}
                    </p>
                  </li>
                  <li>
                    <p>{t("purpose_of_data_collection.feedback_with_user")}</p>
                  </li>
                  <li>
                    <p>{t("purpose_of_data_collection.user_location")}</p>
                  </li>
                  <li>
                    <p>{t("purpose_of_data_collection.data_verification")}</p>
                  </li>
                  <li>
                    <p>{t("purpose_of_data_collection.account_creation")}</p>
                  </li>
                  <li>
                    <p>{t("purpose_of_data_collection.email_notifications")}</p>
                  </li>
                  <li>
                    <p>{t("purpose_of_data_collection.technical_support")}</p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "purpose_of_data_collection.special_offers_notifications"
                      )}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>{t("methods_and_terms_of_data_processing_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <p>
                      {t(
                        "methods_and_terms_of_data_processing.data_processing_methods"
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "methods_and_terms_of_data_processing.data_loss_notification"
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "methods_and_terms_of_data_processing.data_protection_measures"
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "methods_and_terms_of_data_processing.prevention_measures"
                      )}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>{t("rights_and_obligations_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <ul>
                      <li>
                        <p>
                          {t(
                            "rights_and_obligations.user_rights.free_decision"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t("rights_and_obligations.user_rights.update_data")}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "rights_and_obligations.user_rights.data_processing_information"
                          )}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <p>
                          {t(
                            "rights_and_obligations.administration_obligations.data_usage"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "rights_and_obligations.administration_obligations.confidentiality_obligation"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "rights_and_obligations.administration_obligations.preventive_measures"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "rights_and_obligations.administration_obligations.data_blocking"
                          )}
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>{t("responsibility_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <p>{t("responsibility.administration_responsibility")}</p>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <p>
                          {t(
                            "responsibility.data_loss_or_disclosure_responsibility"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>{t("responsibility.public_info")}</p>
                      </li>
                      <li>
                        <p>{t("responsibility.third_party_data")}</p>
                      </li>
                      <li>
                        <p>{t("responsibility.user_consent_disclosure")}</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      {t(
                        "responsibility.user_responsibility_for_legislation_compliance"
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "responsibility.user_responsibility_for_provided_info"
                      )}
                    </p>
                  </li>
                  <li>
                    <p>{t("responsibility.intellectual_property_respect")}</p>
                  </li>
                  <li>
                    <p>{t("responsibility.public_content_distribution")}</p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "responsibility.no_admin_responsibility_for_user_content"
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "responsibility.no_admin_responsibility_for_user_behaviour"
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "responsibility.no_admin_responsibility_for_user_info"
                      )}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>{t("dispute_resolution_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <p>{t("dispute_resolution.pre_court_proceedings")}</p>
                  </li>
                  <li>
                    <p>{t("dispute_resolution.claim_response_time")}</p>
                  </li>
                  <li>
                    <p>
                      {t("dispute_resolution.arbitration_court_resolution")}
                    </p>
                  </li>
                  <li>
                    <p>{t("dispute_resolution.policy_legislation")}</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>{t("additional_terms_title")}</b>
                </p>
              </li>
              <li>
                <ul>
                  <li>
                    <p>{t("additional_terms.policy_changes")}</p>
                  </li>
                  <li>
                    <p>{t("additional_terms.new_policy_effective_date")}</p>
                  </li>
                  <li>
                    <p>{t("additional_terms.suggestions_and_questions")}</p>
                  </li>
                  <li>
                    <p>{t("additional_terms.current_policy_location")}</p>
                  </li>
                  <li>
                    <p>{t("additional_terms.updated_date")}</p>
                  </li>
                </ul>
              </li>
            </ul>
          </StyledBox>
        </Container>
      </Box>
    </>
  );
}
