import { saveAs } from 'file-saver'
import { getTicketPDF } from './functions'
 
export interface IProps {
  paymentRefId: string
 
}

export type TPodPdf = any

export const getPodPdf = async ({
  paymentRefId,
   
}: // eslint-disable-next-line consistent-return
IProps): Promise<TPodPdf> => {
  try {
    await getTicketPDF({ paymentRefId }).then((response:any) => {
      const byteCharacters = atob(response.data)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      // @ts-ignore
      const blob = new Blob([byteArray], {
        type: 'application/pdf',
        
      })

      saveAs(blob,'Квитки')
    })
  } catch (error) {
    return 'error'
  }
}
