import { Grid } from "@mui/material";
import { useOrders } from "features/orders/useOrders";
import OrderCart from "./OrderCart";
import { nanoid } from "nanoid";

export default function OrdersList() {
  const { orders } = useOrders();
  return (
    <Grid container spacing={2} alignItems="flex-start">
      {orders &&
        Object.keys(orders).map((id) => {
          const e = orders[id];
          return e && <OrderCart key={nanoid()} order={e} />;
        })}
    </Grid>
  );
}
