import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ChildrenDiscount() {
  const { t } = useTranslation();

  return (
    <TextField
      value={t("discountKey-20")}
      size="small"
      fullWidth
      disabled
      inputProps={{
        readOnly: true,
      }}
    />
  );
}
