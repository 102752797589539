import { Box, Container } from "@mui/material";

import Main from "./components/Main";
import BusRoutes from "components/BusRoutes";
import Seo from "components/Seo";

export default function ContactsPage() {
  return (
    <>
      <Seo
        title={"seo.contacts.title"}
        description={"seo.contacts.description"}
        canonicalLink="contacts"
      />
      <Box component="section">
        <Container maxWidth="lg">
          <Main />
        </Container>
      </Box>
      <Box component="section">
        <Container maxWidth="lg">
          <BusRoutes />
        </Container>
      </Box>
    </>
  );
}
