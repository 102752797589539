import { useTranslation } from "react-i18next";

export type FaqSubItem = {
  id: string;
  subQuestion: string;
  subAnswer: JSX.Element | string;
};

export type FaqItem = {
  id: string;
  question: string;
  answer: FaqSubItem[];
};

export type IFaqData = { [key: string]: FaqItem[] };

const useFaqData = () => {
  const { t } = useTranslation();

  const general: FaqItem[] = [
    {
      id: "panel1",
      question: t("purchase_tickets"),
      answer: [
        {
          id: "subpanel1",
          subQuestion: t("how_to_pay_order"),
          subAnswer: (
            <ul>
              <li>
                {t("you_can_pay_with_google_pay_apple_pay_visa_mastercard")}
              </li>
            </ul>
          ),
        },
        {
          id: "subpanel2",
          subQuestion: t("no_bank_card_cash_only_how_to_pay"),
          subAnswer: (
            <ul>
              <li>{t("we_offer_free_reservation_pay_before_boarding")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel3",
          subQuestion: t("payment_not_successful_what_to_do"),
          subAnswer: (
            <ul>
              <li>{t("insufficient_funds")}</li>
              <li>{t("internet_payment_not_activated")}</li>
              <li>{t("exceeded_online_payment_limit")}</li>
              <li>{t("international_payments_not_enabled")}</li>
              <li>{t("contact_bank_and_try_again")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel4",
          subQuestion: t("order_paid_no_ticket_why"),
          subAnswer: (
            <ul>
              <li>{t("check_email_for_ticket")}</li>
              <li>{t("check_spam_folder")}</li>
              <li>{t("verify_email_address")}</li>
              <li>{t("contact_support_if_no_ticket")}</li>
              <li>{t("download_ticket_from_personal_account")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel5",
          subQuestion: t("need_to_print_ticket"),
          subAnswer: (
            <ul>
              <li>{t("show_ticket_on_mobile_or_print")}</li>
              <li>{t("always_have_accessible_ticket")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel6",
          subQuestion: t("how_to_change_ticket_date"),
          subAnswer: (
            <ul>
              <li>{t("contact_support_to_change_date")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel7",
          subQuestion: t("change_departure_arrival_point"),
          subAnswer: (
            <ul>
              <li>{t("contact_support_to_change_point")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel8",
          subQuestion: t("discounts"),
          subAnswer: (
            <ul>
              <li>{t("children_discount")}</li>
              <li>{t("students_discount")}</li>
              <li>{t("seniors_discount")}</li>
              <li>{t("round_trip_discount")}</li>
              <li>{t("seventh_trip_free")}</li>
            </ul>
          ),
        },
      ],
    },
    {
      id: "panel2",
      question: t("trip_questions"),
      answer: [
        {
          id: "subpanel1",
          subQuestion: t("departure_arrival_time_local"),
          subAnswer: (
            <ul>
              <li>{t("time_is_local")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel2",
          subQuestion: t("where_does_bus_depart_from"),
          subAnswer: (
            <ul>
              <li>{t("departure_address_in_ticket")}</li>
              <li>{t("contact_support_if_no_bus_seen")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel3",
          subQuestion: t("schedule_accuracy"),
          subAnswer: (
            <ul>
              <li>{t("schedule_may_vary_due_to_conditions")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel4",
          subQuestion: t("cannot_see_vehicle_at_departure_time"),
          subAnswer: (
            <ul>
              <li>{t("contact_support_if_no_vehicle_seen")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel5",
          subQuestion: t("is_route_direct_or_with_transfer"),
          subAnswer: (
            <ul>
              <li>{t("contact_support_for_transfer_info")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel6",
          subQuestion: t("can_departure_be_delayed"),
          subAnswer: (
            <ul>
              <li>{t("contact_support_if_late")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel7",
          subQuestion: t("baggage_transportation_rules"),
          subAnswer: (
            <ul>
              <li>{t("one_free_baggage_up_to_25kg")}</li>
              <li>{t("second_baggage_fee")}</li>
              <li>{t("large_baggage_fee")}</li>
              <li>{t("no_live_animals_or_mail")}</li>
            </ul>
          ),
        },
      ],
    },
    {
      id: "panel3",
      question: t("ticket_refund"),
      answer: [
        {
          id: "subpanel1",
          subQuestion: t("why_reservation_cancelled"),
          subAnswer: (
            <ul>
              <li>{t("free_reservation_confirmation")}</li>
              <li>{t("reservation_cancelled_if_no_contact")}</li>
              <li>{t("paid_reservations_valid_until_departure")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel2",
          subQuestion: t("how_to_refund_ticket"),
          subAnswer: (
            <ul>
              <li>{t("refund_according_to_law")}</li>
              <li>{t("refund_requests_timing")}</li>
              <li>{t("check_with_support_for_refund_conditions")}</li>
            </ul>
          ),
        },
        {
          id: "subpanel3",
          subQuestion: t("trip_cancelled_what_to_do"),
          subAnswer: (
            <ul>
              <li>{t("inform_passengers_of_cancellation")}</li>
              <li>{t("full_refund_if_no_alternative")}</li>
            </ul>
          ),
        },
      ],
    },
  ];

  return { general };
};

export default useFaqData;
