import { Box, Grid, Paper } from "@mui/material";
import { ITticket } from "features/reports/types";
import { ColumnWrapp, OrderSubText, OrderTitle } from "../style";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface IReportElProps {
  el: ITticket;
}

export default function ReportEl({ el }: IReportElProps) {
  const { t } = useTranslation();
  const regex = /(.*?)(?:EastExpressUkraine\.)/;
  const getTrimmedInfo = (info: string | undefined): string => {
    if (!info) return "";
    const match = info.match(regex);
    return match ? match[1].trim() : "";
  };
  return (
    <Grid item xs={12} sm={6}>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <OrderSubText sx={{ textAlign: "left", fontWeight: 600, mb: 1 }}>
        {t(`cityKey-${el.pointFrom}`) || "Unknown City"} - {t(`cityKey-${el.pointTo}`) || "Unknown City"}
        </OrderSubText>

 
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ColumnWrapp>
            <OrderTitle>Пасажир</OrderTitle>
            <OrderSubText>{`${el.name} ${el.surname}`}</OrderSubText>
          </ColumnWrapp>
          <ColumnWrapp>
            <OrderTitle>Телефон</OrderTitle>
            <OrderSubText>{el.phone}</OrderSubText>
          </ColumnWrapp>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ColumnWrapp>
            <OrderTitle>Дата відправки</OrderTitle>
            <OrderSubText>
              {dayjs(el.departureDate).format("DD.MM.YYYY")}
            </OrderSubText>
          </ColumnWrapp>

          <ColumnWrapp>
            <OrderTitle>Вартість</OrderTitle>
            <OrderSubText>{el.price} UAH</OrderSubText>
          </ColumnWrapp>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ColumnWrapp>
            <OrderTitle>Знижка</OrderTitle>
            <OrderSubText>{t(`discountKey-${el.discountId}`)}</OrderSubText>
          </ColumnWrapp>
          <ColumnWrapp>
            <OrderTitle>Примітки</OrderTitle>
            <OrderSubText>{getTrimmedInfo(el.info)}</OrderSubText>
          </ColumnWrapp>
        </Box>
      </Paper>
    </Grid>
  );
}
