import { Box, styled, Typography } from "@mui/material";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  ul: {
    display: "flex",
    flexDirection: "column",
    gap: spacing(1),

    li: {
      p: {
        color: PrimeTexrColor,
        fontWeight: 300,
        fontSize: 12,
        "@media (min-width:600px)": {
          fontSize: 14,
        },
      },
    },
  },
}));

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  marginBottom: spacing(1),
  marginTop: spacing(6),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginBottom: spacing(2),
    marginTop: spacing(10),
  },
}));
