import { Grid } from "@mui/material";

import { SectionTitle } from "../style";
import UkraineContacts from "./UkraineContacts";
import PrahaContacts from "./PrahaContacts";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitle>{t("contacts")}</SectionTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={12}>
          <UkraineContacts />
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <PrahaContacts />
        </Grid>
      </Grid>
    </>
  );
}
