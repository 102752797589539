import {
  ContactsListLinks,
  ContactUsTitle,
  SectionContainer,
  ServiceOfficeItem,
  ServiceText,
  ServiceTextWrapp,
  ServiceTitle,
} from "../style";
import { Box, Grid } from "@mui/material";
import cz from "../images/cz.png";
import ua from "../images/ua.png";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useTranslation } from "react-i18next";

export default function ServiceOffice() {
  const { t } = useTranslation();
  return (
    <SectionContainer>
      <Grid container spacing={2}>
        <Grid item>
          <ContactUsTitle>{t("branches")}</ContactUsTitle>
        </Grid>
        <Grid item xs={12}>
          <ServiceTextWrapp>
            <ServiceOfficeItem>
              <img src={cz} alt="cz" width={25} height={15} />
              <Box>
                <ServiceTitle>{t("czech_republic_prague")}</ServiceTitle>
                <ServiceText>
                  {t("florenc_bus_station_krizikova_6")}
                </ServiceText>
              </Box>
            </ServiceOfficeItem>
            <ServiceOfficeItem>
              <AlternateEmailIcon color="action" fontSize="small" />
              <ContactsListLinks to={"mailto:rezervacejizdenek@musiltour.cz"}>
                rezervacejizdenek@musiltour.cz
              </ContactsListLinks>
            </ServiceOfficeItem>
          </ServiceTextWrapp>
        </Grid>
        <Grid item xs={12}>
          <ServiceTextWrapp>
            <ServiceOfficeItem>
              <img src={ua} alt="ua" width={25} height={15} />

              <Box>
                <ServiceTitle>{t("ukraine_zaporizhzhia")}</ServiceTitle>
                <ServiceText>
                  {t("central_bus_station_sobornyi_avenue_20")}
                </ServiceText>
              </Box>
            </ServiceOfficeItem>
            <ServiceOfficeItem>
              <AlternateEmailIcon color="action" fontSize="small" />
              <ContactsListLinks to={"mailto:Praha-bus@ukr.net"}>
                Praha-bus@ukr.net
              </ContactsListLinks>
            </ServiceOfficeItem>
          </ServiceTextWrapp>
        </Grid>
      </Grid>
    </SectionContainer>
  );
}
