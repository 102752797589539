import { Grid } from "@mui/material";
import React from "react";
import AdressList from "./AdressList";
import ContactUs from "./ContactUs";

export default function Main() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <AdressList />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <ContactUs />
      </Grid>
    </Grid>
  );
}
