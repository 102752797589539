import { Box, Grid, IconButton, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  DeleteIconRed,
  FeedbackHeadText,
  FeedbacksActionsAside,
  FeedbacksAvatar,
  FeedbackSecondText,
  RecommendIconGreen,
  RowContainer,
  StyledStarIcon,
} from "./style";
import { useReviews } from "features/reviews/useReviews";
import { useUser } from "features/user/useUser";
import { IReviews } from "./types";

interface IFeedbacksCartProps {
  feedback: IReviews;
  isAdmin: boolean;
}

const SectionWrapp = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  margin: theme.spacing(1),
  width: "100%",
  color: theme.palette.text.primary,
  textAlign: "left",
}));

const Figure = styled(Box)(({ theme }) => ({
  background: "#fff",
  padding: theme.spacing(2),
  borderRadius: 8,
  position: "relative",
  zIndex: 1000,
}));

const Arrow = styled(Box)(({ theme }) => ({
  position: "absolute",
  content: '" "',
  width: 40,
  height: 40,
  background: "#fff",
  transform: "rotate(-45deg)",
  borderTopRightRadius: "100%",

  bottom: -20,
  left: 15,
}));

export default function NewFeedbacks({
  feedback,
  isAdmin,
}: IFeedbacksCartProps) {
  const { deleteReviewById, approveReviewById } = useReviews();
  const { role } = useUser();
  const { rating, userName, text, date, docId, approved } = feedback;
  const userRole = role === "admin";

  return (
    <Grid item xs={12} sm={6}>
      <SectionWrapp>
        <Figure>
          {isAdmin && userRole && (
            <FeedbacksActionsAside>
              <IconButton
                aria-label="delete"
                onClick={() => deleteReviewById(docId!)}
              >
                <DeleteIconRed />
              </IconButton>
              <IconButton
                aria-label="approved"
                onClick={() => approveReviewById(docId!)}
                disabled={approved}
              >
                <RecommendIconGreen />
              </IconButton>
            </FeedbacksActionsAside>
          )}
          {text}
          <Arrow></Arrow>

          <RowContainer>
            <Box>
              <FeedbackSecondText>
                {dayjs(date?.seconds! * 1000).format("DD.MM.YYYY")}
              </FeedbackSecondText>
            </Box>
          </RowContainer>
        </Figure>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            marginTop: 5,
            marginLeft: 2,
          }}
        >
          <FeedbacksAvatar aria-label="recipe">
            {userName?.split("")[0]}
          </FeedbacksAvatar>
          <Typography>{userName}</Typography>
          <RowContainer>
            <StyledStarIcon />
            <FeedbackHeadText>{rating}</FeedbackHeadText>
          </RowContainer>
        </Box>
      </SectionWrapp>
    </Grid>
  );
}
