import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useField } from "formik";
import { memo } from "react";

interface ICustomDatePickerProps {
  name: string;
  label: string;
  backPicker?: boolean;
  mindate?: boolean;
}

const DatePicker = ({ name, label }: ICustomDatePickerProps) => {
  const [field, meta, helpers] = useField(name);

  return (
    <MuiDatePicker
      {...field}
      format="DD MMM"
      label={label}
      value={dayjs(field.value)}
      onChange={(newValue) => {
        helpers.setValue(dayjs(newValue).format("YYYY-MM-DD"));
      }}
      slotProps={{
        textField: () => ({
          readOnly: true,
          fullWidth: true,
          name: field.name,
          size: "small",
          error: meta.touched && Boolean(meta.error),
        }),
      }}
    />
  );
};

export default memo(DatePicker);
