import { Grid } from "@mui/material";
import { contactData } from "components/NavBar/constans";
import { nanoid } from "nanoid";

import {
  AdressTitle,
  ContactsItemWrapper,
  ContactsListItem,
  ContactsListLink,
  SectionContainer,
} from "../style";
import { useTranslation } from "react-i18next";

export default function UkraineContacts() {
  const { t } = useTranslation();

  return (
    <SectionContainer props={{ contacts: true }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AdressTitle>{t("companyNameUkraine")}</AdressTitle>
        </Grid>
        {contactData.map((e) => (
          <Grid item xs={12} key={nanoid()}>
            <ContactsListItem>
              {typeof e.icon !== "string" ? (
                <ContactsItemWrapper>{e.icon}</ContactsItemWrapper>
              ) : (
                <ContactsItemWrapper>
                  <img
                    src={e.icon}
                    alt={`Contact icon for ${e.label}`}
                    width={20}
                    height={20}
                  />
                </ContactsItemWrapper>
              )}
              <ContactsListLink props={{ contacts: true }} to={e.link}>
                {e.label}
              </ContactsListLink>
            </ContactsListItem>
          </Grid>
        ))}
      </Grid>
    </SectionContainer>
  );
}
