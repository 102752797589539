import { Box } from "@mui/material";
import { styled } from "@mui/system";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { grey } from "@mui/material/colors";
const PrimeTexrColor = `rgb(66, 69, 67)`;

export const SuccessfulContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing(2),
  marginTop: spacing(10),
}));

export const SuccessfulTitle = styled(Box)(({ theme: { spacing } }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: PrimeTexrColor,
  "@media (min-width:600px)": {
    fontSize: 18,
  },
}));
export const SuccessfulSubTitle = styled(Box)(({ theme: { spacing } }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: grey[600],

  "@media (min-width:600px)": {
    fontSize: 14,
  },
}));

export const StyleDownloadingIcon = styled(DownloadingIcon)(
  ({ theme: { spacing } }) => ({
    fontSize: 28,

    "@media (min-width:600px)": {
      fontSize: 28,
    },
  })
);

export const LoadTicketBtnBox = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: spacing(0.5),
}));

export const LoadTicketBtnText = styled(Box)(({ theme: { spacing } }) => ({
  fontSize: 12,
  color: grey[600],

  "@media (min-width:600px)": {
    fontSize: 14,
  },

  "&:hover, &:focus, &:active": {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
