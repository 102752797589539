import { ReactNode } from "react";
import { nanoid } from "nanoid";
import {
  IDiscount,
  ISeat,
  IPrice,
  InormalizeDataProps,
  IPassengers,
  IisAllSeatsFilledProps,
} from "../types";

import { toast } from "react-toastify";

export const passengersCountAvailable = (
  passengers: IPassengers[],
  to?: boolean
) => {
  return passengers.filter(({ seatNumberTo, seatNumberFrom }) =>
    to ? seatNumberTo !== "" : seatNumberFrom !== ""
  ).length;
};

export const passengersDirectionsCount = (
  passengers: IPassengers[],
  to?: boolean
) => {
  return passengers.filter(({ seatNumberTo, seatNumberFrom }) =>
    to ? seatNumberTo !== "" : seatNumberFrom !== ""
  ).length;
};

export const FindeLastSeatNumber = (freeSeats: ISeat[] | null) => {
  let maxNumber = 0;
  if (freeSeats) {
    freeSeats.forEach((row) => {
      if (row && Number(row.number) > maxNumber) {
        maxNumber = Number(row.number);
      }
    });
  }
  return maxNumber;
};

export const generateSeats = (lastSeatNumber: number) => {
  const seats = [];
  let currentId = 0;
  const minSeatsInRow = 68;
  const seatsInRow = Math.max(minSeatsInRow, lastSeatNumber);

  const rowCount = Math.ceil(Math.ceil(seatsInRow) / 4);

  for (let row = 1; row <= rowCount; row += 1) {
    const rowSeats = [];

    for (let col = 0; col < 4; col += 1) {
      const seatNumber = (row - 1) * 4 + col + 1;

      if (seatNumber <= seatsInRow) {
        rowSeats.push({
          id: `${(currentId += 1)}`,
          number: `${seatNumber}`,
          isSelected: false,
          isActive: true,
          available: true,
        });
      }
    }

    seats.push(rowSeats);
  }

  return seats;
};

export const createSeatsList = (freeSeats: ISeat[] | null = null) => {
  const lastNum = FindeLastSeatNumber(freeSeats);

  return generateSeats(lastNum).map((row) =>
    row.map((seat) => {
      if (seat !== null) {
        const correspondingSeat = freeSeats?.find((busSeat) => {
          return Number(busSeat.id) === Number(seat.id);
        });

        if (correspondingSeat) {
          return {
            ...seat,
            isSelected: correspondingSeat.isSelected,
            isActive: correspondingSeat.isActive,
          };
        } else {
          return { ...seat, isSelected: false, isActive: false };
        }
      } else {
        return null;
      }
    })
  );
};

export const createPasList = (
  adult: number = 0,
  children: number = 0,
  priceTo: number = 0,
  priceFrom: number = 0,
  childrenPriceFrom: number = 0,
  from: boolean
) => {
  let list = [];
  for (let i = 0; i < adult; i += 1) {
     list.push({
       isChildren: false,
       id: nanoid(),
       seatNumberTo: "",
       seatNumberFrom: "",
       name: "",
       surname: "",
       priceTo,
       priceFrom: from ? priceTo : priceFrom,
       discount: "0",
       discountFrom: "18",
       notes: "",
       currency: "UAH",
     });
   }

  for (let i = 0; i < children; i += 1) {
    list.push({
      isChildren: true,
      id: nanoid(),
      seatNumberTo: "",
      seatNumberFrom: "",
      name: "",
      surname: "",
      priceTo,
      priceFrom: childrenPriceFrom,
      discount: "20",
      discountFrom: "20",
      notes: "",
      currency: "UAH",
    });
  }

  return list;
};

export const filterDiscount = (
  discountList: IDiscount[] = [],
  isChildrenDiscount: boolean
) => {
  return discountList.filter((e) => {
    if (isChildrenDiscount) {
      return e.DiscountId === "20";
    }
    return e.DiscountId !== "20" && e.DiscountId !== "23";
  });
};

export const findePrice = (prices: IPrice[] = []) =>
  Math.floor(Number(prices.find((price) => price.Currency === "UAH")?.Amount));

export const calculatePrice = (discount: string, price: number) => {
  switch (discount) {
    case "0":
      return price;

    case "17":
      return price - (price * 10) / 100;

    case "21":
      return price;

    case "23":
      return price - (price * 10) / 100;

    case "24":
      return price - (price * 10) / 100;

    case "18":
      return price - (price * 20) / 100;

    case "20":
      return price - (price * 50) / 100;

    case "22":
      return 0;

    default:
      return price;
  }
};

// export const totalPrice = (passengers: IPassengers[], direction: string) =>
//   passengers.reduce((acc, e) => {
//     const price =
//       direction === "to"
//         ? calculatePrice(e.discount, e.priceTo)
//         : e.discountFrom === "18"
//         ? e.priceFrom
//         : calculatePrice(e.discountFrom || "", e.priceFrom);
//     return acc + price;
//   }, 0);

export const totalPrice = (passengers: IPassengers[], direction: string) =>
  passengers.reduce((acc, e) => {
    const price =
      direction === "to"
        ? calculatePrice(e.discount, e.priceTo):
        calculatePrice(e.discountFrom , e.priceFrom)
    return acc + price;
  }, 0);

export const normalizeData = ({
  ticketsTo,
  ticketsFrom,
  formData,
  userId,
}: InormalizeDataProps) => {
  let normalize = [];
  const { passengers } = formData;

  if (!!ticketsTo) {
    for (let i = 0; i < passengers.length; i += 1) {
      normalize.push({
        userId: userId,
        userPhone: formData.phone,
        userEmail: formData.email,
        price: ticketsTo.price,
        direction: "to",
        lineId: ticketsTo.lineid,
        departureDate: ticketsTo.departureDate,
        routeId: ticketsTo.routeId,
        arrivalDate: ticketsTo.arrivalDate,
        busId: -1,
        pointFrom: ticketsTo.pointFromId,
        pointTo: ticketsTo.pointToId,
        seat: passengers[i].seatNumberTo,
        discountId: passengers[i].discount,
        currency: "UAH",
        busName: ticketsTo.name,
        name: passengers[i].name,
        surname: passengers[i].surname,
        passport: "Passport",
        phone: formData.phone,
        departureTime: ticketsTo.departureTime,
        arrivalTime: ticketsTo.arrivalTime,
        info:
          formData.payment === "nopay"
            ? `Оплата при посадці EastExpressUkraine. ${passengers[i].notes}`
            : `100% Оплата EastExpressUkraine. ${passengers[i].notes}`,
      });
    }
  }

  if (!!ticketsFrom) {
    for (let i = 0; i < passengers.length; i += 1) {
      normalize.push({
        userId: userId,
        userPhone: formData.phone,
        userEmail: formData.email,
        price:  passengers[i].priceFrom,
        direction: "from",
        lineId: ticketsFrom.lineid,
        departureDate: ticketsFrom.departureDate,
        arrivalDate: ticketsFrom.arrivalDate,
        routeId: ticketsFrom.routeId,
        busName: ticketsFrom.name,
        busId: -1,
        pointFrom: ticketsFrom.pointFromId,
        pointTo: ticketsFrom.pointToId,
        seat: passengers[i].seatNumberFrom,
        discountId: passengers[i].discountFrom,
        currency: "UAH",
        name: passengers[i].name,
        surname: passengers[i].surname,
        passport: "Passport",
        phone: formData.phone,
        departureTime: ticketsFrom.departureTime,
        arrivalTime: ticketsFrom.arrivalTime,
        info:
          formData.payment === "nopay"
            ? `Оплата при посадці EastExpressUkraine. ${passengers[i].notes}`
            : `100% Оплата EastExpressUkraine. ${passengers[i].notes}`,
      });
    }
  }

  return normalize;
};

export const isAllSeatsFilled = ({
  passengers,
  ticketsFrom,
}: IisAllSeatsFilledProps) =>
  ticketsFrom
    ? passengers.every((e) => e.seatNumberTo !== "" && e.seatNumberFrom !== "")
    : passengers.every((e) => e.seatNumberTo !== "");

interface InotifyProps {
  text: string | ReactNode;
  type: "success" | "error";
}

export const notify = ({ text, type }: InotifyProps) => {
  toast[type](text, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
