import { useNews } from "features/news/useNews";
import CircularLoading from "components/CircularLoading";
import NewsList from "./components/NewsList";
import NewsEditor from "./components/NewsEditor";
import { useEffect } from "react";
import LoadMoreBtn from "components/LoadMoreBtn";

export default function ManagementNews() {
  const {
    loadingNews,
    fetchNews,
    revoke,
    fetchNextPageOfNews,
    loadingNextPage,
    hasMore,
  } = useNews();

  useEffect(() => {
    fetchNews();
    return () => {
      revoke();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NewsEditor />

      {!loadingNews && <NewsList />}

      {loadingNews && <CircularLoading size={60} />}

      {hasMore && !loadingNews && (
        <LoadMoreBtn
          handleClick={fetchNextPageOfNews}
          loading={loadingNextPage}
        />
      )}
    </>
  );
}
