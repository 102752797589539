import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { green, red, grey } from "@mui/material/colors";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import EastIcon from "@mui/icons-material/East";
import { LoadingButton } from "@mui/lab";

type SeatsProps = {
  props: { active: boolean; selected: boolean; available: boolean };
};

const PrimeTexrColor = `rgb(66, 69, 67)`;

//? ---------------------- Text ----------------------

export const SimpleText = styled(Typography)({
  color: PrimeTexrColor,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1.5,

  "@media (min-width:600px)": {
    fontSize: 16,
  },

  "&.nowrap": {
    textWrap: "nowrap",
  },
});

export const SpacingDivider = styled(Divider)(({ theme: { spacing } }) => ({
  marginBottom: spacing(1),
  marginTop: spacing(1),
}));

export const SectionTitle = styled(Typography)({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  "@media (min-width:600px)": {
    fontSize: 20,
  },
});

export const SectionContainer = styled(Paper)(({ theme: { spacing } }) => ({
  backgroundColor: "white",
  padding: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
}));

export const StickyGridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    position: "sticky",
    top: "90px",
    height: "100%",
    overflowY: "auto",
  },
}));

//! Payment ------------------------------------------------------------ Payment

export const BookingSectionTitle = styled(Typography)({
  color: PrimeTexrColor,
  fontSize: 20,
  fontWeight: 400,
  marginBottom: 8,
  "@media (min-width:600px)": {
    fontSize: 22,
  },
});

export const BookingDateContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  marginBottom: 5,
});

export const DateRich = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 16,
  fontWeight: 600,
}));

export const DateSimpl = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 16,
}));

export const RichTitle = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 16,
  fontWeight: 500,
}));

export const PoitToWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  paddingLeft: 20,
  ":before": {
    position: "absolute",
    content: '" "',
    top: 28,
    left: -6,
    backgroundColor: grey[400],
    padding: 6,
    borderRadius: "50%",
    zIndex: 1,
    border: `2px solid ${grey[400]}`,
  },
  ":after": {
    content: '" "',
    position: "absolute",
    top: -25,
    left: 1,
    width: 2,
    height: 60,
    background: grey[400],
  },
}));

export const PoitFromWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingLeft: 20,
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 8,
  ":before": {
    position: "absolute",
    content: '" "',
    top: 27,
    left: -6,
    zIndex: 1,
    backgroundColor: "#ffff",
    padding: 6,
    borderRadius: "50%",
    border: `2px solid ${grey[400]}`,
  },
  ":after": {
    content: '" "',
    position: "absolute",
    top: 39,
    left: 1,
    width: 2,
    height: "100%",
    background: grey[400],
  },
}));

export const PointText = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  fontWeight: 400,
}));

export const GreyEastIcon = styled(EastIcon)({
  color: grey[500],
});

//! Payment ------------------------------------------------------------ Payment

export const PaymentTitleWarapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const PaymentContentWarapper = styled(Box)({
  marginTop: 8,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const PaymentSubtitle = styled(Typography)(({ theme }) => ({
  color: grey[600],
  fontSize: 14,
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: 16,
  },
  marginTop: 5,
}));

export const PaymentTitle = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 16,
  fontWeight: 600,
  "@media (min-width:600px)": {
    fontSize: 18,
  },

  "& span": {
    fontWeight: 400,
  },
}));

export const PassCountText = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));

export const PassCountPrice = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  fontWeight: 600,
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));
//! PrivacyPolicy ------------------------------------------------------------ PrivacyPolicy

export const PrivacyPolicyWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const PrivacyPolicyText = styled(Typography)({
  color: PrimeTexrColor,
  fontSize: 12,
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: 14,
  },
  span: {
    fontWeight: 500,
  },
});

//! LoadingButton ------------------------------------------------------------ LoadingButton
export const LoadingButtonStyle = styled(LoadingButton)(
  ({ theme: { spacing } }) => ({
    textTransform: "none",
    fontSize: 18,
    fontWeight: 400,
    p: spacing(1),
  })
);

//! DrawerFirstScreen ------------------------------------------------------------ DrawerFirstScreen

export const DfcContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  maxWidth: 320,
  minWidth: 320,
}));

export const DfcContent = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(2),
  flex: "1  0 auto",
}));

export const DfcFooter = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: spacing(1),
  padding: spacing(2),
}));

export const DfcHead = styled(Box)(({ theme: { spacing } }) => ({
  paddingLeft: spacing(2),
  paddingRight: spacing(2),
  paddingTop: spacing(1),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: spacing(1),
  justifyContent: "space-between",
}));

export const DfcTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  margin: spacing(1),
  fontSize: 20,
  fontWeight: 500,
}));

export const DfcDirectionTitle = styled(Typography)(
  ({ theme: { spacing } }) => ({
    color: PrimeTexrColor,
    fontSize: 18,
    fontWeight: 500,
  })
);

export const DfcDirectionContainer = styled(Box)(({ theme: { spacing } }) => ({
  padding: 12,
  borderRadius: spacing(1),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: spacing(1),
  justifyContent: "space-between",
  ":hover": {
    background: grey[200],
    cursor: "pointer",
  },
}));

export const DfcPointToWrapp = styled(Box)({
  position: "relative",
  paddingLeft: 10,
  display: "flex",
  justifyContent: "space-between",

  ":before": {
    position: "absolute",
    content: '" "',
    top: 4,
    left: -8,
    zIndex: 1,

    backgroundColor: "#ffff",
    padding: 4,
    borderRadius: "50%",
    border: `2px solid ${grey[600]}`,
  },
  ":after": {
    content: '" "',
    position: "absolute",
    top: 7,
    left: -3,
    width: 2,
    height: "100%",

    background: grey[600],
  },
});

export const DfcPointFromWrapp = styled(Box)({
  position: "relative",
  paddingLeft: 10,
  display: "flex",
  justifyContent: "space-between",

  ":before": {
    position: "absolute",
    content: '" "',
    top: 7,
    left: -8,
    zIndex: 1,
    backgroundColor: "#ffff",
    padding: 4,
    borderRadius: "50%",
    border: `2px solid ${grey[600]}`,
  },
});

export const DfcPointText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 16,
  textWrap: "nowrap",
}));

export const DfcReservedText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  textWrap: "nowrap",
}));

export const DfcFooterText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  textWrap: "nowrap",
  "& span": { fontSize: 16, fontWeight: 500 },
}));

export const DfcFooterBtn = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  color: PrimeTexrColor,
  flexShrink: 0,
  textTransform: "none",
});

export const DfcFromDivider = styled(Divider)(({ theme: { spacing } }) => ({
  marginTop: spacing(1),
  marginBottom: spacing(1),
}));

//! DrawerSeatsScreen ------------------------------------------------------------ DrawerSeatsScreen

export const DssCloseIcon = styled(CloseIcon)({ color: grey[400] });

export const DssPersonIcon = styled(PersonIcon)({
  color: grey[600],
});
export const DssCloseOutlinedIcon = styled(CloseOutlinedIcon)({
  color: grey[400],
});

export const DssContainer = styled(Box)(({ theme: { spacing } }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: spacing(2),
  maxWidth: 320,
  minWidth: 320,
}));

export const DssHead = styled(Box)(({ theme: { spacing } }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: "#ffff",
  maxWidth: 320,
  minWidth: 320,
}));

export const DssTitleWrapp = styled(Box)(({ theme: { spacing } }) => ({
  paddingLeft: spacing(2),
  paddingRight: spacing(2),
  paddingTop: spacing(1),
  marginTop: spacing(2),
  marginBottom: spacing(1),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: spacing(1),
  justifyContent: "space-between",
}));

export const DssTitleContainer = styled(Box)({ textAlign: "center" });

export const DssTitle = styled(Box)(({ theme: { spacing } }) => ({
  fontSize: 20,
  fontWeight: 500,
  color: PrimeTexrColor,
}));

export const DssCountContainer = styled(Box)(({ theme: { spacing } }) => ({
  paddingLeft: spacing(2),
  paddingRight: spacing(2),
  marginTop: spacing(1),
  marginBottom: spacing(1),
}));

export const DssCountText = styled(Typography)({
  ontSize: 16,
  textWrap: "nowrap",
  fontWeight: 400,
  color: PrimeTexrColor,
});

export const DssSeatsContainer = styled(Box)(({ theme: { spacing } }) => ({
  paddingTop: 120,
  paddingBottom: 20,
}));

export const DssSeatsWrapper = styled(Box)(({ theme: { spacing } }) => ({
  border: `3px solid ${grey[300]}`,
  borderRadius: 40,
  display: "flex",
  flexDirection: "column",
  padding: spacing(2),
  gap: spacing(1),
}));

export const DssSeatsColumnWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  gap: spacing(1),
}));

export const DssSeats = styled(Box)<SeatsProps>(({ theme, props }) => ({
  fontSize: 16,
  color: PrimeTexrColor,
  padding: 5,
  borderTopRightRadius: 20,
  borderTopLeftRadius: 20,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  cursor: props.active ? "pointer" : "not-allowed",
  backgroundColor: props.active
    ? props.selected
      ? green[100]
      : grey[50]
    : grey[100],
  border: props.selected
    ? "none"
    : props.active
    ? `3px solid ${grey[300]}`
    : "none",
  height: 50,
  width: 50,
  flex: "0 0 auto",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  "&:nth-of-type(2)": {
    marginRight: 20,
  },
}));

export const DssNoSeats = styled(Box)({
  padding: 5,
  cursor: "not-allowed",
  backgroundColor: "inherit",
  height: 40,
  width: 10,
  flex: "0 0 auto",
});

export const DeleteOutlinedIcon = styled(DeleteOutlineOutlinedIcon)({
  color: red[200],
});

export const BlockIcon = styled(BlockOutlinedIcon)({
  color: grey[300],
});

//! ButtonChoiseSeats ------------------------------------------------------------ ButtonChoiseSeats

export const ButtonChoiseSeats = styled(Button)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textTransform: "none",
  gap: spacing(2),
  padding: spacing(1),

  "@media (min-width:600px)": {
    fontSize: 18,
  },
}));
export const ButtonChoiseSeatsContainer = styled(Box)(
  ({ theme: { spacing } }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
    padding: spacing(1),
  })
);

export const ButtonChoiseSeatsTitle = styled(Box)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 16,
  fontWeight: 600,
  marginRight: spacing(1),
}));

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)({
  color: red[800],
});

export const StyledCheckCircleOutlineOutlinedIcon = styled(
  CheckCircleOutlineOutlinedIcon
)({
  color: green[800],
});

export const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)({
  marginLeft: "auto",
});
//! PassengersCart ------------------------------------------------------------ PassengersCart

export const PassengersListWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(2),
}));

export const PassengersCartWrapper = styled(Paper)(
  ({ theme: { spacing } }) => ({
    backgroundColor: "white",
    padding: spacing(2),
    borderRadius: spacing(1),
    border: `1px solid ${grey[400]}`,
  })
);

export const PassengersCartHead = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const PassengersCartWarningText = styled(Typography)(
  ({ theme: { spacing } }) => ({
    color: PrimeTexrColor,
    fontSize: 14,
    fontWeight: 400,
    marginTop: spacing(1),
  })
);

export const PassengersCartTitle = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 500,
  marginBottom: 10,
}));

//! PaymentAccordion ------------------------------------------------------------ PaymentAccordion

export const PaymentAccordionWrapperNoPay = styled(Box)(
  ({ theme: { spacing } }) => ({
    border: `1px solid ${grey[400]}`,
    borderBottom: "none",
    borderRadius: spacing(1),
    gap: spacing(1),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: spacing(1),
  })
);

type CardPayProps = {
  props: { ticketsFrom?: boolean };
};

export const PaymentAccordionWrapperCardPay = styled(Box)<CardPayProps>(
  ({ props: { ticketsFrom }, theme: { spacing } }) => ({
    border: `1px solid ${grey[400]}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: spacing(1),
    borderRadius: spacing(1),
    borderTopLeftRadius: ticketsFrom ? spacing(1) : 0,
    borderTopRightRadius: ticketsFrom ? spacing(1) : 0,
    gap: spacing(1),
  })
);

//! Information ------------------------------------------------------------ Information
export const InformationTitle = styled(Typography)({
  color: PrimeTexrColor,
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 4,
});

export const InformationText = styled(Typography)({
  color: PrimeTexrColor,
  fontSize: 12,
  fontWeight: 400,
  marginTop: 4,
});

export const StyledSnackbar = styled(Snackbar)({
  transform: "translate(0, 50%)",
});
