import { Box, Container, Grid } from "@mui/material";
import BusCart from "components/BusCard";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { IBusTo } from "features/tickets/types";
import { useTickets } from "features/tickets/useTickets";
import { useSearchForm } from "features/searchform/useShopForm";
import { useBuses } from "features/buses/useBuses";

import {
  NotFoundBus,
  SearchResult,
  busesResultCount,
} from "components/CommonForms";
import { Skeleton } from "components/CommonForms";
import SearchForm from "./components/SearchForm";
import { useEffect } from "react";
import DatePagination from "./components/DatePagination";
import { useTranslation } from "react-i18next";

export default function BusesManagement() {
  const { setTicketsTo } = useTickets();
  const { formData } = useSearchForm();
  const navigate = useNavigate();
  const { i18n,t } = useTranslation();
  const { busesData, busesError, isLoading, getBuses } = useBuses();

  const handleSelect = (data: IBusTo) => {
    navigate(`/${i18n.language}/checkout`);
    setTicketsTo(data);
  };

  useEffect(() => {
    getBuses({
      city_from: t(formData?.city_from||''),
      city_to:  t(formData?.city_to||''),
      date_sent: formData?.date_sent,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData?.city_from,
    formData?.city_to,
    formData?.date_return,
    formData?.date_sent,
    formData?.adult,
    formData?.children,
  ]);

  return (
    <Box component={"section"}>
      <Container maxWidth="lg">
        <SearchForm />
      </Container>

      {isLoading && <Skeleton />}

      {!isLoading && (
        <Container maxWidth="md">
          <DatePagination />
          {!!busesResultCount(busesData) && <SearchResult />}

          <Grid container gap={4}>
            {!busesError &&
              busesData &&
              busesData
                .filter(({ freeSeats }) => freeSeats && freeSeats.length > 0)
                .map((bus) => (
                  <BusCart
                    busData={bus}
                    handleSelect={handleSelect}
                    key={nanoid()}
                  />
                ))}
          </Grid>

          {(!busesData?.some(({ freeSeats }) => freeSeats) || busesError) && (
            <NotFoundBus />
          )}
        </Container>
      )}
    </Box>
  );
}
