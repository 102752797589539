import { Autocomplete, Box, TextField } from "@mui/material";
import { useField } from "formik";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { useReports } from "features/reports/useReports";

interface ICustomAutocompleteProps {
  name: string;
  label: string;
}

function AgentsPicker({ name, label }: ICustomAutocompleteProps) {
  const [field, meta, helpers] = useField(name);
  const { agents } = useReports();

  const { t } = useTranslation();

  return (
    <Autocomplete
      {...field}
      id={name}
      value={field.value}
      clearIcon={false}
      loadingText={
        <Box sx={{ p: 0.5, textAlign: "center" }}>
          <CircularLoading size={24} />
        </Box>
      }
      noOptionsText={t("not_found")}
      includeInputInList
      onChange={(event, newValue) => {
        helpers.setValue(newValue);
      }}
      options={agents || []}
      renderInput={(params) => (
        <TextField
          {...params}
          {...field}
          size="small"
          label={label}
          variant="outlined"
          name={name}
          error={meta.touched && Boolean(meta.error)}
        />
      )}
    />
  );
}

export default memo(AgentsPicker);
