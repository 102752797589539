import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useTranslation } from "react-i18next";

export default function BackBtn() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      color="info"
      startIcon={<NavigateBeforeIcon />}
      sx={{ my: 2 }}
      onClick={() => {
        navigate(-1);
      }}
    >
      {t("goBack")}
    </Button>
  );
}
