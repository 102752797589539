import bus1 from "./images/busFoto1.jpg";
import bus2 from "./images/busFoto2.jpg";
import bus3 from "./images/busFoto3.jpg";
import bus4 from "./images/busFoto4.jpg";
import bus5 from "./images/busFoto5.jpg";
import bus6 from "./images/busFoto6.jpg";

interface IBusImages {
  alt: string;
  src: string;
}

export const busImages: IBusImages[] = [
  {
    alt: "bus-foto1",
    src: bus1,
  },
  {
    alt: "bus-foto2",
    src: bus2,
  },
  {
    alt: "bus-foto3",
    src: bus3,
  },
  {
    alt: "bus-foto4",
    src: bus4,
  },
  {
    alt: "bus-foto5",
    src: bus5,
  },
  {
    alt: "bus-foto6",
    src: bus6,
  },
];
