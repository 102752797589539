import { IPassengers } from "../types";
import {
  PassengersCartHead,
  PassengersCartTitle,
  PassengersCartWrapper,
} from "../style";
import { Grid, FormControl, InputLabel } from "@mui/material";

import { memo } from "react";
import { FastTextField } from "components/CommonForms";
import { useTickets } from "features/tickets/useTickets";
import ChildrenDiscount from "./ChildrenDiscount";
import AdultDdiscount from "./AdultDdiscount";
import { useUser } from "features/user/useUser";
import DiscountSelect from "./DiscountSelect";
import { useTranslation } from "react-i18next";

interface IPassengersCartProps {
  index: number;
  passenger: IPassengers;
}

function PassengersCart({ index, passenger }: IPassengersCartProps) {
  const { ticketsFrom } = useTickets();
  const { role } = useUser();
  const { t } = useTranslation();

  return (
    <PassengersCartWrapper key={passenger.id}>
      <PassengersCartHead>
        <PassengersCartTitle>
          {`${index + 1}. ${
            (passenger as IPassengers).isChildren ? t("Child") : t("Adult")
          }`}
        </PassengersCartTitle>
      </PassengersCartHead>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel shrink htmlFor={`passengers${index}-name`}>
                {t("name")}
              </InputLabel>
              <FastTextField type="text" name={`passengers.[${index}].name`} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel shrink htmlFor={`passengers${index}-surname`}>
                {t("surname")}
              </InputLabel>
              <FastTextField
                type="text"
                name={`passengers.[${index}].surname`}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel shrink htmlFor={`passengers.[${index}].notes`}>
                {t("notes")}
              </InputLabel>
              <FastTextField
                type="text"
                multiline
                rows={1}
                name={`passengers.[${index}].notes`}
              />
            </Grid>

            {role !== "admin" ? (
              passenger.isChildren ? (
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink>
                    {ticketsFrom ? t("discount_there") : t("discount")}
                  </InputLabel>
                  <ChildrenDiscount />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink htmlFor={`passengers.[${index}].discount`}>
                    {ticketsFrom ? t("discount_there") : t("discount")}
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <DiscountSelect
                      name={`passengers.[${index}].discount`}
                      index={index}
                      passenger={passenger}
                    />
                  </FormControl>
                </Grid>
              )
            ) : (
              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor={`passengers.[${index}].discount`}>
                  {ticketsFrom ? t("discount_there") : t("discount")}
                </InputLabel>
                <FormControl variant="outlined" fullWidth size="small">
                  <DiscountSelect
                    name={`passengers.[${index}].discount`}
                    index={index}
                    passenger={passenger}
                  />
                </FormControl>
              </Grid>
            )}

            {!!ticketsFrom && passenger.isChildren && role !== "admin" && (
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>{t("return_discount")}</InputLabel>
                <ChildrenDiscount />
              </Grid>
            )}

            {!!ticketsFrom && !passenger.isChildren && role !== "admin" && (
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>{t("return_discount")}</InputLabel>
                <AdultDdiscount />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </PassengersCartWrapper>
  );
}

export default memo(PassengersCart);
