import { Box, Container } from "@mui/material";
import { Grid, InputLabel } from "@mui/material";
import { useUser } from "features/user/useUser";
import { Form, Formik } from "formik";
import { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { IFormInitialValues } from "./types";
import { InformationText, SectionContainer, SectionTitle } from "./style";
import { SimplePhoneField, SimpleTextField } from "components/CommonForms";
import { matchIsValidTel } from "mui-tel-input";
import { notify } from "./helpers";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const useValidationSchema = () => {
  const { t } = useTranslation();
  return Yup.object().shape({
    email: Yup.string().email(t("invalidEmail")).required(t("requiredField")),
    phone: Yup.string()
      .min(8, t("invalid_format"))
      .test("isValidTel", t("invalid_format"), (value) =>
        matchIsValidTel(value ?? "", {
          onlyCountries: ["UA", "CZ", "PL", "DE", "BE"],
        })
      )
      .required(t("requiredField")),
    name: Yup.string().trim().required(t("requiredField")),
    surname: Yup.string().trim().required(t("requiredField")),
  });
};

export default function ProfileSettings() {
  const { user, update, error, isLoading } = useUser();

  const initialValues: IFormInitialValues = useMemo(() => {
    return {
      email: user?.email || "",
      phone: user?.phone || "",
      name: user?.name || "",
      surname: user?.surname || "",
    };
  }, [user]);

  useEffect(() => {
    if (!!error) {
      notify({ text: t("an_error_occurred"), type: "error" });
    }
  }, [error]);

  return (
    <Box component={"section"}>
      <Container maxWidth="md">
        <SectionTitle>{t("profile_settings")}</SectionTitle>

        <SectionContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={useValidationSchema()}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              update(values);
              if (!error) {
                notify({ text: t("successfully"), type: "success" });
              }
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel shrink htmlFor="name">
                        {t("name")}
                      </InputLabel>
                      <SimpleTextField name="name" type="text" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel shrink htmlFor="surname">
                        {t("surname")}
                      </InputLabel>
                      <SimpleTextField name="surname" type="text" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel shrink htmlFor="email">
                        Email
                      </InputLabel>
                      <SimpleTextField name="email" type="email" />

                      <InformationText>
                        {t("we_send_receipts_to_your_email")}
                      </InformationText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel shrink htmlFor="phone">
                        {t("phone")}
                      </InputLabel>
                      <SimplePhoneField isRequired name="phone" />

                      <InformationText>
                        {t("we_will_be_able_to_contact")}
                      </InformationText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LoadingButton
                        loading={isLoading && isSubmitting}
                        variant="outlined"
                        color="info"
                        type="submit"
                        disableFocusRipple
                        disableRipple
                      >
                        {t("save")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </SectionContainer>
      </Container>
    </Box>
  );
}
