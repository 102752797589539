import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  declinePayment,
  checkPaymentStatus,
  setPayment,
} from "services/functions";

export interface PaymentData {
  action: string;
  payment_id: number;
  status: string;
  version: number;
  type: string;
  paytype: string;
  public_key: string;
  acq_id: number;
  order_id: string;
  liqpay_order_id: string;
  description: string;
  sender_phone: string;
  sender_card_mask2: string;
  sender_card_bank: string;
  sender_card_type: string;
  sender_card_country: number;
  ip: string;
  card_token: string;
  info: string;
  amount: number;
  currency: string;
  sender_commission: number;
  receiver_commission: number;
  agent_commission: number;
  amount_debit: number;
  amount_credit: number;
  commission_debit: number;
  commission_credit: number;
  currency_debit: string;
  currency_credit: string;
  sender_bonus: number;
  amount_bonus: number;
  bonus_type: string;
  bonus_procent: number;
  authcode_debit: string;
  authcode_credit: string;
  rrn_debit: string;
  rrn_credit: string;
  mpi_eci: string;
  is_3ds: boolean;
  create_date: number;
  end_date: number;
  moment_part: boolean;
  transaction_id: number;
}

interface IliqPayProps {
  data: string;
  signature: string;
  paymentRef: string;
}

declare global {
  interface Window {
    LiqPayCheckout: {
      init: Function;
    };
  }
}

function LiqPay({ data, signature, paymentRef }: IliqPayProps) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//static.liqpay.ua/libjs/checkout.js";
    script.async = true;
    script.onerror = () => {};

    script.onclose = () => {};
    script.onload = () => {
      const liqPayInstance = window.LiqPayCheckout.init({
        data: data,
        signature: signature,
        embedTo: "#liqpay_checkout",
        language: "ua",
        mode: "popup", // embed || popup
      });

      liqPayInstance
        .on("liqpay.callback", async function (data: PaymentData) {
          if (data.status !== "success") {
            return declinePayment(paymentRef);
          }

          try {
            await setPayment(paymentRef);
          } catch (error) {
            console.log(error);
          }

          navigate({
            pathname: `/${i18n.language}/successful`,
            search: createSearchParams({
              paymentId: paymentRef || "",
            }).toString(),
          });

          return;
        })
        .on("liqpay.ready", function () {})
        .on("liqpay.close", async function () {
          try {
            const paymentRes = await checkPaymentStatus(paymentRef);
            const paymentData = paymentRes.data as PaymentData;

            if (paymentData.status !== "success") {
              return declinePayment(paymentRef);
            }
          } catch (error) {
            return error;
          }
        });
    };

    document.body.appendChild(script);
  }, [data, i18n.language, navigate, paymentRef, signature]);
  return <Box id="liqpay_checkout"></Box>;
}

export default LiqPay;
