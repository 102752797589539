import { List, Paper, styled, Typography } from "@mui/material";
import { blue, grey, yellow } from "@mui/material/colors";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const SectionContainer = styled(Paper)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  padding: spacing(2),
  gap: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
}));

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  marginBottom: spacing(1),
  marginTop: spacing(6),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginBottom: spacing(2),
    marginTop: spacing(10),
  },
}));

export const AboutTextList = styled(List)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing(1),
  "@media (min-width:600px)": {
    gap: spacing(2),
  },
}));

export const AboutTextTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 400,
  fontSize: 18,
  marginBottom: spacing(1),
  marginTop: spacing(6),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginBottom: spacing(2),
    marginTop: spacing(10),
  },
  span: {
    color: blue[800],
    fontWeight: 500,

    "&.yellow": {
      color: yellow[900],
    },
  },
}));

export const SimpleText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 300,
  fontSize: 14,

  "@media (min-width:600px)": {
    fontSize: 16,
  },

  span: {
    fontWeight: 500,
  },
}));
