import { Grid, IconButton, InputLabel, Rating } from "@mui/material";
import { useReviews } from "features/reviews/useReviews";
import { useUser } from "features/user/useUser";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { BtnContainer, SectionContainer, SectionTitle } from "../style";
import { SimpleTextField } from "components/CommonForms";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

interface IinitialValues {
  comment: string;
  rating: number;
}

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .max(400, "Максимально 400 символів")
    .required("Це поле є обов'язковим"),
});

interface IFeedbacksFormProps {
  onFormClose: () => void;
}

export default function FeedbacksForm({ onFormClose }: IFeedbacksFormProps) {
  const { submitReview } = useReviews();
  const { user } = useUser();

  const initialValues: IinitialValues = { comment: "", rating: 1 };
  return (
    <>
      <SectionTitle>Залиши відгук</SectionTitle>
      <SectionContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={(values, { resetForm }) => {
            const { comment, rating } = values;
            const data = {
              text: comment,
              userId: user?.id || "",
              rating: rating,
              approved: false,
              docId: "",
            };
            submitReview(data);
            resetForm();
            onFormClose();
          }}
        >
          {({ values: { comment, rating }, setFieldValue }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor="comment">
                      Ваш відгук
                    </InputLabel>
                    <SimpleTextField
                      name="comment"
                      type="text"
                      rows={5}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems={"flex-end"}>
                      <Grid item xs={6}>
                        <InputLabel shrink htmlFor="rating">
                          Рейтинг
                        </InputLabel>
                        <Rating
                          name="rating"
                          value={rating}
                          onChange={(event, newValue) => {
                            setFieldValue("rating", newValue);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <BtnContainer>
                          <IconButton
                            disabled={comment === ""}
                            color="success"
                            type="submit"
                          >
                            <CheckCircleOutlineIcon fontSize="medium" />
                          </IconButton>
                          <IconButton
                            color="error"
                            size="medium"
                            onClick={() => {
                              onFormClose();
                            }}
                          >
                            <DoNotDisturbIcon fontSize="medium" />
                          </IconButton>
                        </BtnContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </SectionContainer>
    </>
  );
}
