import { fetchAgents, fetchReportsByQuery } from "./operation";

import { useAppDispatch } from "store/hooks";
import { IUseReports } from "./types";
import { useSelector } from "react-redux";
import {
  selectAgentsData,
  selectError,
  selectLoadingAgents,
  selectLoadingReports,
  selectTicketsData,
} from "./selector";
import { revoke } from "./slice";

export const useReports = (): IUseReports => {
  const dispatch = useAppDispatch();
  const agents = useSelector(selectAgentsData);
  const tickets = useSelector(selectTicketsData);
  const loadingReports = useSelector(selectLoadingReports);
  const error = useSelector(selectError);
  const loadingAgents = useSelector(selectLoadingAgents);

  return {
    loadingReports,
    error,
    loadingAgents,
    tickets,
    agents,
    revoke: () => {
      dispatch(revoke());
    },
    fetchAgents: () => {
      dispatch(fetchAgents());
    },
    fetchReportsByQuery: ({ email, start_date, end_date }) => {
      dispatch(fetchReportsByQuery({ email, start_date, end_date }));
    },
  };
};
