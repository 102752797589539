import { Box, Button, Paper, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export const CardContainer = styled(Paper)(({ theme: { spacing } }) => ({
   padding: spacing(2),
  border: `1px solid ${grey[400]}`,
  borderRadius: spacing(1),
}));

export const DateWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  marginBottom: spacing(1),
}));

export const PointText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  "@media (min-width:600px)": {
    fontSize: 14,
  },
  fontWeight: 400,
  color: grey[900],

  span: {
    fontSize: 14,
    "@media (min-width:600px)": {
      fontSize: 16,
    },
    fontWeight: 600,
    color: grey[900],
  },
}));

export const RowTooltip = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: spacing(1),
}));

export const TooltipContainer = styled(Grid)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: "flex",
    flexDirection: "column",
    [breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    gap: spacing(1),
  })
);

export const ButtonContainer = styled(Grid)(
  ({ theme: { spacing, breakpoints } }) => ({
    textAlign: "end",
  })
);

export const CheckButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: 16,
  fontWeight: 400,
}));

export const DateText = styled(Typography)(({ theme }) => ({
  padding: 2,
  fontSize: 10,
  "@media (min-width:600px)": {
    fontSize: 12,
  },
  marginLeft: 5,
  textWrap: "nowrap",
  backgroundColor: grey[200],
  borderRadius: theme.spacing(1),
  fontWeight: 400,
  color: grey[900],
}));

export const TimeText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  "@media (min-width:600px)": {
    fontSize: 28,
  },
  lineHeight: 1,
  color: grey[900],
}));

export const TimeDiff = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  "@media (min-width:600px)": {
    fontSize: 12,
  },
  color: grey[600],
}));

export const CityText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  "@media (min-width:600px)": {
    fontSize: 20,
  },
  fontWeight: 400,
  color: grey[900],
}));

export const PriceText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 400,
  textWrap: "nowrap",

  "@media (min-width:600px)": {
    fontSize: 28,
  },
  color: grey[900],
  alignSelf: "center",
  span: {
    fontSize: 12,
    marginLeft: "4px",
  },
}));

export const PriceBtnText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  textWrap: "nowrap",
  alignSelf: "center",
  span: {
    fontSize: 12,
    marginLeft: "4px",
  },
}));
