import { Box, Typography, Theme, Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const PrimeTextColor = `rgb(66, 69, 67)`;

// Common text styles function
const commonTextStyles = ({ spacing }: { spacing: Theme["spacing"] }) => ({
  color: PrimeTextColor,
  fontWeight: 400,
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    marginBottom: spacing(2),
  },
});

interface ISectionContainer {
  props?: { contacts?: boolean };
}

export const SectionContainer = styled(Paper)<ISectionContainer>(
  ({ theme: { spacing }, props }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: spacing(2),
    gap: spacing(2),
    borderRadius: spacing(1),
    border: `1px solid ${grey[400]}`,
    minHeight: props?.contacts ? 325 : 0,
  })
);

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  ...commonTextStyles({ spacing }),
  fontWeight: 500,
  fontSize: 18,
  marginTop: spacing(4),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginTop: spacing(6),
  },
}));

export const TextWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(0.5),
}));

export const AdressTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTextColor,
  fontWeight: 500,
  fontSize: 12,
  "@media (min-width:600px)": {
    fontSize: 14,
  },
}));

export const AdressText = styled(Box)(({ theme: { spacing } }) => ({
  color: grey[600],
  fontWeight: 400,
  fontSize: 10,
  flexWrap: "wrap",

  "@media (min-width:600px)": {
    fontSize: 12,
  },
}));

export const CartContent = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: "flex",
    [breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
    gap: spacing(2),
    img: { display: "block", objectFit: "contain" },
  })
);

export const ContactsListItem = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 4,
}));

export const ContactsItemWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

interface IContactsListLinkProps {
  props?: { contacts: boolean };
}

export const ContactsListLink = styled(Link)<IContactsListLinkProps>(
  ({ theme: { spacing }, props }) => ({
    marginLeft: props?.contacts ? 0 : spacing(0.5),
    fontSize: 14,
    fontWeight: 400,
    color: PrimeTextColor,
    whiteSpace: "nowrap",
    "&:hover, &:focus, &:active": {
      textDecoration: "underline",
    },
  })
);

export const ContactsText = styled(Typography)(({ theme: { spacing } }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: PrimeTextColor,
  whiteSpace: "nowrap",
}));

export const ContactsListText = styled(Typography)(
  ({ theme: { spacing } }) => ({
    fontSize: 14,
    fontWeight: 400,
    color: PrimeTextColor,
    whiteSpace: "nowrap",
  })
);

export const ContactUsTitle = styled(Typography)(({ theme: { spacing } }) => ({
  ...commonTextStyles({ spacing }),
  fontSize: 14,
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));
