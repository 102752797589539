import { IconButton, CircularProgress } from "@mui/material";
import { useState } from "react";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { getPodPdf } from "services/ticket";
import { LoadTicketBtnBox, LoadTicketBtnText } from "./style";
import { useTranslation } from "react-i18next";

interface ILoadTicketBtnProps {
  paymentId: string;
}

export default function LoadTicketBtn({ paymentId }: ILoadTicketBtnProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handlClick = () => {
    setLoading(true);
    getPodPdf({ paymentRefId: paymentId }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <LoadTicketBtnBox>
      <LoadTicketBtnText onClick={() => handlClick()}>
        {t("download_ticket")}
      </LoadTicketBtnText>
      <IconButton
        disabled={loading}
        onClick={() => handlClick()}
        color="success"
      >
        {!loading ? (
          <DownloadingIcon />
        ) : (
          <CircularProgress color="info" size={24} />
        )}
      </IconButton>
    </LoadTicketBtnBox>
  );
}
