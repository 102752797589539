import { CircularProgress, Fab } from "@mui/material";
import { useState } from "react";
import { getReportPdf } from "services/report";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";

interface ILoadReportFabProps {
  email: string;
  start_date: string;
  end_date: string;
}

export default function LoadReportFab({
  email,
  start_date,
  end_date,
}: ILoadReportFabProps) {
  const [loading, setLoading] = useState(false);

  const handlClick = () => {
    setLoading(true);
    getReportPdf({
      email: email,
      start_date: start_date,
      end_date: end_date,
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Fab
      color="primary"
      size="medium"
      disabled={loading}
      onClick={() => handlClick()}
    >
      {!loading ? (
        <ArrowCircleDownOutlinedIcon />
      ) : (
        <CircularProgress color="secondary" size={24} />
      )}
    </Fab>
  );
}
