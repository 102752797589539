import * as yup from "yup";
import { matchIsValidTel } from "mui-tel-input";
import { useTranslation } from "react-i18next";

export const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object().shape({
    phone: yup
      .string()
      .min(8, t("requiredField"))
      .test("isValidTel", t("invalid_format"), (value) =>
        matchIsValidTel(value ?? "", {
          onlyCountries: ["UA", "CZ", "PL", "DE", "BE"],
        })
      )
      .required(t("requiredField")),

    email: yup
      .string()
      .email(t("invalid_format"))
      .max(255)
      .required(t("requiredField")),

    passengers: yup.array().of(
      yup.object().shape({
        name: yup.string().required(t("requiredField")),
        surname: yup.string().required(t("requiredField")),
      })
    ),
  });
};
