import { FastField, useField } from "formik";
import { memo } from "react";
import { MenuItem, Select } from "@mui/material";
import { useTickets } from "features/tickets/useTickets";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";
import { IDiscount, IPassengers } from "pages/Checkout/types";
import { useUser } from "features/user/useUser";

interface IDiscountSelectProps {
  passenger: IPassengers;
  index: number;
  name: string;
}

export const filterDiscount = (
  discountList: IDiscount[] = [],
  userRole: string | null
) => {
  const excludedDiscountIds = ["20", "23", "18", "22", "21"];
  const excludedDiscountIdsAgent = ["21", "22", "23"];
  switch (userRole) {
    case "admin":
      return discountList;
    case "agent":
      return discountList.filter(
        (e) => !excludedDiscountIdsAgent.includes(e.DiscountId)
      );
    case "user":
      return discountList.filter(
        (e) => !excludedDiscountIds.includes(e.DiscountId)
      );
    case null:
      return discountList.filter(
        (e) => !excludedDiscountIds.includes(e.DiscountId)
      );
    default:
      return discountList;
  }
};

const DiscountSelect = ({ name, passenger }: IDiscountSelectProps) => {
  const [field, , helpers] = useField(name);
  const { t } = useTranslation();
  const { ticketsTo } = useTickets();
  const { role } = useUser();

  return (
    <FastField
      as={Select}
      name={field.name}
      id={name}
      defaultValue={"0"}
      value={field.value}
      onChange={(newValue: any) => {
        helpers.setValue(newValue.target.value);
      }}
    >
      {filterDiscount(ticketsTo?.discounts, role || null).map(
        ({ DiscountId }) => (
          <MenuItem key={nanoid()} value={DiscountId}>
            {t(`discountKey-${DiscountId}`)}
          </MenuItem>
        )
      )}
    </FastField>
  );
};

export default memo(DiscountSelect);
