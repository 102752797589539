import { Box, Divider, IconButton } from "@mui/material";
import { memo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTickets } from "features/tickets/useTickets";
import { useTranslation } from "react-i18next";
import {
  DfcContainer,
  DfcFooterBtn,
  DfcFooterText,
  DfcFromDivider,
  DfcContent,
  DfcFooter,
  DfcHead,
  DfcPointFromWrapp,
  DfcPointText,
  DfcPointToWrapp,
  DfcReservedText,
  DfcTitle,
  DfcDirectionTitle,
  DfcDirectionContainer,
} from "../style";

interface IDrawerFirstScreenProps {
  allSeats: number;
  reservedSetasTo: number;
  totalReserved: number;
  reservedSetasFrom?: number;
  toggleContent: (direction: string) => void;
  toggleDrawer: (
    inOpen: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

function DrawerFirstScreen({
  toggleDrawer,
  toggleContent,
  totalReserved,
  allSeats,
  reservedSetasFrom,
  reservedSetasTo,
}: IDrawerFirstScreenProps) {
  const { t } = useTranslation();
  const { ticketsTo, ticketsFrom } = useTickets();

  return (
    <>
      <DfcContainer>
        <Box>
          <DfcHead>
            <DfcTitle>{t("seat_reservation")}</DfcTitle>
            <IconButton disableTouchRipple onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </DfcHead>
          <Divider />
        </Box>

        <DfcContent>
          <DfcDirectionTitle>{t("journey_destination")}</DfcDirectionTitle>
          <Box>
            <DfcDirectionContainer onClick={() => toggleContent("to")}>
              <Box>
                <DfcPointToWrapp>
                  <DfcPointText>
                    {t(`cityKey-${ticketsTo?.pointFromId}`)}
                  </DfcPointText>
                </DfcPointToWrapp>
                <DfcPointFromWrapp>
                  <DfcPointText>
                    {t(`cityKey-${ticketsTo?.pointToId}`)}
                  </DfcPointText>
                </DfcPointFromWrapp>
              </Box>
              <DfcReservedText>
                {`${t("reserved")}: ${reservedSetasTo}/${allSeats}`}
              </DfcReservedText>
              <KeyboardArrowRightIcon />
            </DfcDirectionContainer>
          </Box>
          {!!ticketsFrom && (
            <Box>
              <DfcFromDivider />
              <DfcDirectionTitle>{t("return_journey")}</DfcDirectionTitle>
              <DfcDirectionContainer onClick={() => toggleContent("from")}>
                <Box>
                  <DfcPointToWrapp>
                    <DfcPointText>
                      {t(`cityKey-${ticketsFrom?.pointFromId}`)}
                    </DfcPointText>
                  </DfcPointToWrapp>
                  <DfcPointFromWrapp>
                    <DfcPointText>
                      {t(`cityKey-${ticketsFrom?.pointToId}`)}
                    </DfcPointText>
                  </DfcPointFromWrapp>
                </Box>
                <DfcReservedText>
                  {`${t("reserved")}: ${reservedSetasFrom}/${allSeats}`}
                </DfcReservedText>
                <KeyboardArrowRightIcon />
              </DfcDirectionContainer>
            </Box>
          )}
        </DfcContent>
      </DfcContainer>
      <DfcFooter>
        <DfcFooterText>
          <span>{`${totalReserved}`}</span> {t("seat_selected")}
        </DfcFooterText>
        <DfcFooterBtn
          onClick={toggleDrawer(false)}
          color="info"
          variant="outlined"
        >
          {t("confirm")}
        </DfcFooterBtn>
      </DfcFooter>
    </>
  );
}

export default memo(DrawerFirstScreen);
