import {
  Theme,
  AccordionSummary,
  Box,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";

const PrimeTextColor = `rgb(66, 69, 67)`;

const commonTextStyles = ({ spacing }: { spacing: Theme["spacing"] }) => ({
  color: PrimeTextColor,
  fontWeight: 400,
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    marginBottom: spacing(2),
  },
});

export const SectionContainer = styled(Paper)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  padding: spacing(2),
  gap: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
}));

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  ...commonTextStyles({ spacing }),
  fontWeight: 500,
  fontSize: 18,
  marginTop: spacing(4),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginTop: spacing(6),
  },
}));

export const ContactUsTitle = styled(Typography)(({ theme: { spacing } }) => ({
  ...commonTextStyles({ spacing }),
  fontSize: 14,
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(16),
  color: PrimeTextColor,
  marginBottom: theme.spacing(4),
  "@media (min-width:600px)": {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export const QuestionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  ...commonTextStyles({ spacing }),
  fontSize: 16,
  "@media (min-width:600px)": {
    fontSize: 18,
  },
}));

export const QuestionSubTitle = styled(Typography)(
  ({ theme: { spacing } }) => ({
    ...commonTextStyles({ spacing }),
    fontSize: 14,
    "@media (min-width:600px)": {
      fontSize: 16,
    },
  })
);

export const ExpandedSummary = styled(AccordionSummary)(
  ({ theme: { spacing } }) => ({
    "&.Mui-expanded": {
      backgroundColor: grey[200],
      borderRadius: spacing(1),
    },
  })
);

export const Question = styled(Box)(({ theme: { spacing } }) => ({
  fontWeight: 300,
  fontSize: 14,
  color: PrimeTextColor,
  marginLeft: spacing(1),
  "@media (min-width:600px)": {
    fontSize: 16,
  },
  ul: {
    display: "flex",
    flexDirection: "column",
    gap: spacing(1),
    ".phone_list": {
      display: "flex",
      flexDirection: "column",
      gap: spacing(0.5),
      marginTop: spacing(0.5),
    },
  },
}));

export const ContactsListItem = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(0.5),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const ContactsItemWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ContactsListLink = styled(Link)(({ theme: { spacing } }) => ({
  marginLeft: spacing(0.5),
  fontSize: 14,
  fontWeight: 400,
  color: PrimeTextColor,
  whiteSpace: "nowrap",
  "&:hover, &:focus, &:active": {
    textDecoration: "underline",
  },
}));

export const ServiceOfficeItem = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: spacing(0.5),
}));

export const ServiceTextWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: spacing(0.5),
}));

export const ServiceTitle = styled(Box)(({ theme: { spacing } }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: PrimeTextColor,
  textAlign: "left",
}));

export const ServiceText = styled(Box)(({ theme: { spacing } }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: grey[600],
}));

export const ContactsListLinks = styled(Link)(
  ({ theme: { spacing, breakpoints } }) => ({
    marginLeft: 4,
    fontSize: 14,
    fontWeight: 400,
    color: PrimeTextColor,
    textWrap: "nowrap",

    "&:hover, &:focus, &:active": {
      textDecoration: "underline",
    },
  })
);
