import { ChangeEvent, memo } from "react";
import crediCard from "../images/credit_card.png";
import { Typography, Box, RadioGroup, Radio } from "@mui/material";
import { useTickets } from "features/tickets/useTickets";
import {
  PaymentAccordionWrapperCardPay,
  PaymentAccordionWrapperNoPay,
} from "../style";
import { useTranslation } from "react-i18next";

interface IPaymentAccordionProps {
  value: string;
  setValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled:boolean
}

function PaymentAccordion({ value, setValue ,disabled}: IPaymentAccordionProps) {
  const { ticketsFrom } = useTickets();
  const { t } = useTranslation();

  return (
    <>
      <RadioGroup
        name="payment"
        aria-labelledby="payment-select-buttons-group-label"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setValue("payment", event.target.value, false);
        }}
        value={value}
        defaultValue={"nopay"}
      >
        {!ticketsFrom && (
          <>
            <PaymentAccordionWrapperNoPay
              onClick={() => {!disabled && setValue("payment", "nopay")}}
            >
              <Radio color="info" value="nopay"  disabled={disabled} />
              <Typography>{t("payment_upon_boarding")}</Typography>
            </PaymentAccordionWrapperNoPay>
            <PaymentAccordionWrapperCardPay
              props={{ ticketsFrom: false }}
              onClick={() => {!disabled && setValue("payment", "card")}}
             >
              <Radio color="info" value="card" disabled={disabled}/>
              <Typography>{t("bank_card")}</Typography>
              <Box>
                <img src={crediCard} alt="credit-card-icon" />
              </Box>
            </PaymentAccordionWrapperCardPay>
          </>
        )}
        {ticketsFrom && (
          <PaymentAccordionWrapperCardPay
            props={{ ticketsFrom: true }}
            onClick={() => {!disabled && setValue("payment", "card")}}
            >
            <Radio color="info" value="card" disabled={disabled}/>
            <Typography>{t("bank_card")}</Typography>
            <Box>
              <img src={crediCard} alt="credit-card-icon" />
            </Box>
          </PaymentAccordionWrapperCardPay>
        )}
      </RadioGroup>
    </>
  );
}

export default memo(PaymentAccordion);
