import { Box, Grid } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  OrderSubText,
  OrderTitle,
  PaymantStatusText,
  PaymantStatusContainer,
  ColumnWrapp,
  Title,
  CartContainer,
} from "../style";
import { useFormatPaymentStatus } from "../helpers";
import { IOrder } from "features/orders/types";
import LoadTicketBtn from "./LoadTicketBtn";
import { grey } from "@mui/material/colors";
import DeleteBtn from "./DeleteBtn";
import ChangeStatus from "./ChangeStatus";

interface IOrderCartProps {
  order: IOrder;
}

export default function OrderCart({ order }: IOrderCartProps) {
  const { t } = useTranslation();
  const { tickets, paymentId, paymentStatus, userEmail, userPhone, date } =
    order;

  const pasTo = tickets.filter((e) => e.direction === "to");
  const pasFrom = tickets.filter((e) => e.direction !== "to");

  return (
    <Grid item xs={12} sm={12} md={6}>
      <CartContainer>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ColumnWrapp>
              <OrderTitle>{t("order_number")}</OrderTitle>
              <OrderSubText>{order.count}</OrderSubText>
            </ColumnWrapp>
          </Grid>
          <Grid item xs={6}>
            <PaymantStatusContainer props={{ paymentStatus: paymentStatus }}>
              <PaymantStatusText>
                {useFormatPaymentStatus(paymentStatus)}
              </PaymantStatusText>
            </PaymantStatusContainer>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title>{t("customer")}</Title>
          </Grid>
          <Grid item xs={6}>
            <ColumnWrapp>
              <OrderTitle>{t("phone")}:</OrderTitle>
              <OrderSubText>{userPhone}</OrderSubText>
            </ColumnWrapp>
          </Grid>
          <Grid item xs={6}>
            <ColumnWrapp>
              <OrderTitle>Email:</OrderTitle>
              <OrderSubText>{userEmail}</OrderSubText>
            </ColumnWrapp>
          </Grid>
          <Grid item xs={6}>
            <ColumnWrapp>
              <OrderTitle>{t("cost")}</OrderTitle>
              <OrderSubText>
                {tickets.reduce((acc, e) => {
                  acc = acc + e.price;
                  return acc;
                }, 0)}{" "}
                UAH
              </OrderSubText>
            </ColumnWrapp>
          </Grid>
          <Grid item xs={6}>
            <ColumnWrapp>
              <OrderTitle>{t("number_of_passengers")}</OrderTitle>
              <OrderSubText>{tickets.length}</OrderSubText>
            </ColumnWrapp>
          </Grid>
          <Grid item xs={6}>
            <ColumnWrapp>
              <OrderTitle>
                {paymentStatus === "nopay"
                  ? t("booking_time")
                  : t("purchase_time")}
              </OrderTitle>
              <OrderSubText>
                {dayjs(date.toDate()).format("DD.MM.YYYY")}
              </OrderSubText>
            </ColumnWrapp>
          </Grid>
          <Grid item xs={6}>
            <ColumnWrapp>
              <OrderTitle>
                {paymentStatus === "nopay"
                  ? t("booking_time")
                  : t("purchase_time")}
              </OrderTitle>
              <OrderSubText>
                {dayjs(date.toMillis()).format("HH:MM")}
              </OrderSubText>
            </ColumnWrapp>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title>{!!pasFrom.length ? t("routes") : t("route")}</Title>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                p: 1,
                border: `1px solid ${grey[400]}`,
                borderRadius: 5,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ColumnWrapp>
                    <OrderTitle>{t("flight_name")}</OrderTitle>
                    <OrderSubText>{pasTo[0].busName}</OrderSubText>
                  </ColumnWrapp>
                </Grid>
                <Grid item xs={6}>
                  <ColumnWrapp>
                    <OrderTitle>{t("route_name")}</OrderTitle>
                    <OrderSubText>
                      {t(`cityKey-${pasTo[0].pointFrom}`)} -{" "}
                      {t(`cityKey-${pasTo[0].pointTo}`)}
                    </OrderSubText>
                  </ColumnWrapp>
                </Grid>
                <Grid item xs={6}>
                  <ColumnWrapp>
                    <OrderTitle>{t("departure_date")}</OrderTitle>
                    <OrderSubText>
                      {dayjs(pasTo[0].departureDate).format("DD.MM.YYYY")}
                    </OrderSubText>
                  </ColumnWrapp>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {!!pasFrom.length && (
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 1,
                  border: `1px solid ${grey[400]}`,
                  borderRadius: 5,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ColumnWrapp>
                      <OrderTitle>{t("flight_name")}</OrderTitle>
                      <OrderSubText>{pasFrom[0].busName}</OrderSubText>
                    </ColumnWrapp>
                  </Grid>
                  <Grid item xs={6}>
                    <ColumnWrapp>
                      <OrderTitle>{t("route_name")}</OrderTitle>
                      <OrderSubText>
                        {t(`cityKey-${pasFrom[0].pointFrom}`)} -{" "}
                        {t(`cityKey-${pasFrom[0].pointTo}`)}
                      </OrderSubText>
                    </ColumnWrapp>
                  </Grid>
                  <Grid item xs={6}>
                    <ColumnWrapp>
                      <OrderTitle>{t("departure_date")}</OrderTitle>
                      <OrderSubText>
                        {dayjs(pasFrom[0].departureDate).format("DD.MM.YYYY")}
                      </OrderSubText>
                    </ColumnWrapp>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <LoadTicketBtn paymentId={paymentId} />

          {order.paymentStatus !=='paid'&&          <DeleteBtn paymentId={paymentId} />}
          {order.paymentStatus !=='paid'&&<ChangeStatus paymentId={paymentId}  />}
        </Box>
      </CartContainer>
    </Grid>
  );
}
