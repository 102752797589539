import { createTheme } from "@mui/material/styles";
import { red, yellow } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
    h1: {
      fontSize: 34,
      fontWeight: 500,
      lineHeight: 1.3,
    },

    h2: {
      fontSize: 24,
      fontWeight: 500,
      color: yellow[900],
    },

    body1: {
      fontSize: 17,
      fontWeight: 400,
      lineHeight: 1.5,
    },

    subtitle1: {
      color: "#767676",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.3,
    },
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: { backgroundColor: "white" },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: { textTransform: "none" },
      },
    },
  },
});

export default theme;
