import { Button, Paper, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";

export const SearchFormWrapper = styled(Paper)(({ theme: { spacing } }) => ({
  backgroundColor: "white",
  padding: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
  marginTop: spacing(2),
  marginBottom: spacing(2),
}));

export const SearchFormBtn = styled(Button)(({ theme: { spacing } }) => ({
  height: "100%",
}));

export const TabContainer = styled(Tabs)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 400,

  "& .MuiTabs-indicator": {
    backgroundColor: blue[800],
  },

  "& .MuiTabs-indicatorSpan": {
    color: "red",
  },
}));

//! TabElement --------------

export const TabElement = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(14),

  color: `rgb(66, 69, 67)`,
  "&.Mui-selected": {
    borderRight: `1px solid ${grey[400]}`,
    borderLeft: `1px solid ${grey[400]}`,
    color: `rgb(66, 69, 67)`,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
  },
  "&.Mui-focusVisible": {
    backgroundColor: grey[400],
  },
}));

export const TabWrapper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${grey[400]}`,
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
}));
