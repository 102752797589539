import { createAsyncThunk } from "@reduxjs/toolkit";
import { bus, getClosestBus } from "services/functions";
import { IGetBusData } from "./types";
import { setBuses, setClosestBus } from "./slice";

export const getBus = createAsyncThunk(
  "buses/getBus",
  async (data: IGetBusData, { rejectWithValue, dispatch }) => {
    try {
      const [busData, closestBusData] = await Promise.all([
        bus(data),
        getClosestBus(data),
      ]);

      dispatch(setBuses(busData.data));

      dispatch(setClosestBus(closestBusData.data));

      return;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
 