import { matchIsValidTel } from "mui-tel-input";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const useFormatPaymentStatus = (paymentStatus: string) => {
  const { t } = useTranslation();
  switch (paymentStatus) {
    case "nopay":
      return t("payment_upon_boarding");

    case "pending":
      return t("awaiting_payment");

    case "paid":
      return t("paid");

    default:
      break;
  }
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    tel: Yup.string()
      .min(8)
      .test("isValidTel", t("invalid_format"), (value) =>
        matchIsValidTel(value ?? "", {
          onlyCountries: ["UA", "CZ", "PL", "DE", "BE"],
        })
      )
      .required(t("requiredField")),
  });
};
