import { GoogleMap, Marker } from "@react-google-maps/api";
import { Box, Grid, Skeleton } from "@mui/material";

import {
  AdressText,
  AdressTitle,
  SectionContainer,
  TextWrapper,
} from "../style";
import { IAddressData } from "../constans";
import { nanoid } from "nanoid";
import { t } from "i18next";

interface IAdressCartProps {
  adress: IAddressData;
  isLoaded: boolean;
  onLoad: any;
  onUnmount: any;
}

export default function AdressCart({
  adress,
  isLoaded,
  onLoad,
  onUnmount,
}: IAdressCartProps) {
  const { address, workingHours, contacts, image, position } = adress;
  return (
    <Grid item xs={12}>
      <SectionContainer>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                img: {
                  objectFit: "cover",
                },
              }}
            >
              <img
                src={image.src}
                alt={image.alt}
                width={image.width}
                height={image.height}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextWrapper>
              <Box>
                <AdressTitle>{t("address")}:</AdressTitle>
                <AdressText>{t(`${address}`)}</AdressText>
              </Box>
              <Box>
                <AdressTitle>{t("working_hours")}:</AdressTitle>
                <AdressText>{t(`${workingHours}`)}</AdressText>
              </Box>
              <Box>
                <AdressTitle>{t("contacts")}:</AdressTitle>
                {contacts.map((e) => (
                  <AdressText key={nanoid()}>{e}</AdressText>
                ))}
              </Box>
            </TextWrapper>
          </Grid>

          <Grid item xs={12}>
            <Box>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    minHeight: "200px",
                    maxHeight: "300px",
                  }}
                  center={position}
                  zoom={15}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  <Marker position={position} />
                </GoogleMap>
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"200px"}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </SectionContainer>
    </Grid>
  );
}
