import NavBar from "components/NavBar";

import { MainWrapper, Wrapper } from "components/MainLayout/style";
import { ReactNode } from "react";

interface IMainLayout {
  children: ReactNode;
}

export default function SecondLayout({ children }: IMainLayout) {
  return (
    <Wrapper>
      <NavBar />
      <MainWrapper component="main">{children}</MainWrapper>
    </Wrapper>
  );
}
