import { styled } from "@mui/material/styles";
import { Box, Tab, Tabs } from "@mui/material";

export const LayoutContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#005094",
}));

export const NavBarWrapper = styled(Box)(({ theme }) => ({}));

export const TabContainer = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 500,
  fontSize: 14,
  color: "#ffff",
  "&.Mui-selected": {
    fontWeight: 500,
    color: "orange",
  },
}));
