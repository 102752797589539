import { memo, SyntheticEvent, useEffect, useState } from "react";
import { TabContainer, TabElement, TabWrapper } from "../style";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { Idata } from "../types";
import { useSearchForm } from "features/searchform/useShopForm";
import { getDateRange } from "components/CommonForms/helpers";
import { useTranslation } from "react-i18next";

function DatePagination() {
  const { formData, setFormData } = useSearchForm();
  const { i18n } = useTranslation();
  const [data, setData] = useState<Idata>({
    dataGrid: null,
    tabValue: formData?.date_sent!,
  });

  useEffect(() => {
    setData({
      dataGrid: getDateRange(formData?.date_sent!),
      tabValue: formData?.date_sent!,
    });
  }, [formData?.date_sent]);

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setFormData({
      date_sent: formattedDate,
    });
  };

  return (
    <TabWrapper elevation={1}>
      <TabContainer
        variant="fullWidth"
        value={data.tabValue}
        onChange={handleTabChange}
      >
        {data.dataGrid &&
          data.dataGrid.map((key) => {
            return (
              <TabElement
                key={nanoid()}
                disabled={dayjs(key).isBefore(
                  dayjs(new Date()).subtract(1, "day")
                )}
                label={dayjs(key).locale(i18n.language).format("ddd, DD MMM.")}
                value={dayjs(key).format("YYYY-MM-DD")}
              />
            );
          })}
      </TabContainer>
    </TabWrapper>
  );
}

export default memo(DatePagination);
