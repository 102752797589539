export interface IRoute {
  to: string;
  label: string;
  value: string;
}

export interface IProfileRoutes {
  [key: string]: IRoute[];
}

export const ROLES = {
  ADMIN: "admin",
  USER: "user",
  AGENT: "agent",
};

export const PROFILE_ROTES = {
  ADMIN: {
    MAIN: "/admin",
    HISTORY: "/admin/history",
    SETTINGS: "/admin/settings",
    REVIEWS: "/admin/reviews",
    NEWS: "/admin/manager-news",
    REPORT: "/admin/report",
  },
  USER: { MAIN: "/user", REVIEWS: "/user/reviews", HISTORY: "/user/history" },
  AGENT: {
    MAIN: "/agent",
    HISTORY: "/agent/history",
    SETTINGS: "/agent/settings",
  },
};

export const profileRoutes: IProfileRoutes = {
  [ROLES.ADMIN]: [
    {
      to: PROFILE_ROTES.ADMIN.MAIN,
      label: "search_for_trips",
      value: PROFILE_ROTES.ADMIN.MAIN,
    },
    {
      to: PROFILE_ROTES.ADMIN.HISTORY,
      label: "trip_history",
      value: PROFILE_ROTES.ADMIN.HISTORY,
    },
    {
      to: PROFILE_ROTES.ADMIN.REPORT,
      label: "Звіти",
      value: PROFILE_ROTES.ADMIN.REPORT,
    },
    {
      to: PROFILE_ROTES.ADMIN.REVIEWS,
      label: "reviews_",
      value: PROFILE_ROTES.ADMIN.REVIEWS,
    },
    {
      to: PROFILE_ROTES.ADMIN.NEWS,
      label: "news_",
      value: PROFILE_ROTES.ADMIN.NEWS,
    },
    {
      to: PROFILE_ROTES.ADMIN.SETTINGS,
      label: "profile_settings",
      value: PROFILE_ROTES.ADMIN.SETTINGS,
    },
  ],
  [ROLES.AGENT]: [
    {
      to: PROFILE_ROTES.AGENT.MAIN,
      label: "search_for_trips",
      value: PROFILE_ROTES.AGENT.MAIN,
    },
    {
      to: PROFILE_ROTES.AGENT.HISTORY,
      label: "trip_history",
      value: PROFILE_ROTES.AGENT.HISTORY,
    },
    {
      to: PROFILE_ROTES.AGENT.SETTINGS,
      label: "profile_settings",
      value: PROFILE_ROTES.AGENT.SETTINGS,
    },
  ],
  [ROLES.USER]: [
    {
      to: PROFILE_ROTES.USER.MAIN,
      label: "profile_settings",
      value: PROFILE_ROTES.USER.MAIN,
    },
    {
      to: PROFILE_ROTES.USER.REVIEWS,
      label: "reviews_",
      value: PROFILE_ROTES.USER.REVIEWS,
    },
    {
      to: PROFILE_ROTES.USER.HISTORY,
      label: "trip_history",
      value: PROFILE_ROTES.USER.HISTORY,
    },
  ],
};
