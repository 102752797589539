import {
  SelectedBusFromContainer,
  SelectedColumnWrapp,
  SelectedDate,
  SelectedFromWrapp,
  SelectedPointText,
  SelectedTitle,
  SelectedToWrapp,
  StyledKeyboardArrowDownIcon,
} from "../style";
import dayjs from "dayjs";
import { useTickets } from "features/tickets/useTickets";
import { useTranslation } from "react-i18next";

export default function SelectedBusFrom() {
  const { ticketsTo } = useTickets();
  const { t, i18n } = useTranslation();

  return (
    <SelectedBusFromContainer>
      <SelectedTitle>{t("selected_flight")}</SelectedTitle>
      <SelectedDate>
        {dayjs(ticketsTo?.departureDate).locale(i18n.language).format("DD MMM")}{" "}
        | {ticketsTo?.departureTime} — {ticketsTo?.arrivalTime}
      </SelectedDate>
      <SelectedColumnWrapp>
        <SelectedFromWrapp>
          <StyledKeyboardArrowDownIcon />
          <SelectedPointText>
            <span>{t(`cityKey-${ticketsTo?.pointFromId}`)}</span>,{" "}
            {t(`pointKey-${ticketsTo?.pointFromId}`)}
          </SelectedPointText>
        </SelectedFromWrapp>

        <SelectedToWrapp>
          <SelectedPointText>
            <span>{t(`cityKey-${ticketsTo?.pointToId}`)}</span>,{" "}
            {t(`pointKey-${ticketsTo?.pointToId}`)}
          </SelectedPointText>
        </SelectedToWrapp>
      </SelectedColumnWrapp>
    </SelectedBusFromContainer>
  );
}
