import {
  SectionContainer,
  SectionTitle,
  StyledReactQuill,
  TextEditorBtnContainer,
  TextEditorContainer,
} from "../style";
import { formats, modules } from "../constans";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { useNews } from "features/news/useNews";
import { IconButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

interface ITextEditorProps {
  onFormClose: () => void;
}

export default function TextEditor({ onFormClose }: ITextEditorProps) {
  const [value, setValue] = useState<string>("");
  const { сurrentNews, updateNews, revokeCurrent, submitNews } = useNews();

  useEffect(() => {
    setValue(сurrentNews?.text || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [сurrentNews]);

  return (
    <TextEditorContainer>
      <SectionTitle>Редактор</SectionTitle>
      <SectionContainer>
        <StyledReactQuill
          onChange={setValue}
          theme="snow"
          value={value}
          modules={modules}
          formats={formats}
        />

        <TextEditorBtnContainer>
          <IconButton
            disabled={value === ""}
            color="success"
            onClick={() => {
              if (сurrentNews) {
                updateNews(сurrentNews?.docId, value);
              } else {
                submitNews(value);
              }
              onFormClose();
            }}
          >
            <CheckCircleOutlineIcon fontSize="medium" />
          </IconButton>
          <IconButton
            color="error"
            size="medium"
            onClick={() => {
              revokeCurrent();
              onFormClose();
            }}
          >
            <DoNotDisturbIcon fontSize="medium" />
          </IconButton>
        </TextEditorBtnContainer>
      </SectionContainer>
    </TextEditorContainer>
  );
}
