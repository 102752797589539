import { Box, Container } from "@mui/material";
import { SectionTitle, StyledBox } from "./style";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import Link from "components/Link";
import Seo from "components/Seo";

export default function Oferta() {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={"seo.oferta.title"}
        description={"seo.oferta.description"}
        canonicalLink="oferta"
      />
      <Box component={"section"}>
        <Container maxWidth="lg">
          <SectionTitle>{t("oferta_page_title")}</SectionTitle>
          <StyledBox>
            <ul>
              <li>
                <p>{t("oferta_text.intro")}</p>
              </li>
              <li>
                <p>
                  <b>{t("oferta_text.terms_definitions_title")}</b>
                </p>
              </li>

              <li>
                <p>
                  {parse(t("oferta_text.agent"))}
                  <br />
                  {parse(t("oferta_text.baggage"))}
                  <br />
                  {parse(t("oferta_text.ticket"))}
                  <br />
                  {parse(t("oferta_text.commission_fee"))}
                  <br />
                  {parse(t("oferta_text.user_of_site"))}
                  <br />
                  {parse(t("oferta_text.passenger"))}
                  <br />
                  {parse(t("oferta_text.carrier"))}
                  <br />
                  {parse(t("oferta_text.payer"))}
                  <br />
                  {parse(t("oferta_text.service"))}
                  <br />
                  {parse(t("oferta_text.rules_of_carriage"))}
                  <br />
                  {parse(t("oferta_text.site"))}
                  <br />
                  {parse(t("oferta_text.oferta_text.parties"))}
                </p>
              </li>
              <br />
              <li>
                <p>
                  <b>{t("oferta_text.contract_subject_title")}</b>
                </p>
              </li>

              <li>
                <p>
                  {t("oferta_text.contract_subject_1_1")}
                  <br />
                  {t("oferta_text.contract_subject_1_2")}
                  <br />
                  {t("oferta_text.contract_subject_1_3")}
                </p>
              </li>
              <br />
              <li>
                <p>
                  <b>{t("oferta_text.rights_obligations_parties_title")}</b>
                </p>
              </li>

              <li>
                <p>
                  {t("oferta_text.rights_obligations_parties_2_1_1")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_1_2")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_1_3")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_1_4")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_1_5")}
                </p>
              </li>
              <li>
                <p>
                  <b>{t("oferta_text.rights_obligations_parties_2_2")}</b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.rights_obligations_parties_2_2_1")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_2_2")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_2_3")}
                </p>
              </li>
              <li>
                <b>
                  <p>{t("oferta_text.rights_obligations_parties_2_3")}</p>
                </b>
              </li>
              <li>
                <p>
                  {t("oferta_text.rights_obligations_parties_2_3_1")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_3_2")}
                </p>
              </li>
              <li>
                <p>
                  <b>{t("oferta_text.rights_obligations_parties_2_4")}</b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.rights_obligations_parties_2_4_1")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_4_2")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_4_3")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_4_4")}
                  <br />
                  {t("oferta_text.rights_obligations_parties_2_4_5")}
                </p>
              </li>
              <br />
              <li>
                <p>
                  <b>{t("oferta_text.order_conditions_ticket_return_title")}</b>
                </p>
              </li>

              <li>
                <p>
                  {t("oferta_text.order_conditions_ticket_return_3_1")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_2")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_3")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_4")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_5")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_6")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_7")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_8")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_9")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_10")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_11")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_12")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_13")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_14")}
                  <br />
                  {t("oferta_text.order_conditions_ticket_return_3_15")}
                </p>
              </li>
              <li>
                <p>
                  <b>{t("oferta_text.ticket_return_conditions_title")} </b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.ticket_return_conditions_3_16_1")}
                  <br />
                  {t("oferta_text.ticket_return_conditions_3_16_2")}
                  <br />
                  {t("oferta_text.ticket_return_conditions_3_16_3")}
                  <br />
                  {t("oferta_text.ticket_return_conditions_3_16_4")}
                  <br />
                  {t("oferta_text.ticket_return_conditions_3_16_5")}
                  <br />
                  {t("oferta_text.ticket_return_conditions_3_16_6")}
                </p>
              </li>
              <br />

              <li>
                <p>
                  <b>{t("oferta_text.transportation_conditions_title")}</b>
                </p>
              </li>

              <li>
                <p>
                  {t("oferta_text.transportation_conditions_general_4_1_1")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_2")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_3")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_4")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_5")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_6")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_7")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_8")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_9")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_10")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_11")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_12")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_13")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_14")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_15")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_16")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_17")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_18")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_19")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_20")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_21")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_22")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_23")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_24")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_25")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_26")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_27")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_28")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_29")}
                  <br />
                  {t("oferta_text.transportation_conditions_general_4_1_30")}
                </p>
              </li>
              <li>
                <p>
                  <b>
                    {t(
                      "oferta_text.international_transportation_conditions_title"
                    )}
                  </b>
                </p>
              </li>
              <li>
                <p>
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_1"
                  )}
                  <br />
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_2"
                  )}
                  <br />
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_3"
                  )}
                  <br />
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_4"
                  )}
                  <br />
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_5"
                  )}
                  <br />
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_6"
                  )}
                  <br />
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_7"
                  )}
                  <br />
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_8"
                  )}
                  <br />
                  {t(
                    "oferta_text.international_transportation_conditions_4_2_9"
                  )}
                </p>
              </li>
              <li>
                <p>
                  <b>{t("oferta_text.behavior_rules_in_bus_title")}</b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.behavior_rules_in_bus_4_3_1")}
                  <br />
                  {t("oferta_text.behavior_rules_in_bus_4_3_2")}
                  <br />
                  {t("oferta_text.behavior_rules_in_bus_4_3_3")}
                  <br />
                  {t("oferta_text.behavior_rules_in_bus_4_3_4")}
                  <br />
                  {t("oferta_text.behavior_rules_in_bus_4_3_5")}
                  <br />
                  {t("oferta_text.behavior_rules_in_bus_4_3_6")}
                  <br />
                  {t("oferta_text.behavior_rules_in_bus_4_3_7")}
                  <br />
                  {t("oferta_text.behavior_rules_in_bus_4_3_8")}
                </p>
              </li>
              <li>
                <p>
                  {parse(t("oferta_text.luggage_transportation_rules_title"))}
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.luggage_transportation_rules_4_4_1")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_2")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_3")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_4")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_5")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_6")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_7")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_8")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_9")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_10")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_11")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_12")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_13")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_14")}
                  <br />
                  {t("oferta_text.luggage_transportation_rules_4_4_15")}
                </p>
              </li>
              <br />

              <li>
                <p>
                  <b>
                    {t("oferta_text.copyright_privacy_permission_data_title")}
                  </b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.copyright_privacy_permission_data_5_1")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_2")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_3")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_4")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_5")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_6")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_7")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_8")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_9")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_10")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_11")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_12")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_13")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_14")}
                  <br />
                  {t("oferta_text.copyright_privacy_permission_data_5_15")}
                </p>
              </li>
              <br />

              <li>
                <p>
                  <b>{t("oferta_text.responsibility_parties_title")}</b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.responsibility_parties_6_1")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_2")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_3")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_4")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_5")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_6")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_7")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_8")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_9")}
                  <br />
                  {t("oferta_text.responsibility_parties_6_10")}
                </p>
              </li>
              <br />
              <li>
                <p>
                  <b>{t("oferta_text.force_majeure_title")}</b>
                </p>
              </li>

              <li>
                <p>
                  {t("oferta_text.force_majeure_7_1")}
                  <br />
                  {t("oferta_text.force_majeure_7_2")}
                  <br />
                  {t("oferta_text.force_majeure_7_3")}
                </p>
              </li>
              <br />
              <li>
                <p>
                  <b>{t("oferta_text.dispute_resolution_title")}</b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.dispute_resolution_8_1")}
                  <br />
                  {t("oferta_text.dispute_resolution_8_2")}
                  <br />
                  {t("oferta_text.dispute_resolution_8_3")}
                </p>
              </li>

              <br />
              <li>
                <p>
                  <b>
                    {t("oferta_text.contract_modification_termination_title")}
                  </b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.contract_modification_termination_9_1")}{" "}
                  <Link to={"/rules"} target="_blank">
                    <span style={{ color: "blue" }}>
                      east-express.net.ua/rules
                    </span>
                  </Link>
                  ;
                  <br />
                  {t("oferta_text.contract_modification_termination_9_2")}
                  <br />
                  {t("oferta_text.contract_modification_termination_9_3")}
                  <br />
                  {t("oferta_text.contract_modification_termination_9_4")}
                  <br />
                  {t("oferta_text.contract_modification_termination_9_5")}
                  <br />
                  {t("oferta_text.contract_modification_termination_9_6")}
                  <br />
                  {t("oferta_text.contract_modification_termination_9_7")}
                  <br />
                  {t("oferta_text.contract_modification_termination_9_8")}
                  <br />
                  {t("oferta_text.contract_modification_termination_9_9")}
                </p>
              </li>
              <br />
              <li>
                <p>
                  <b>{t("oferta_text.parties_details_title")}</b>
                </p>
              </li>
              <li>
                <p>
                  {t("oferta_text.parties_details_10_1")}
                  <br />
                  {t("oferta_text.parties_details_10_2")}
                </p>
              </li>
            </ul>
          </StyledBox>
        </Container>
      </Box>
    </>
  );
}
