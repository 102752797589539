import styled from "@emotion/styled";
import {
  Box,
  Button,
  styled as muiStyled,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import ReactQuill from "react-quill";

const PrimeTextColor = `rgb(66, 69, 67)`;

export const StyledReactQuill = styled(ReactQuill)({
  minHeight: 300,
  ".ql-editor": { height: 400, overflowY: "scroll" },
});

export const SectionContainer = muiStyled(Paper)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  padding: spacing(2),
  gap: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
  minHeight: 325,
}));

const commonTextStyles = ({ spacing }: { spacing: Theme["spacing"] }) => ({
  color: PrimeTextColor,
  fontWeight: 400,
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    marginBottom: spacing(2),
  },
});

export const SectionTitle = muiStyled(Typography)(({ theme: { spacing } }) => ({
  ...commonTextStyles({ spacing }),
  fontWeight: 500,
  fontSize: 18,
  marginTop: spacing(4),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginTop: spacing(6),
  },
}));

export const StyledBtn = muiStyled(Button)(({ theme: { spacing } }) => ({
  textTransform: "none",
  marginTop: spacing(1),
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    marginTop: spacing(2),
  },
}));

export const CenterContainer = muiStyled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: spacing(2),
  marginBottom: spacing(2),
}));

export const AddFeedbackText = muiStyled(Typography)(
  ({ theme: { spacing } }) => ({
    color: PrimeTextColor,
    fontWeight: 500,
    fontSize: 12,
    marginTop: spacing(0.5),
    marginBottom: spacing(1),
    "@media (min-width:600px)": {
      fontSize: 14,
    },
  })
);

export const PreviewNewsCardImage = muiStyled(Box)(
  ({ theme: { spacing } }) => ({
    img: {
      width: "100%",
      maxHeight: 300,
      objectFit: "cover",
      display: "block",
    },
  })
);

export const PreviewNewsCardTitle = muiStyled(Typography)(
  ({ theme: { spacing } }) => ({
    color: PrimeTextColor,
    fontWeight: 500,
    fontSize: 14,
    marginTop: spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: 16,
    },
  })
);

export const PreviewNewsCardDescription = muiStyled(Typography)(
  ({ theme: { spacing } }) => ({
    color: PrimeTextColor,
    fontWeight: 300,
    fontSize: 12,
    marginTop: spacing(0.5),
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  })
);

export const TextEditorBtnContainer = muiStyled(Typography)(
  ({ theme: { spacing } }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: spacing(0.5),
  })
);

export const TextEditorContainer = muiStyled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(2),
}));
