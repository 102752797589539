import { useSelector } from "react-redux";
import { IUseReviews } from "./types";
import {
  selectError,
  selectHasMore,
  selectLastVisible,
  selectLoadingNextPage,
  selectLoadingReviews,
  selectReviews,
} from "./selector";

import {
  deleteReviewById,
  fetchReviews,
  submitReview,
  approveReviewById,
  fetchNextPageOfReviews,
  fetchApprovedReviews,
} from "./operation";
import { useAppDispatch } from "store/hooks";
import { revoke } from "./slice";

export const useReviews = (): IUseReviews => {
  const reviews = useSelector(selectReviews);
  const error = useSelector(selectError);
  const loadingReviews = useSelector(selectLoadingReviews);
  const loadingNextPage = useSelector(selectLoadingNextPage);
  const lastVisible = useSelector(selectLastVisible);
  const hasMore = useSelector(selectHasMore);
  const dispatch = useAppDispatch();

  return {
    reviews,
    loadingReviews,
    error,
    lastVisible,
    hasMore,
    loadingNextPage,

    fetchReviews: () => {
      dispatch(fetchReviews());
    },

    submitReview: (data) => {
      dispatch(submitReview(data));
    },

    deleteReviewById: (id) => {
      dispatch(deleteReviewById(id));
    },

    approveReviewById: (id) => {
      dispatch(approveReviewById(id));
    },

    fetchNextPageOfReviews: () => {
      dispatch(fetchNextPageOfReviews());
    },

    fetchApprovedReviews: () => {
      dispatch(fetchApprovedReviews());
    },

    revoke: () => {
      dispatch(revoke());
    },
  };
};
