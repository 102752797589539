import { Grid } from "@mui/material";
import QuestionList from "./QuestionList";
import ContactUs from "./ContactUs";
import { SectionTitle } from "../style";
import ServiceOffice from "./ServiceOffice";
import { useTranslation } from "react-i18next";

export default function Main() {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitle as={"h1"}>{t("frequentlyAskedQuestions")}</SectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <QuestionList />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={12}>
              <ContactUs />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <ServiceOffice />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
