import { Box, styled } from "@mui/material";

const PrimeTextColor = `rgb(66, 69, 67)`;
const SecondaryTextColor = `rgb(99, 102, 106)`;

export const GlobalStyles = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
  color: PrimeTextColor,
  fontFamily: "Arial, sans-serif",
  h1: {
    color: PrimeTextColor,
    fontWeight: 600,
    fontSize: 22,

    "@media (min-width:600px)": {
      fontSize: 24,
    },
  },
  h2: {
    color: PrimeTextColor,
    fontWeight: 500,
    fontSize: 18,

    "@media (min-width:600px)": {
      fontSize: 22,
    },
  },
  h3: {
    color: PrimeTextColor,
    fontWeight: 500,
    fontSize: 16,

    "@media (min-width:600px)": {
      fontSize: 20,
    },
  },
  h4: {
    color: PrimeTextColor,
    fontWeight: 500,
    fontSize: 14,

    "@media (min-width:600px)": {
      fontSize: 18,
    },
  },
  h5: {
    color: PrimeTextColor,
    fontWeight: 400,
    fontSize: 12,

    "@media (min-width:600px)": {
      fontSize: 16,
    },
  },
  h6: {
    color: PrimeTextColor,
    fontWeight: 400,
    fontSize: 10,

    "@media (min-width:600px)": {
      fontSize: 14,
    },
  },
  p: {
    color: SecondaryTextColor,
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 1.5,

    "@media (min-width:600px)": {
      fontSize: 14,
    },
  },
  a: {
    color: PrimeTextColor,
    textDecoration: "none",
    fontWeight: 400,

    "&:hover": {
      textDecoration: "underline",
    },
  },
  ul: {
    color: SecondaryTextColor,
    fontWeight: 300,
    fontSize: 12,
    paddingLeft: spacing(4),

    "@media (min-width:600px)": {
      fontSize: 14,
    },

    li: {
      marginBottom: spacing(1),
    },
  },
  ol: {
    color: SecondaryTextColor,
    fontWeight: 300,
    fontSize: 12,
    paddingLeft: spacing(4),

    "@media (min-width:600px)": {
      fontSize: 14,
    },

    li: {
      marginBottom: spacing(1),
    },
  },
  img: {
    width: "100%",
    maxHeight: 400,
    objectFit: "contain",
    display: "block",
    margin: `${spacing(2)}px 0`,
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: spacing(2),
    color: PrimeTextColor,

    th: {
      borderBottom: `2px solid ${SecondaryTextColor}`,
      padding: spacing(1),
      textAlign: "left",
    },
    td: {
      borderBottom: `1px solid ${SecondaryTextColor}`,
      padding: spacing(1),
    },
  },
}));
