import { Box, Container } from "@mui/material";
import FeedbacksForm from "./components/FeedbacksForm";
import Feedbacks from "./components/Feedbacks";
import { useState } from "react";
import OpenFormBtn from "./components/OpenFormBtn";

export default function ReviewsPage() {
  const [showForm, setShowForm] = useState(false);

  const handleToggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Box component="section">
        <Container maxWidth="md">
          {showForm ? (
            <FeedbacksForm onFormClose={handleToggleForm} />
          ) : (
            <OpenFormBtn onFormOpen={handleToggleForm} />
          )}
        </Container>
      </Box>
      <Box component="section">
        <Container maxWidth="md">
          <Feedbacks />
        </Container>
      </Box>
    </>
  );
}
