import { Avatar, Box, Paper, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material";
import { blue, grey, red, yellow } from "@mui/material/colors";
import StarIcon from "@mui/icons-material/Star";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const SectionWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const SectionContainer = styled(Paper)(({ theme: { spacing } }) => ({
  backgroundColor: "white",
  padding: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
}));

export const FeedbacksCartContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(4),
}));

export const RowContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: spacing(1),
}));

export const FeedbacksActionsAside = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: spacing(1),
  backgroundColor: "#ffff",
}));

export const FeedbacksAvatar = styled(Avatar)(({ theme: { spacing } }) => ({
  backgroundColor: blue[500],

  width: 30,
  height: 30,
}));

export const FeedbacksText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 400,
  fontSize: 14,
  wordWrap: "break-word",
  overflowWrap: "break-word",
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));

export const FeedbackSecondText = styled(Typography)(
  ({ theme: { spacing } }) => ({
    color: grey[500],
    fontWeight: 400,
    fontSize: 14,
  })
);

export const FeedbackHeadText = styled(Typography)(
  ({ theme: { spacing } }) => ({
    color: grey[500],
    fontWeight: 500,
    fontSize: 16,
  })
);

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  marginTop: spacing(2),
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginTop: spacing(4),
    marginBottom: spacing(2),
  },
}));

export const DeleteIconRed = styled(DeleteIcon)({
  color: red[400],
});

export const RecommendIconGreen = styled(StarIcon)({});

export const StyledStarIcon = styled(StarIcon)({
  color: yellow[800],
  fontSize: 18,
});
