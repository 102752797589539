import { useLocation, useNavigate } from "react-router-dom";
import {
  LayoutContainer,
  NavBarWrapper,
  StyledTab,
  TabContainer,
} from "./style";
import { Container } from "@mui/material";
import { IRoute, profileRoutes } from "./constans";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProfileNavProps {
  role: string;
}

export default function ProfileNav({ role }: IProfileNavProps) {
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(`/${i18n.language}${newValue}`);
    navigate(newValue);
  };
  const routes: IRoute[] = profileRoutes[role] || [];

  return (
    <LayoutContainer>
      <Container maxWidth="lg">
        <NavBarWrapper>
          <TabContainer
            value={value}
            variant="scrollable"
            onChange={handleChange}
            aria-label="admin routes"
          >
            {routes.map((route) => (
              <StyledTab
                key={route.value}
                label={t(route.label)}
                value={`/${i18n.language}${route.value}`}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              />
            ))}
          </TabContainer>
        </NavBarWrapper>
      </Container>
    </LayoutContainer>
  );
}
