import { toast } from "react-toastify";

interface InotifyProps {
  text: string;
  type: "success" | "error";
}

export const notify = ({ text, type }: InotifyProps) =>
  toast[type](`${text}`, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
