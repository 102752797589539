import { Button, IconButton } from "@mui/material";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FormWrapp, SearchContainer, SectionSubTitle } from "../style";
import { useValidationSchema } from "../helpers";
import { useOrders } from "features/orders/useOrders";
import { useTranslation } from "react-i18next";

export default function SearchOrders() {
  const [, setSearchParams] = useSearchParams();
  const { fetchOrdersByPhoneNumber, fetchOrders, revoke } = useOrders();
  const { t } = useTranslation();

  const {
    handleSubmit,
    values: { tel },
    setFieldValue,
    setErrors,
    errors: { tel: telError },
    touched: { tel: telTouch },
  } = useFormik({
    initialValues: { tel: "" },
    validationSchema: useValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit(values, formikHelpers) {
      fetchOrdersByPhoneNumber(values.tel);
    },
  });

  return (
    <SearchContainer>
      <SectionSubTitle>{t("search_by_phone_number")}</SectionSubTitle>
      <FormWrapp onSubmit={handleSubmit}>
        <MuiTelInput
          size="small"
          value={tel}
          name="tel"
          fullWidth
          disableFormatting
          InputProps={{
            endAdornment: (
              <IconButton
                disableFocusRipple
                onClick={() => {
                  setFieldValue("tel", "");
                  setErrors({});
                  setSearchParams({});
                  if (tel === "") return;
                  revoke();
                  fetchOrders();
                }}
              >
                <HighlightOffIcon fontSize="small" />
              </IconButton>
            ),
          }}
          defaultCountry="UA"
          onlyCountries={["UA", "CZ", "PL", "DE", "BE"]}
          onChange={(newValue: string, info) => {
            setFieldValue("tel", newValue);
          }}
          error={telTouch && Boolean(telError)}
        />

        <Button type="submit" variant="outlined" color="info">
          {t("searchButton")}
        </Button>
      </FormWrapp>
    </SearchContainer>
  );
}
