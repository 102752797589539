import ProfileNavBar from "components/ProfileNavBar";
import { Outlet } from "react-router-dom";

export default function Profile() {
  return (
    <>
      <ProfileNavBar />
      <Outlet />
    </>
  );
}
