import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Paper,
  Skeleton,
} from "@mui/material";
import { blue, deepOrange, green, grey } from "@mui/material/colors";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const AddIconGrey = styled(AddIcon)(({ theme }) => ({
  color: grey[50],
}));

export const RemoveIconGrey = styled(RemoveIcon)(({ theme }) => ({
  color: grey[50],
}));

export const PopoverContetnWrapper = styled(Box)(({ theme }) => ({
  padding: 16,
}));

export const PassengersCountTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 8,
  fontSize: 16,
  fontWeight: 500,
  color: PrimeTexrColor,
}));

export const ContentContainer = styled(Grid)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  alignItems: "center",
}));

export const ContainerTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: PrimeTexrColor,
}));

export const ContainerSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: grey[500],
}));

export const CountContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 8,
  flexWrap: "nowrap",
  alignItems: "center",
}));

export const CenteredCountItems = styled(Grid)(({ theme }) => ({
  textAlign: "center",
}));

export const CountNumber = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: PrimeTexrColor,
}));

export const CountButton = styled(IconButton)(({ theme }) => ({
  background: blue[600],
  "&:disabled": {
    background: grey[200],
  },
}));

export const TabContainer = styled(Tabs)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 400,

  "& .MuiTabs-indicator": {
    backgroundColor: blue[800],
  },

  "& .MuiTabs-indicatorSpan": {
    color: "red",
  },
}));

//! TabElement --------------

export const TabElement = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(14),

  color: `rgb(66, 69, 67)`,
  "&.Mui-selected": {
    borderRight: `1px solid ${grey[400]}`,
    borderLeft: `1px solid ${grey[400]}`,
    color: `rgb(66, 69, 67)`,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
  },
  "&.Mui-focusVisible": {
    backgroundColor: grey[400],
  },
}));

export const TabWrapper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${grey[400]}`,
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

export const SearchResultGrid = styled(Grid)(({ theme: { spacing } }) => ({
  alignItems: "flex-end",
  padding: spacing(0.5),
  marginTop: spacing(1),
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    padding: spacing(1),
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
}));

export const SearchResultText = styled(Typography)(
  ({ theme: { spacing } }) => ({
    fontSize: 14,
    fontWeight: 500,
    color: PrimeTexrColor,

    "@media (min-width:600px)": {
      fontSize: 16,
    },
  })
);

export const SearchResultCount = styled(Typography)(
  ({ theme: { spacing } }) => ({
    fontSize: 12,
    fontWeight: 400,
    color: PrimeTexrColor,

    "@media (min-width:600px)": {
      fontSize: 14,
    },
  })
);

// NotFoundBus
export const NotFoundBusContiner = styled(Paper)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: spacing(1),
  borderRadius: spacing(2),
  padding: spacing(2),
  marginTop: spacing(2),
}));

export const NotFoundTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  textAlign: "center",
  fontSize: 22,
  "@media (min-width:600px)": {
    fontSize: 28,
  },
  span: {
    fontWeight: 600,
  },
}));

export const NotFoundSubTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  "@media (min-width:600px)": {
    fontSize: 18,
  },
  span: {
    fontWeight: 500,
  },
}));

export const StyledFmdBadIcon = styled(ErrorOutlineIcon)(
  ({ theme: { spacing } }) => ({
    color: deepOrange[500],
    fontSize: 20,
    "@media (min-width:600px)": {
      fontSize: 40,
    },
  })
);
export const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)(
  ({ theme: { spacing } }) => ({
    color: green[500],
    fontSize: 20,
    "@media (min-width:600px)": {
      fontSize: 30,
    },
  })
);

export const StyledSkeleton = styled(Skeleton)(({ theme: { spacing } }) => ({
  borderRadius: spacing(1),
}));

export const SkeletonContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(2),
  marginTop: spacing(2),
}));

export const StyledIconButton = styled(IconButton)(
  ({ theme: { spacing } }) => ({
    "&.MuiButtonBase-root": {
      padding: 0,
      background: "white",
    },
    "&.MuiIconButton-root": {
      padding: 0,
      background: "white",
    },
  })
);
