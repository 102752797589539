import {
  DssSeatsWrapper,
  DssContainer,
  DssSeatsColumnWrapper,
  DssTitleWrapp,
  DssTitleContainer,
  DssCountContainer,
  DssCountText,
  DssSeatsContainer,
  DssSeats,
  DssPersonIcon,
  DssCloseOutlinedIcon,
  DssCloseIcon,
  DssTitle,
  DssNoSeats,
  DssHead,
} from "../style";
import { Divider, IconButton } from "@mui/material";
import { nanoid } from "nanoid";
import { memo } from "react";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IRow } from "../types";
import { useTranslation } from "react-i18next";

interface IDrawerSeatsScreenProps {
  allSeats: number;
  reservedSetasTo: number;
  reservedSetasFrom?: number;
  seatsList: IRow[] | null;
  backDrawer?: boolean;
  toggleContent: (direction: string) => void;
  handleSeatSelect: (seatId: string, direction: string) => void;
  toggleDrawer: (
    inOpen: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

function DrawerSeatsScreen({
  backDrawer,
  seatsList,
  toggleDrawer,
  toggleContent,
  handleSeatSelect,
  allSeats,
  reservedSetasFrom,
  reservedSetasTo,
}: IDrawerSeatsScreenProps) {
  const { t } = useTranslation();

  return (
    <DssContainer>
      <DssHead>
        <DssTitleWrapp>
          <IconButton disableTouchRipple onClick={() => toggleContent("main")}>
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
          <DssTitleContainer>
            <DssTitle>{t("seat_reservation")}</DssTitle>
          </DssTitleContainer>

          <IconButton disableTouchRipple onClick={toggleDrawer(false)}>
            <DssCloseIcon />
          </IconButton>
        </DssTitleWrapp>
        <Divider />

        <DssCountContainer>
          <DssCountText>
            {backDrawer
              ? `${t("selected")}: ${reservedSetasFrom} / ${allSeats}`
              : `${t("selected")}: ${reservedSetasTo} / ${allSeats}`}
          </DssCountText>
        </DssCountContainer>
        <Divider />
      </DssHead>
      <DssSeatsContainer>
        <DssSeatsWrapper>
          {!!seatsList &&
            seatsList.map((row, i) => (
              <DssSeatsColumnWrapper key={i}>
                {row.map((seat) =>
                  !!seat ? (
                    <DssSeats
                      key={seat.id}
                      props={{
                        active: seat?.isActive,
                        selected: seat?.isSelected,
                        available: seat?.available,
                      }}
                      onClick={() => {
                        if (seat.isActive) {
                          handleSeatSelect(
                            seat.number,
                            backDrawer ? "from" : "to"
                          );
                        }
                      }}
                    >
                      {seat?.isSelected ? (
                        <DssPersonIcon />
                      ) : seat?.isActive ? (
                        `${seat.number}`
                      ) : (
                        <DssCloseOutlinedIcon />
                      )}
                    </DssSeats>
                  ) : (
                    <DssNoSeats key={nanoid()} />
                  )
                )}
              </DssSeatsColumnWrapper>
            ))}
        </DssSeatsWrapper>
      </DssSeatsContainer>
    </DssContainer>
  );
}

export default memo(DrawerSeatsScreen);
