import { useEffect } from "react";
import { useReviews } from "features/reviews/useReviews";
import { SectionTitle } from "../style";
import CircularLoading from "components/CircularLoading";
import { Grid } from "@mui/material";
import { nanoid } from "nanoid";
import LoadMoreBtn from "components/LoadMoreBtn";
import { useTranslation } from "react-i18next";
import NewFeedbacks from "components/FeedbackCart/NewFeedbacks";

export default function Feedbacks() {
  const {
    fetchApprovedReviews,
    reviews,
    loadingReviews,
    error,
    loadingNextPage,
    fetchNextPageOfReviews,
    hasMore,
  } = useReviews();

  const { t } = useTranslation();

  useEffect(() => {
    fetchApprovedReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!loadingReviews && !error && (
        <>
          <SectionTitle>{t("your_reviews")}</SectionTitle>
          <Grid container spacing={2} alignItems={"center"}>
            {reviews &&
              Object.keys(reviews)?.map((id) => {
                const e = reviews[id];
                return (
                  e && (
                    <NewFeedbacks isAdmin={false} feedback={e} key={nanoid()} />
                  )
                );
              })}
          </Grid>
          {hasMore && (
            <LoadMoreBtn
              loading={loadingNextPage}
              handleClick={fetchNextPageOfReviews}
            />
          )}
        </>
      )}

      {loadingReviews && <CircularLoading size={80} />}
    </>
  );
}
