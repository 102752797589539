import { Box, Button, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { grey } from "@mui/material/colors";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const SearchformWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  background: "white",
  borderRadius: theme.spacing(1),
}));

export const RelativAutocompleteWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
}));

export const SwapWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  padding: 4,
  right: 0,
  zIndex: 1000,
  color: grey[500],
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    height: 41.44,
  },
}));

export const ColorWrapper = styled(Box)({
  background:
    "linear-gradient(180deg, rgba(0,80,148,1) 0%, rgba(58,123,217,1) 100%)",
});

export const SelectedBusFromContainer = styled(Paper)(
  ({ theme: { spacing } }) => ({
    padding: spacing(1),
    border: `1px solid ${grey[400]}`,
    borderRadius: 1,
    marginTop: spacing(2),
    marginBottom: spacing(2),
    "@media (min-width:600px)": {
      marginBottom: spacing(4),
    },
  })
);

export const SelectedTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  fontWeight: 600,
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));

export const SelectedDate = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  fontWeight: 400,
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));

export const SelectedPointText = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  color: grey[900],
  fontSize: 12,
  "@media (min-width:600px)": {
    fontSize: 14,
  },

  span: {
    fontSize: 14,
    fontWeight: 600,
    color: grey[900],
    "@media (min-width:600px)": {
      fontSize: 16,
    },
  },
}));

export const SelectedColumnWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(1),
  alignItems: "start",
}));

export const SelectedFromWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "4px",
}));

export const SelectedToWrapp = styled(Box)(({ theme: { spacing } }) => ({
  marginLeft: "29px",
}));

export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme: { spacing } }) => ({
    color: grey[400],
  })
);

export const SearchFormWrapp = styled(Box)(({ theme: { spacing } }) => ({
  paddingTop: spacing(1),
  paddingBottom: spacing(4),
  "@media (min-width:600px)": {
    paddingBottom: spacing(4),
  },
}));
