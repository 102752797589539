import { createSlice } from "@reduxjs/toolkit";
import { IBusesState } from "./types";
import { getBus } from "./operation";

const initialState: IBusesState = {
  isLoading: false,
  buses: null,
  closestBus: null,
  closestError: null,
  lastError: null,
  busesError: null,
};

const busesSlice = createSlice({
  name: "buses",
  initialState,
  reducers: {
    clearBuses: (state) => {
      return { ...initialState };
    },
    setBuses: (state, { payload }) => {
      return { ...state, buses: payload };
    },
    setClosestBus: (state, { payload }) => {
      return { ...state, closestBus: payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBus.pending, (state: IBusesState) => {
        return { ...state, isLoading: true, error: null, closestBus: null };
      })
      .addCase(getBus.fulfilled, (state: IBusesState, { payload }) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(getBus.rejected, (state: IBusesState, { payload }) => {
        return {
          ...state,
          lastError: payload as string,
        };
      });
  },
});
export const { clearBuses, setClosestBus, setBuses } = busesSlice.actions;
export const busesReducer = busesSlice.reducer;
