import { IStore } from "../../store/store";

export const selectOrdersData = (state: IStore) => state.orders.orders;
export const selectError = (state: IStore) => state.orders.error;
export const selectLoadingOrders = (state: IStore) =>
  state.orders.loadingOrders;
export const selectLoadingNextPage = (state: IStore) =>
  state.orders.loadingNextPage;
export const selectLastVisible = (state: IStore) => state.orders.lastVisible;
export const selectHasMore = (state: IStore) => state.orders.hasMore;
export const selectOrderByPaymentId = (state: IStore) => state.orders.orderbypaymentid;

