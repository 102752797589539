import { styled, Typography, Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
const PrimeTexrColor = `rgb(66, 69, 67)`;

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  "@media (min-width:600px)": {
    fontSize: 20,
  },
  marginTop: spacing(5),
  marginBottom: spacing(2),
}));

export const SectionContainer = styled(Paper)(({ theme: { spacing } }) => ({
  backgroundColor: "white",
  padding: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
}));

export const InformationText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontSize: 12,
  fontWeight: 400,
  marginTop: spacing(0.5),
}));
