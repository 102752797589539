import { Avatar, Box, Paper, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RecommendIcon from "@mui/icons-material/Recommend";
import { styled } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const SectionWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(4),
  marginTop: spacing(2),
}));

export const SectionContainer = styled(Paper)(({ theme: { spacing } }) => ({
  backgroundColor: "white",
  padding: spacing(2),
  borderRadius: spacing(1),
  border: `1px solid ${grey[400]}`,
}));

export const FeedbacksCartContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: 300,
  gap: spacing(2),
  "@media (min-width:600px)": {
    minHeight: 360,
  },
}));

export const FeedbacksActionsContainer = styled(Box)(
  ({ theme: { spacing } }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: spacing(1),
  })
);

export const FeedbacksActionsAside = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: spacing(1),
}));

export const FeedbacksAvatar = styled(Avatar)(({ theme: { spacing } }) => ({
  backgroundColor: blue[500],
  width: 30,
  height: 30,
}));

export const FeedbacksTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 14,
}));

export const FeedbacksText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 400,
  fontSize: 14,
  wordWrap: "break-word",
  overflowWrap: "break-word",
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  marginTop: spacing(2),
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginTop: spacing(4),
    marginBottom: spacing(2),
  },
}));

export const DeleteIconRed = styled(DeleteIcon)({
  color: red[400],
});

export const RecommendIconGreen = styled(RecommendIcon)({
  color: green[400],
});

export const CenterContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: spacing(2),
}));

export const AddFeedbackText = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 12,
  marginTop: spacing(0.5),
  marginBottom: spacing(1),
  "@media (min-width:600px)": {
    fontSize: 14,
  },
}));

export const BtnContainer = styled(Typography)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: spacing(0.5),
}));

export const SectionSubTitle = styled(Typography)({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 14,
  "@media (min-width:600px)": {
    fontSize: 16,
  },
});
