import { Box, Container } from "@mui/material";
import React from "react";
import { StyledBox, SectionTitle, StyledLink } from "./style";
import { useTranslation } from "react-i18next";

export default function Rules() {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container maxWidth="lg">
        <SectionTitle>{t("rules_title")}</SectionTitle>
        <StyledBox>
          <ul>
            <li>
              <p>
                {t("rules_title_visa")}
                <StyledLink
                  to={"https://www.passportindex.org/"}
                  target="_blanck"
                >
                  {" "}
                  {t("rules_title_link")}
                </StyledLink>
              </p>
            </li>
            <li>
              <p>{t("rules_title_visa2")}</p>
            </li>
            <li>
              <p>
                <b>{t("rules_1")}</b>
              </p>
            </li>
            <li>
              <p>{t("rules_1.1")}</p>
            </li>
            <li>
              <p>{t("rules_1.2")}</p>
            </li>
            <li>
              <p>{t("rules_1.3")}</p>
            </li>
            <li>
              <p>{t("rules_1.4")}</p>
            </li>
            <li>
              <p>{t("rules_1.5")}</p>
            </li>
            <li>
              <p>{t("rules_1.6")}</p>
            </li>
            <li>
              <p>{t("rules_1.7")}</p>
            </li>
            <li>
              <p>
                <p>{t("rules_1.8")}</p>
              </p>
            </li>
            <li>
              <p>{t("rules_1.9")}</p>
            </li>
            <li>
              <p>{t("rules_1.10")}</p>
            </li>
            <li>
              <p>{t("rules_1.11")}</p>
            </li>
            <li>
              <p>{t("rules_1.12")}</p>
            </li>
            <li>
              <p>{t("rules_1.13")}</p>
            </li>
            <li>
              <p>{t("rules_1.14")}</p>
            </li>
            <li>
              <p>{t("rules_1.15")}</p>
            </li>
            <li>
              <p>{t("rules_1.16")}</p>
            </li>
            <li>
              <p>{t("rules_1.17")}</p>
            </li>
            <li>
              <p>{t("rules_1.18")}</p>
            </li>
            <li>
              <p>{t("rules_1.19")}</p>
            </li>
            <li>
              <p>{t("rules_1.20")}</p>
            </li>
            <li>
              <p>{t("rules_1.21")}</p>
            </li>
            <li>
              <p>{t("rules_1.22")}</p>
            </li>
            <li>
              <p>{t("rules_1.23")}</p>
            </li>
            <li>
              <p>{t("rules_1.24")}</p>
            </li>
            <li>
              <p>{t("rules_1.25")}</p>
            </li>
            <li>
              <p>{t("rules_1.26")}</p>
            </li>
            <li>
              <p>{t("rules_1.27")}</p>
            </li>
            <li>
              <p>{t("rules_1.28")}</p>
            </li>
            <li>
              <p>{t("rules_1.29")}</p>
            </li>
            <li>
              <p>{t("rules_1.30")}</p>
            </li>
            <li>
              <p>{t("rules_1.30.1")}</p>
            </li>
            <li>
              <p>{t("rules_1.30.2")}</p>
            </li>
            <li>
              <p>{t("rules_2")}</p>
            </li>
            <li>
              <p>{t("rules_2.1")}</p>
            </li>
            <li>
              <p>{t("rules_2.2")}</p>
            </li>
            <li>
              <p>{t("rules_2.3")}</p>
            </li>
            <li>
              <p>{t("rules_2.4")}</p>
            </li>
            <li>
              <p>{t("rules_2.5")}</p>
            </li>
            <li>
              <p>{t("rules_2.6")}</p>
            </li>
            <li>
              <p>{t("rules_2.7")}</p>
            </li>
            <li>
              <p>{t("rules_2.8")}</p>
            </li>
          </ul>
        </StyledBox>
      </Container>
    </Box>
  );
}
