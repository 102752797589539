import { Box, Container } from "@mui/material";

import BusGallery from "./components/BusGallery";
import Feedbacks from "./components/Feedbacks";
import AboutText from "./components/AboutText";
import BusRoutes from "components/BusRoutes";
import Seo from "components/Seo";

export default function AboutUsPage() {
  return (
    <>
      <Seo
        title={"seo.about.title"}
        description={"seo.about.description"}
        canonicalLink="aboutus"
      />
      <Box component={"section"}>
        <Container maxWidth="lg">
          <AboutText />
        </Container>
      </Box>

      <Box component={"section"}>
        <Container maxWidth="lg">
          <BusGallery />
        </Container>
      </Box>

      <Box component={"section"}>
        <Container maxWidth="lg">
          <Feedbacks />
        </Container>
      </Box>

      <Box component={"section"}>
        <Container maxWidth="lg">
          <BusRoutes />
        </Container>
      </Box>
    </>
  );
}
