import { Grid, Button, IconButton, Fab, Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { SearchFormWrapper } from "../style";
import dayjs from "dayjs";
import AgentsPicker from "./AgentsPicker";
import { useReports } from "features/reports/useReports";
import DatePicker from "./DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import { getReportPdf } from "services/report";
import LoadReportFab from "./LoadReportFab";

export default function SearchForm() {
  const { t } = useTranslation();
  const { fetchReportsByQuery, revoke } = useReports();

  const initialValues = {
    date_start: dayjs(new Date()).format("YYYY-MM-DD"),
    date_end: dayjs(new Date()).format("YYYY-MM-DD"),
    agent: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={(values) => {
         fetchReportsByQuery({
          email: values.agent,
          start_date: values.date_start,
          end_date: values.date_end,
        });
      }}
    >
      {({ values, resetForm }) => {
        return (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SearchFormWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <DatePicker name="date_start" label={"Дата початку"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePicker name="date_end" label={"Дата кінця"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AgentsPicker name="agent" label={t("Агенти")} />
                    </Grid>
                  </Grid>
                </SearchFormWrapper>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Fab
                    color="error"
                    aria-label="edit"
                    size="medium"
                    onClick={() => {
                      revoke();
                      resetForm();
                    }}
                  >
                    <HighlightOffOutlinedIcon />
                  </Fab>
                  <LoadReportFab
                    email={values.agent}
                    start_date={values.date_start}
                    end_date={values.date_end}
                  />
                </Box>
                <Box>
                  <Fab
                    color="success"
                    aria-label="search"
                    variant="extended"
                    size="medium"
                    sx={{ textTransform: "none" }}
                    type="submit"
                  >
                    <SearchIcon sx={{ mr: 0.5 }} />
                    Пошук
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
