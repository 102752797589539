import { Container, Grid } from "@mui/material";
import {
  ColorWrapper,
  RelativAutocompleteWrapper,
  SearchFormWrapp,
  SearchformWrapper,
  StyledButton,
  SwapWrapper,
} from "../style";
import { useSearchParams } from "react-router-dom";
import { useBuses } from "features/buses/useBuses";
import { useSearchForm } from "features/searchform/useShopForm";
import { useTranslation } from "react-i18next";
import { MouseEvent, useEffect, useMemo, useRef } from "react";
import * as yup from "yup";
import dayjs from "dayjs";
import { useTickets } from "features/tickets/useTickets";
import { Form, Formik, FormikProps } from "formik";

import {
  CityPicker,
  DatePicker,
  DirectionRadioBtn,
  PassengersCount,
} from "components/CommonForms";
import { IFormInitialValues } from "../types";
import SwapBtn from "components/CommonForms/SwapBtn";
import BackDiscount from "components/BackDiscount";

const validationSchema = yup.object({
  city_from: yup.string().required(),
  city_to: yup.string().required(),
});

export default function SearchForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { formData, setFormData, adultCount, childrenCount } = useSearchForm();
  const { getBuses } = useBuses();
  const { clearTicket, clearTickets } = useTickets();
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<IFormInitialValues> | null>();

  const initialValues: IFormInitialValues = useMemo(() => {
    return {
      direction: formData?.direction || searchParams.get("direction") || "",
      city_from: formData?.city_from || searchParams.get("city_from") || "",
      city_to: formData?.city_to || searchParams.get("city_to") || "",
      date_sent: formData?.date_sent || searchParams.get("date_sent") || "",
      date_return:
        dayjs(formData?.date_sent).add(1, "day").format("YYYY-MM-DD") ||
        dayjs(searchParams.get("date_return"))
          .add(1, "day")
          .format("YYYY-MM-DD") ||
        "",
      adult: adultCount || Number(searchParams.get("adult")) || 0,
      children: childrenCount || Number(searchParams.get("children")) || 0,
    };
  }, [
    adultCount,
    childrenCount,
    formData?.city_from,
    formData?.city_to,
    formData?.date_sent,
    formData?.direction,
    searchParams,
  ]);

  useEffect(() => {
    setSearchParams({
      direction: formikRef.current?.values.direction || "",
      city_from: formikRef.current?.values.city_from || "",
      city_to: formikRef.current?.values.city_to || "",
      date_sent: formikRef.current?.values.date_sent || "",
      date_return: formikRef.current?.values.date_return || "",
      adult: `${formikRef.current?.values.adult}` || "",
      children: `${formikRef.current?.values.children}` || "",
    });
  }, [adultCount, childrenCount, formData, setSearchParams]);

  useEffect(() => {
    formikRef.current?.setFieldValue("date_sent", formData?.date_sent);
    formikRef.current?.setFieldValue("date_return", formData?.date_return);
  }, [formData?.date_return, formData?.date_sent]);

  return (
    <ColorWrapper>
      <Container maxWidth="lg">
        <SearchFormWrapp>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              const { city_from, city_to, date_sent } = values;
              clearTicket();
              clearTickets();
              setFormData({
                ...values,
                city_to: t(`${city_to}`),
                city_from: t(`${city_from}`),
                adult: adultCount,
                children: childrenCount,
              });

              getBuses({
                city_to: city_to,
                city_from: city_from,
                date_sent: date_sent,
              });
            }}
            innerRef={(f) => (formikRef.current = f)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <SearchformWrapper>
                  <Grid container spacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={12}>
                      <DirectionRadioBtn name="direction" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, sm: 2, md: 1 }}>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <Grid container spacing={{ xs: 2, sm: 2, md: 1 }}>
                            <Grid item xs={12} sm={12} lg={6} md={6}>
                              <RelativAutocompleteWrapper>
                                <CityPicker
                                  name="city_from"
                                  label={t("city_from")}
                                />

                                <SwapWrapper
                                  onClick={(e: MouseEvent<HTMLDivElement>) => {
                                    e.stopPropagation();
                                    setFieldValue("city_to", values.city_from);
                                    setFieldValue("city_from", values.city_to);
                                    setFormData({
                                      ...values,
                                      city_to: values.city_from,
                                      city_from: values.city_to,
                                    });
                                    clearTicket();
                                    clearTickets();
                                  }}
                                >
                                  <SwapBtn />
                                </SwapWrapper>
                              </RelativAutocompleteWrapper>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={6} md={6}>
                              <CityPicker name="city_to" label={t("city_to")} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Grid container spacing={2}>
                            {values.direction === "oneway" && (
                              <Grid item xs={12} sm={12}>
                                <DatePicker
                                  name="date_sent"
                                  label={t("date_sent")}
                                />
                              </Grid>
                            )}
                            {values.direction === "andBack" && (
                              <Grid item xs={12} sm={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <DatePicker
                                      name="date_sent"
                                      backPicker
                                      label={t("date_sent")}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <DatePicker
                                      name="date_return"
                                      label={t("date_return")}
                                      mindate
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <PassengersCount />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={2}
                          lg={2}
                          alignSelf={"end"}
                        >
                          <StyledButton
                            type="submit"
                            color="info"
                            variant="contained"
                          >
                            {t("searchButton")}
                          </StyledButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <BackDiscount />
                    </Grid>
                  </Grid>
                </SearchformWrapper>
              </Form>
            )}
          </Formik>
        </SearchFormWrapp>
      </Container>
    </ColorWrapper>
  );
}
