import { useCallback, useRef } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { Grid } from "@mui/material";

import { SectionTitle } from "../style";
import { addressData } from "../constans";
import { nanoid } from "nanoid";
import AdressCart from "./AdressCart";
import { useTranslation } from "react-i18next";

const key = process.env.REACT_APP_GOOGLE_MAP_KEY!;

export default function AdressList() {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: key,
  });

  const mapRef = useRef(undefined);

  const onLoad = useCallback(function callback(map: any) {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    mapRef.current = undefined;
  }, []);
  return (
    <>
      <SectionTitle as={"h1"}>{t("company_cash")}</SectionTitle>

      <Grid container spacing={2}>
        {addressData.map((e) => (
          <AdressCart
            adress={e}
            isLoaded={isLoaded}
            onUnmount={onUnmount}
            onLoad={onLoad}
            key={nanoid()}
          />
        ))}
      </Grid>
    </>
  );
}
