import { Typography, Container, Box, CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  SuccessfulContainer,
  SuccessfulSubTitle,
  SuccessfulTitle,
} from "./style";
import { useOrders } from "features/orders/useOrders";
import { useEffect, useState } from "react";
import CircularLoading from "components/CircularLoading";
import LoadTicketBtn from "./LoadTicketBtn";
import GoToMainButton from "./GoToMainButton";
import { useTranslation } from "react-i18next";

export default function SuccessfulBuy() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const {
    OrderByPaymentId,
    fetchOrdersByPaymentId,
    revoke,
    loadingOrders,
    error,
  } = useOrders();
  const [userEmail, setUserEmail] = useState("");
  const paymentId = searchParams.get("paymentId") || "";

  useEffect(() => {
    fetchOrdersByPaymentId(paymentId);

    return () => {
      revoke();
      searchParams.set("paymentId", "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId]);

  useEffect(() => {
    if (OrderByPaymentId) {
      const email = OrderByPaymentId.userEmail;
      setUserEmail(email);
    }
  }, [OrderByPaymentId]);

  if (loadingOrders) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 20, textAlign: "center" }}>
          <CircularLoading size={24} />
          <GoToMainButton />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 20, textAlign: "center" }}>
          <Typography variant="h6" color="error">
            {t("error_loading_the_order")}{" "}
          </Typography>
          <GoToMainButton />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <SuccessfulContainer>
        <Box>
          <SuccessfulTitle>
            {t("your_order_has_been_successfully_placed")}
          </SuccessfulTitle>
          <SuccessfulSubTitle>
            {`${t("to_the_address")} ${userEmail}, ${t(
              "tickets_have_been_sent_to_the_address"
            )}`}
          </SuccessfulSubTitle>
        </Box>
        {OrderByPaymentId?.paymentStatus === "paid" ||
        OrderByPaymentId?.paymentStatus === "nopay" ? (
          <LoadTicketBtn paymentId={paymentId} />
        ) : (
          <CircularProgress color="info" size={24} />
        )}
        <GoToMainButton />
      </SuccessfulContainer>
    </Container>
  );
}
