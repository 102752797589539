import { SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { nanoid } from "nanoid";
import { busImages } from "pages/AboutUs/constans";
import Slider from "components/Slider";
import { SectionTitle } from "../style";

export default function BusGallery() {
  return (
    <>
      <SectionTitle>Галерея</SectionTitle>
      <>
        <Slider>
          {busImages.map(({ src, alt }) => (
            <SwiperSlide key={nanoid()} className=".swiperSlide">
              <img src={src} alt={alt} height={400} width={"100%"} />
            </SwiperSlide>
          ))}
        </Slider>
      </>
    </>
  );
}
