import { Box, Grid, IconButton } from "@mui/material";
import {
  DeleteIconRed,
  FeedbackHeadText,
  FeedbacksActionsAside,
  FeedbacksAvatar,
  FeedbacksCartContainer,
  FeedbackSecondText,
  FeedbacksText,
  RecommendIconGreen,
  RowContainer,
  SectionContainer,
  StyledStarIcon,
} from "./style";
import dayjs from "dayjs";

import { useReviews } from "features/reviews/useReviews";
import { IReviews } from "./types";
import { useUser } from "features/user/useUser";

interface IFeedbacksCartProps {
  feedback: IReviews;
  isAdmin: boolean;
}

export default function FeedbackCart({
  feedback,
  isAdmin,
}: IFeedbacksCartProps) {
  const { deleteReviewById, approveReviewById } = useReviews();
  const { role } = useUser();
  const { rating, userName, text, date, docId, approved } = feedback;
  const userRole = role === "admin";
  return (
    <Grid item xs={12} sm={6}>
      <SectionContainer>
        <FeedbacksCartContainer>
          <RowContainer>
            <RowContainer>
              <FeedbacksAvatar aria-label="recipe">
                {userName?.split("")[0]}
              </FeedbacksAvatar>
              <FeedbackHeadText>{userName}</FeedbackHeadText>
            </RowContainer>
            <RowContainer>
              <FeedbackHeadText>{rating}</FeedbackHeadText>
              <StyledStarIcon />
            </RowContainer>
          </RowContainer>

          <FeedbacksText>{text}</FeedbacksText>

          <RowContainer>
            <Box>
              <FeedbackSecondText>
                {dayjs(date?.seconds! * 1000).format("DD.MM.YYYY")}
              </FeedbackSecondText>
            </Box>
            {isAdmin && userRole && (
              <FeedbacksActionsAside>
                <IconButton
                  aria-label="delete"
                  onClick={() => deleteReviewById(docId!)}
                >
                  <DeleteIconRed />
                </IconButton>
                <IconButton
                  aria-label="approved"
                  onClick={() => approveReviewById(docId!)}
                  disabled={approved}
                >
                  <RecommendIconGreen />
                </IconButton>
              </FeedbacksActionsAside>
            )}
          </RowContainer>
        </FeedbacksCartContainer>
      </SectionContainer>
    </Grid>
  );
}
