import { useUser } from "features/user/useUser";

import { ROLES } from "./constans";
import ProfileNav from "./ProfileNav";
import ProfileError from "components/ProfileError";

export default function ProfileNavBar() {
  const { role } = useUser();

  if (role === ROLES.ADMIN) {
    return <ProfileNav role={ROLES.ADMIN} />;
  }
  if (role === ROLES.AGENT) {
    return <ProfileNav role={ROLES.AGENT} />;
  }
  if (role === ROLES.USER) {
    return <ProfileNav role={ROLES.USER} />;
  }
  return <ProfileError />;
}
