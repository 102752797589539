import { Pagination, Autoplay } from "swiper/modules";
import css from "./Slider.module.css";

import { Swiper } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { ReactNode } from "react";

interface ISliderProps {
  children: ReactNode;
}

export default function Slider({ children }: ISliderProps) {
  return (
    <Swiper
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
      }}
      modules={[Autoplay, Pagination]}
      className={css.swiper}
    >
      {children}
    </Swiper>
  );
}
