import { Box, CircularProgress, Container } from "@mui/material";
import SearchForm from "./components/SearchForm";
import { useReports } from "features/reports/useReports";
import { useEffect } from "react";
import ReportsList from "./components/ReportsList";

export default function ReportPage() {
  const { fetchAgents, loadingReports } = useReports();

  useEffect(() => {
    fetchAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Container maxWidth="md">
        <SearchForm />
      </Container>

      {!loadingReports && (
        <Container maxWidth="md">
          <ReportsList />
        </Container>
      )}

      {loadingReports && (
        <Container maxWidth="md">
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <CircularProgress size={80} />
          </Box>
        </Container>
      )}
    </>
  );
}
