import { SectionTitle, SimpleText, SectionContainer } from "../style";
import { Grid, InputLabel } from "@mui/material";
import { FastPhoneField, FastTextField } from "components/CommonForms";
import { useUser } from "features/user/useUser";

import { memo } from "react";
import { useTranslation } from "react-i18next";

function ClientData() {
  const { user } = useUser();
  const { t } = useTranslation();
  return (
    <SectionContainer elevation={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle>{t("contact_information")}</SectionTitle>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel shrink htmlFor={`email`}>
                {t("email_1")}
              </InputLabel>
              <FastTextField readonly={!!user} type="email" name="email" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel shrink htmlFor={`phone`}>
                {t("phone")}
              </InputLabel>
              <FastPhoneField name="phone" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SimpleText>{t("correct_email_and_phone")}</SimpleText>
        </Grid>
      </Grid>
    </SectionContainer>
  );
}

export default memo(ClientData);
