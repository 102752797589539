import { Box, Container, Grid } from "@mui/material";
import { useNews } from "features/news/useNews";
import { useEffect } from "react";
import { nanoid } from "nanoid";
import { LoadingBox, SectionTitle } from "./style";
import NewsCard from "components/NewsCard";
import CircularLoading from "components/CircularLoading";
import LoadMoreBtn from "components/LoadMoreBtn";
import { useTranslation } from "react-i18next";
import Link from "components/Link";
import Seo from "components/Seo";

export default function NewsPage() {
  const {
    newsData,
    fetchNews,
    fetchCurrentNews,
    loadingNews,
    revoke,
    fetchNextPageOfNews,
    hasMore,
    loadingNextPage,
  } = useNews();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchNews();
    return () => {
      revoke();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Seo
        title={"seo.news.title"}
        description={"seo.news.description"}
        canonicalLink="news"
      />

      <Box component="section">
        <Container maxWidth="md">
          <SectionTitle as={"h1"}>{t("news_and_articles")}</SectionTitle>
          {!loadingNews && (
            <Grid container spacing={2} alignItems={"center"}>
              {newsData &&
                Object.keys(newsData).map((id) => {
                  const e = newsData[id];

                  return (
                    e && (
                      <Grid item xs={12} sm={6} key={nanoid()}>
                        <Link
                          to={`/news/${e.docId}`}
                          onClick={() => fetchCurrentNews(e.docId)}
                        >
                          <NewsCard news={e} />
                        </Link>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          )}
        </Container>
      </Box>

      {loadingNews && (
        <LoadingBox>
          <CircularLoading size={60} />
        </LoadingBox>
      )}

      {hasMore && !loadingNews && (
        <LoadMoreBtn
          handleClick={fetchNextPageOfNews}
          loading={loadingNextPage}
        />
      )}
    </>
  );
}
