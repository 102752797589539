import { ListItem } from "@mui/material";
import {
  AboutTextList,
  AboutTextTitle,
  SectionContainer,
  SimpleText,
} from "../style";
import { useTranslation } from "react-i18next";

export default function AboutText() {
  const { t } = useTranslation();
  return (
    <>
      <AboutTextTitle>
        <span>Musil</span>
        <span className="yellow">Tour</span> – {t("about_text_title")}
      </AboutTextTitle>
      <SectionContainer>
        <AboutTextList>
          <ListItem>
            <SimpleText>{t("about_text_1")}</SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>{t("about_text_2")}</SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>{t("about_text_3")}</SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>Musil Tour s.r.o.</span> {t("about_text_4")}
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>{t("about_text_4")}</SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              {t("about_text_6")} <span> Musil Tour s.r.o.</span>
            </SimpleText>
          </ListItem>
        </AboutTextList>
      </SectionContainer>
    </>
  );
}
