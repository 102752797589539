import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function GoToMainButton() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <Button
        variant="outlined"
        color="info"
        onClick={() => {
          navigate(`/${i18n.language}`);
        }}
        sx={{ mt: 1 }}
      >
        {t("main")}
      </Button>
    </Box>
  );
}
