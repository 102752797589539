import { saveAs } from "file-saver";
import { getReportPDF } from "./functions";

export interface IProps {
  email: string;
  start_date: string;
  end_date: string;
}

export type TPodPdf = any;

export const getReportPdf = async ({
  email,
  start_date,
  end_date,
}: // eslint-disable-next-line consistent-return
IProps): Promise<TPodPdf> => {
  try {
    await getReportPDF({ email, start_date, end_date }).then(
      (response: any) => {
        const byteCharacters = atob(response.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        // @ts-ignore
        const blob = new Blob([byteArray], {
          type: "application/pdf",
        });

        saveAs(blob, "Звіт");
      }
    );
  } catch (error) {
    return "error";
  }
};
