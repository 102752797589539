import { Grid } from "@mui/material";
import { useReports } from "features/reports/useReports";
import ReportEl from "./ReportEl";
import { nanoid } from "nanoid";

export default function ReportsList() {
  const { tickets,error } = useReports();

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      {tickets &&!error&& tickets.map((e) => <ReportEl el={e} key={nanoid()} />)}
    </Grid>
  );
}
