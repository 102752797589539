import { IconButton } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { AddFeedbackText, CenterContainer } from "../style";
import { useTranslation } from "react-i18next";

interface IOpenFormBtnProps {
  onFormOpen: () => void;
}

export default function OpenFormBtn({ onFormOpen }: IOpenFormBtnProps) {
  const { t } = useTranslation();
  return (
    <CenterContainer>
      <IconButton
        color="info"
        aria-label="open-form"
        size="large"
        onClick={() => onFormOpen()}
      >
        <ControlPointIcon fontSize="large" />
      </IconButton>
      <AddFeedbackText>{t("add_review")}</AddFeedbackText>
    </CenterContainer>
  );
}
