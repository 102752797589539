import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { useBuses } from "features/buses/useBuses";
import { Formik, Form, FormikProps } from "formik";
import { useEffect, useMemo, useRef } from "react";
import { useSearchForm } from "features/searchform/useShopForm";

import {
  CityPicker,
  DatePicker,
  PassengersCount,
} from "components/CommonForms";
import * as yup from "yup";

import { IFormInitialValues } from "../types";
import { SearchFormBtn, SearchFormWrapper } from "../style";
import { useTranslation } from "react-i18next";

export const validationSchema = yup.object({
  city_from: yup.string().required(),
  city_to: yup.string().required(),
});

export default function SearchForm() {
  const { adultCount, childrenCount, formData, setFormData } = useSearchForm();
   const formikRef = useRef<FormikProps<IFormInitialValues> | null>();
  const { t } = useTranslation();

  const initialValues: IFormInitialValues = useMemo(() => {
    return {
      city_from: formData?.city_from || "",
      city_to: formData?.city_to || "",
      adult: adultCount,
      children: childrenCount,
      date_sent: formData?.date_sent || dayjs(new Date()).format("YYYY-MM-DD"),
    };
  }, [
    adultCount,
    childrenCount,
    formData?.city_from,
    formData?.city_to,
    formData?.date_sent,
  ]);

  useEffect(() => {
    formikRef.current?.setFieldValue("date_sent", formData?.date_sent);
  }, [formData?.date_return, formData?.date_sent]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={(values) => {
        const { city_from, city_to, date_sent } = values;
        setFormData({
          city_from: t(city_from),
          city_to: t(city_to),
          date_sent,
        });
      }}
      innerRef={(f) => (formikRef.current = f)}
    >
      {({ values }) => {
        return (
          <Form>
            <SearchFormWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <CityPicker name="city_from" label={t("city_from")} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CityPicker name="city_to" label={t("city_to")} />
                </Grid>

                <Grid item xs={12} md={2}>
                  <Grid item xs={12} sm={12}>
                    <DatePicker name="date_sent" label={t("date_sent")} />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2}>
                  <PassengersCount />
                </Grid>

                <Grid item xs={12} md={2}>
                  <SearchFormBtn
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {t("searchButton")}
                  </SearchFormBtn>
                </Grid>
              </Grid>
            </SearchFormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
}
