import { Box, Container } from "@mui/material";
import TextEditor from "./TextEditor";
import OpenFormBtn from "./OpenFormBtn";
import { useEffect, useState } from "react";
import { useNews } from "features/news/useNews";

export default function NewsEditor() {
  const [showForm, setShowForm] = useState(false);
  const { сurrentNews } = useNews();

  useEffect(() => {
    if (сurrentNews) {
      setShowForm(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [сurrentNews]);

  const handleToggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <Box component={"section"}>
      <Container maxWidth="lg">
        {showForm ? (
          <TextEditor onFormClose={handleToggleForm} />
        ) : (
          <OpenFormBtn onFormOpen={handleToggleForm} />
        )}
      </Container>
    </Box>
  );
}
