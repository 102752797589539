import { createBrowserRouter } from "react-router-dom";
import Main from "pages/Main";
import Contacts from "pages/Contacts";
import News from "pages/News";
// import Layout from "components/MainLayout";
import Buses from "pages/Buses";
import Error from "pages/Error";
import FaqPage from "pages/Faq";
import Checkout from "pages/Checkout";
import Successful from "pages/SuccessfulBuy";
import { createRouteId } from "./utils/createRouteId";
import { paths } from "./utils/paths";
import PrivacyPolicyPage from "pages/PrivacyPolicy";
import Rules from "pages/Rules";
import Profile from "pages/Profile";
import HistoryPage from "pages/Profile/History";
import BusesManagement from "pages/Profile/BusesManagement";
import ProfileSettings from "pages/Profile/ProfileSettings";
import ReviewsPage from "pages/Profile/Reviews";
import AboutUsPage from "pages/AboutUs";
import ManagementNews from "pages/Profile/ManagementNews";
import NewsDetail from "pages/NewsDetail";
import LanguagePath from "./LanguagePath";
import ProtectedRoute from "./ProtectedRoute";
import Oferta from "pages/Oferta";
import ReportPage from "pages/Profile/ReportPage";
import FirstLayout from "components/FirstLayout";
import SecondLayout from "components/SecondLayout";
import { lazy, Suspense } from "react";
import LoaderPage from "components/LoaderPage";
const Layout = lazy(() => import("components/MainLayout"));

const components = [
  {
    index: true,
    element: (
      <FirstLayout>
        <Main />
      </FirstLayout>
    ),
  },

  {
    path: "faq",
    element: (
      <FirstLayout>
        <FaqPage />
      </FirstLayout>
    ),
  },
  {
    path: "rules",
    element: (
      <FirstLayout>
        <Rules />
      </FirstLayout>
    ),
  },

  {
    path: "news",
    element: (
      <FirstLayout>
        <News />
      </FirstLayout>
    ),
  },
  {
    path: "news/:id",
    element: (
      <FirstLayout>
        <NewsDetail />
      </FirstLayout>
    ),
  },
  {
    path: "contacts",
    element: (
      <FirstLayout>
        <Contacts />
      </FirstLayout>
    ),
  },

  {
    path: "buses",
    element: (
      <SecondLayout>
        <Buses />
      </SecondLayout>
    ),
  },
  {
    path: "checkout",
    element: (
      <SecondLayout>
        <Checkout />
      </SecondLayout>
    ),
  },
  {
    path: "aboutus",
    element: (
      <FirstLayout>
        <AboutUsPage />
      </FirstLayout>
    ),
  },
  {
    path: "successful",
    element: (
      <FirstLayout>
        <Successful />
      </FirstLayout>
    ),
  },
  {
    path: "oferta",
    element: (
      <FirstLayout>
        <Oferta />
      </FirstLayout>
    ),
  },
  {
    path: "privacypolicy",
    element: (
      <FirstLayout>
        <PrivacyPolicyPage />
      </FirstLayout>
    ),
  },
  {
    element: (
      <SecondLayout>
        <ProtectedRoute />
      </SecondLayout>
    ),
    children: [
      {
        path: paths.agent.path,
        element: <Profile />,
        id: createRouteId("agent"),
        children: [
          { index: true, element: <BusesManagement /> },
          {
            path: "history",
            element: <HistoryPage />,
          },
          { path: "settings", element: <ProfileSettings /> },
        ],
      },
      {
        path: paths.admin.path,
        element: <Profile />,
        id: createRouteId("admin"),
        children: [
          { index: true, element: <BusesManagement /> },
          {
            path: "history",
            element: <HistoryPage />,
          },
          { path: "settings", element: <ProfileSettings /> },
          { path: "reviews", element: <ReviewsPage /> },
          { path: "manager-news", element: <ManagementNews /> },
          { path: "report", element: <ReportPage /> },
        ],
      },
      {
        path: paths.user.path,
        element: <Profile />,
        id: createRouteId("user"),
        children: [
          { index: true, element: <ProfileSettings /> },
          {
            path: "history",
            element: <HistoryPage />,
          },
          { path: "reviews", element: <ReviewsPage /> },
        ],
      },
    ],
  },
];

const router = createBrowserRouter([
  {
    element: (
      <Suspense fallback={<LoaderPage />}>
        <Layout />
      </Suspense>
    ),
    errorElement: <Error />,

    path: "/",
    children: [
      {
        path: "/:lang",
        element: <LanguagePath />,
        children: components,
      },
    ],
  },
]);

export default router;
