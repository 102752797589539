import { LoadingButton } from "@mui/lab";
import { Box, styled } from "@mui/material";

export const StyledLoadingButton = styled(LoadingButton)(
  ({ theme: { spacing } }) => ({
    textTransform: "none",
    alignSelf: "center",
  })
);
export const CenterContainer = styled(Box)(({ theme: { spacing } }) => ({
  textAlign: "center",
  marginTop: spacing(2),
}));
