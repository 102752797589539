import dayjs from "dayjs";
import { useTickets } from "features/tickets/useTickets";
import { useSearchForm } from "features/searchform/useShopForm";
import {
  NotFoundBusContiner,
  NotFoundSubTitle,
  NotFoundTitle,
  StyledCheckCircleOutlineIcon,
  StyledFmdBadIcon,
} from "./style";
import { useTranslation } from "react-i18next";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Box, Button, CircularProgress } from "@mui/material";
import { useBuses } from "features/buses/useBuses";
import { nanoid } from "nanoid";

export default function NotFoundBus() {
  const { ticketsTo } = useTickets();
  const { formData, setFormData } = useSearchForm();
  const { closestBus } = useBuses();

  // const { i18n, t } = useTranslation();

  return (
    <NotFoundBusContiner>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
          mt: 1,
        }}
      >
        <NotFoundTitle>
          Квитки на {dayjs(formData.date_sent).format("DD.MM")} не знайдені{" "}
        </NotFoundTitle>
        <StyledFmdBadIcon />
      </Box>
      {closestBus && closestBus.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            alignItems: { xs: "flex-start", sm: "center" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <StyledCheckCircleOutlineIcon />
            <NotFoundSubTitle>Ми знайшли квитки на такі дати:</NotFoundSubTitle>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            {closestBus
              .filter((e) => e.freeSeats)
              .map((e) => (
                <Box key={nanoid()}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      !!ticketsTo
                        ? setFormData({
                            date_return: dayjs(e.departureDate).format(
                              "YYYY-MM-DD"
                            ),
                          })
                        : setFormData({
                            date_sent: dayjs(e.departureDate).format(
                              "YYYY-MM-DD"
                            ),
                          });
                    }}
                  >
                    {dayjs(e.departureDate).format("DD.MM")}
                  </Button>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </NotFoundBusContiner>
  );
}
