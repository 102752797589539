import { useTranslation } from "react-i18next";
import { CenterContainer, StyledLoadingButton } from "./style";

interface ILoadMoreBtnProps {
  loading: boolean;
  handleClick: () => void;
}

export default function LoadMoreBtn({
  loading,
  handleClick,
}: ILoadMoreBtnProps) {
  const { t } = useTranslation();
  return (
    <CenterContainer>
      <StyledLoadingButton
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => handleClick()}
      >
        {t("load_more")}
      </StyledLoadingButton>
    </CenterContainer>
  );
}
