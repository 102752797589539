import { Box, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useBuses } from "features/buses/useBuses";
import { useSearchForm } from "features/searchform/useShopForm";
import { nanoid } from "nanoid";
import { IBusTo } from "features/tickets/types";
import { useTickets } from "features/tickets/useTickets";
import BusCart from "components/BusCard";

import {
  NotFoundBus,
  DatePagination,
  busesResultCount,
  SearchResult,
  Skeleton,
} from "components/CommonForms";
import SelectedBusFrom from "./components/SelectedBusFrom";
import SearchForm from "./components/SearchForm";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function BusesPage() {
  const { formData, adultCount, childrenCount } = useSearchForm();
  const { busesData, busesError, isLoading, getBuses, lastError } = useBuses();
  const { setTicketsTo, setTicketsFrom, selectTicket, setTicket, clearTicket } =
    useTickets();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (formData?.direction === "oneway") {
      getBuses({
        ...formData,
        city_from: t(`${formData?.city_from}`),
        city_to: t(`${formData?.city_to}`),
      });
      return;
    }
    getBuses({
      ...formData,
      city_from: selectTicket
        ? t(formData?.city_to || "")
        : t(formData?.city_from || ""),
      city_to: selectTicket
        ? t(formData?.city_from || "")
        : t(formData?.city_to || ""),
      date_sent: selectTicket ? formData?.date_return : formData.date_sent,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adultCount,
    childrenCount,
    formData?.city_from,
    formData?.city_to,
    formData?.date_sent,
    formData?.date_return,
    formData?.direction,
    selectTicket,
  ]);

  useEffect(() => {
    return () => {
      clearTicket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (ticket: IBusTo) => {
    if (formData?.direction === "oneway") {
      setTicketsTo(ticket);
      navigate(`/${i18n.language}/checkout`);

      return;
    } else {
      if (selectTicket) {
        setTicketsFrom(ticket);
        navigate(`/${i18n.language}/checkout`);
      } else {
        setTicket(true);
        setTicketsTo(ticket);
      }
    }
  };

  return (
    <Box component="section">
      <SearchForm />

      {selectTicket && (
        <Container maxWidth="md">
          <SelectedBusFrom />
        </Container>
      )}

      {isLoading && <Skeleton />}

      {!isLoading && (
        <Container maxWidth="md">
          <DatePagination />

          {!!busesResultCount(busesData) && <SearchResult />}

          <Grid container gap={4}>
            {!lastError &&
              !busesError &&
              busesData &&
              busesData
                .filter(({ freeSeats }) => freeSeats && freeSeats.length > 0)
                .map((bus) => (
                  <BusCart
                    busData={bus}
                    handleSelect={handleSelect}
                    key={nanoid()}
                  />
                ))}
          </Grid>

          {(!busesData?.some(({ freeSeats }) => freeSeats) || busesError) && (
            <NotFoundBus />
          )}
        </Container>
      )}
    </Box>
  );
}
