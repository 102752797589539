import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function AdultDdiscount() {
  const { t } = useTranslation();
  return (
    <TextField
      value={t("discountKey-18")}
      size="small"
      fullWidth
      disabled
      inputProps={{
        readOnly: true,
      }}
    />
  );
}
