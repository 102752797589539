import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Container, Grid, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  SectionContainer,
  SectionTitle,
  StickyGridItem,
  PrivacyPolicyText,
  PrivacyPolicyWrapper,
  PointText,
  PaymentTitle,
  PassCountText,
  PassCountPrice,
  BookingDateContainer,
  DateRich,
  DateSimpl,
  RichTitle,
  PoitFromWrapper,
  PoitToWrapper,
  GreyEastIcon,
  PaymentContentWarapper,
  PassengersListWrapper,
  LoadingButtonStyle,
  BookingSectionTitle,
  SpacingDivider,
} from "./style";

import dayjs from "dayjs";
import BackBtn from "components/BackBtn";
import { useTickets } from "features/tickets/useTickets";
import { useSearchForm } from "features/searchform/useShopForm";
import Drawer from "./components/Drawer";
import PaymentAccordion from "./components/PaymentAccordion";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { newOrder } from "services/functions";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { useUser } from "features/user/useUser";
import {
  createPasList,
  createSeatsList,
  normalizeData,
  totalPrice,
  passengersCountAvailable,
  isAllSeatsFilled,
  findePrice,
  notify,
} from "./services/index";

import {
  IerrorArr,
  IFormInitialValues,
  IliqPayData,
  IResponses,
  IRow,
} from "./types";
import LiqPay from "./components/LiqPay";
import { useLocalStorage } from "../../hooks";
import PassengersCart from "./components/PassengersCart";
import ClientData from "./components/ClientData";
import ChoiseSeatsBtn from "./components/ChoiseSeatsBtn";
import { useValidationSchema } from "./services/validationSchema";

export const LOCAL_STORAGE_KEY = {
  PASSENGERS: "passengersList",
  SEATS_LIST_TO: "toSeats",
  SEATS_LIST_FROM: "fromSeats",
};

export default function CheckoutPage() {
  const { ticketsTo, ticketsFrom, clearTickets } = useTickets();
  const { formData } = useSearchForm();
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const formikRef = useRef<FormikProps<IFormInitialValues> | null>();
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [liqPayData, setLiqPayData] = useState<IliqPayData | null>(null);

  const [toSeats, setToSeats] = useLocalStorage<IRow[]>(
    LOCAL_STORAGE_KEY.SEATS_LIST_TO,
    createSeatsList(ticketsTo?.freeSeats)
  );
  const [fromSeats, setFromSeats] = useLocalStorage<IRow[] | null>(
    LOCAL_STORAGE_KEY.SEATS_LIST_FROM,
    ticketsFrom ? createSeatsList(ticketsFrom.freeSeats) : null
  );
  const navigate = useNavigate();
  const storedPassengers = localStorage.getItem(LOCAL_STORAGE_KEY.PASSENGERS);

  const initialValues: IFormInitialValues = useMemo(() => {
    return {
      email: user?.email || "",
      phone: user?.phone || "",
      passengers: storedPassengers
        ? JSON.parse(storedPassengers)
        : createPasList(
            formData?.adult,
            formData?.children,
            ticketsTo?.price,
            ticketsFrom?.price,
            findePrice(ticketsFrom?.prices),
            !!ticketsFrom
          ),
      payment: !!ticketsFrom ? "card" : "nopay",
      totalPriceTo: 0,
      totalPriceFrom: 0,
    };
  }, [
    formData?.adult,
    formData?.children,
    storedPassengers,
    ticketsFrom,
    ticketsTo?.price,
    user?.email,
    user?.phone,
  ]);

  const toggleDrawer =
    (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenDrawer((p) => !p);
    };

  const handleSeatSelect = (seatId: string, direction: string) => {
    const passengersArr = formikRef.current?.values?.passengers!;
    const totalPas = passengersArr.length;
    const pasCountTo = passengersCountAvailable(passengersArr, true);
    const pasCountFrom = passengersCountAvailable(passengersArr);

    if (direction === "to") {
      const pasidx = passengersArr?.findIndex(
        (pas) => pas.seatNumberTo === `${seatId}`
      );
      if (!!~pasidx) {
        formikRef.current?.setFieldValue(
          `passengers.${pasidx}.seatNumberTo`,
          ""
        );
      } else {
        const pasidxNotAvailable = passengersArr.findIndex(
          (pas) => pas.seatNumberTo === ""
        );
        if (pasCountTo >= totalPas) return;
        formikRef.current?.setFieldValue(
          `passengers.${pasidxNotAvailable}.seatNumberTo`,
          `${seatId}`
        );
      }

      setToSeats((prevSeats: IRow[]) =>
        prevSeats.map((row) =>
          row.map((seat) =>
            !!seat && seat.id === seatId
              ? { ...seat, isSelected: !seat.isSelected }
              : seat
          )
        )
      );
    } else {
      const pasidx = passengersArr.findIndex(
        (pas) => pas.seatNumberFrom === `${seatId}`
      );
      if (!!~pasidx) {
        formikRef.current?.setFieldValue(
          `passengers.${pasidx}.seatNumberFrom`,
          ""
        );
      } else {
        const pasidxNotAvailable = passengersArr.findIndex(
          (pas) => pas.seatNumberFrom === ""
        );
        if (pasCountFrom >= totalPas) return;
        formikRef.current?.setFieldValue(
          `passengers.${pasidxNotAvailable}.seatNumberFrom`,
          `${seatId}`
        );
      }

      setFromSeats(
        (prevSeats: IRow[] | null) =>
          prevSeats &&
          prevSeats.map((row) =>
            row.map((seat) =>
              !!seat && seat.id === seatId
                ? { ...seat, isSelected: !seat.isSelected }
                : seat
            )
          )
      );
    }
  };

  const handleSeatAvailable = (availableSeats: IerrorArr[]) => {
    const passengersArr = formikRef.current?.values?.passengers!;

    availableSeats.forEach(({ seat: seatArr, direction }) => {
      if (direction === "to") {
        const pasidx = passengersArr.findIndex(
          (pas) => pas.seatNumberTo === `${seatArr}`
        );
        if (pasidx !== -1) {
          formikRef.current?.setFieldValue(
            `passengers.${pasidx}.seatNumberTo`,
            ""
          );
        }
        setToSeats((prevSeats: IRow[]) =>
          prevSeats.map((row) =>
            row.map((seat) =>
              seat && seat.id === (seatArr || "")
                ? {
                    ...seat,
                    isSelected: !seat.isSelected,
                    isActive: !seat.isActive,
                  }
                : seat
            )
          )
        );
      } else {
        const pasidx = passengersArr.findIndex(
          (pas) => pas.seatNumberFrom === `${seatArr}`
        );
        if (pasidx !== -1) {
          formikRef.current?.setFieldValue(
            `passengers.${pasidx}.seatNumberFrom`,
            ""
          );
        }
        setFromSeats(
          (prevSeats: IRow[] | null) =>
            prevSeats &&
            prevSeats.map((row) =>
              row.map((seat) =>
                seat && seat.id === (seatArr || "")
                  ? {
                      ...seat,
                      isSelected: !seat.isSelected,
                      isActive: !seat.isActive,
                    }
                  : seat
              )
            )
        );
      }
    });
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem(LOCAL_STORAGE_KEY.PASSENGERS);
      localStorage.removeItem(LOCAL_STORAGE_KEY.SEATS_LIST_TO);
      localStorage.removeItem(LOCAL_STORAGE_KEY.SEATS_LIST_FROM);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      clearTickets();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ticketsTo) {
      navigate(`/${i18n.language}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsTo]);

  return (
    <Container maxWidth="lg" sx={{ position: "relative" }}>
      <BackBtn />

      <Formik
        initialValues={initialValues}
        validationSchema={useValidationSchema()}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={async (values, actions) => {
          try {
            setIsLoading(true);
            const response = await newOrder({
              passengerData: normalizeData({
                ticketsTo,
                ticketsFrom,
                formData: {
                  ...values,
                },
                userId: user?.id || null,
              }),
              paymentType: values.payment,
            });
            const responseDataArray = response.data as IResponses[];

            if (
              Array.isArray(responseDataArray) &&
              responseDataArray.some((item) => item.error)
            ) {
              const errorArr = responseDataArray
                .filter((item) => item.error)
                .map((item) => ({
                  seat: item.seat,
                  direction: item.direction,
                }));
              for (const item of errorArr) {
                notify({
                  text: (
                    <ul>
                      <li>
                        {t("place")} {item.seat}{" "}
                        {item.direction === "to" ? t("there") : t("goBack")},{" "}
                        {t("occupied")}
                      </li>
                    </ul>
                  ),
                  type: "error",
                });
              }

              handleSeatAvailable(errorArr);
              setIsLoading(false);
              return;
            }

            const { liqpaydata, signature, paymentType, paymentId } =
              response.data as {
                liqpaydata?: string;
                paymentId?: string;
                signature?: string;
                paymentType: string;
              };
            if (paymentType === "nopay") {
              setIsLoading(false);
              return navigate({
                pathname: `/${i18n.language}/successful`,
                search: createSearchParams({
                  paymentId: paymentId || "",
                }).toString(),
              });
            } else {
              setLiqPayData({
                data: liqpaydata,
                signature: signature,
                paymentId,
              });
              setIsLoading(false);
            }
          } catch (error: any) {
            notify({
              text: t("please_try_again_later"),
              type: "error",
            });
            setIsLoading(false);
          }
        }}
        innerRef={(f) => (formikRef.current = f)}
      >
        {({ values, setFieldValue }) => {
          localStorage.setItem(
            LOCAL_STORAGE_KEY.PASSENGERS,
            JSON.stringify(values.passengers)
          );
          return (
             <>
               {liqPayData && (
                 <LiqPay
                   paymentRef={(liqPayData && liqPayData?.paymentId) || ""}
                   data={(liqPayData && liqPayData?.data) || ""}
                   signature={(liqPayData && liqPayData?.signature) || ""}
                 />
               )}

               <Form autoComplete="off" noValidate>
                 <Grid container spacing={3}>
                   <Grid item xs={12} sm={12} md={7}>
                     <Grid container spacing={2}>
                       <Grid item xs={12}>
                         <BookingSectionTitle>
                           {t("passengersCount")}
                         </BookingSectionTitle>

                         <FieldArray
                           name="passengers"
                           render={(arrayHelpers) => (
                             <PassengersListWrapper>
                               {values.passengers.map((passenger, index) => (
                                 <PassengersCart
                                   index={index}
                                   key={passenger.id}
                                   passenger={passenger}
                                 />
                               ))}
                             </PassengersListWrapper>
                           )}
                         />
                       </Grid>

                       <Grid item xs={12}>
                         <SectionContainer elevation={2}>
                           <Grid container spacing={2} alignItems={"center"}>
                             <Grid item xs={12}>
                               <SectionTitle>
                                 {t("seat_reservation")}
                               </SectionTitle>
                             </Grid>
                             <Grid item xs={12}>
                               <ChoiseSeatsBtn
                                 isAllSeatsFilled={
                                   ticketsFrom
                                     ? isAllSeatsFilled({
                                         passengers: values.passengers,
                                         ticketsFrom: !!ticketsFrom,
                                       })
                                     : isAllSeatsFilled({
                                         passengers: values.passengers,
                                         ticketsFrom: !!ticketsFrom,
                                       })
                                 }
                                 toggleDrawer={toggleDrawer}
                               />
                             </Grid>
                           </Grid>
                         </SectionContainer>

                         <Drawer
                           passengers={values.passengers}
                           drawerState={openDrawer}
                           toggleDrawer={toggleDrawer}
                           seatsListFrom={fromSeats}
                           seatsListTo={toSeats}
                           handleSeatSelect={handleSeatSelect}
                         />
                       </Grid>

                       <Grid item xs={12}>
                         <ClientData />
                       </Grid>

                       <Grid item xs={12}>
                         <SectionContainer elevation={2}>
                           <Grid container spacing={2}>
                             <Grid item xs={12}>
                               <SectionTitle>{t("payment")}</SectionTitle>
                             </Grid>
                             <Grid item xs={12}>
                               <PaymentAccordion
                                 disabled={isLoading}
                                 value={values.payment}
                                 setValue={setFieldValue}
                               />
                             </Grid>
                           </Grid>
                         </SectionContainer>
                       </Grid>
                     </Grid>
                   </Grid>

                   <StickyGridItem item xs={12} sm={12} md={5}>
                     <Grid container spacing={4}>
                       <Grid item xs={12}>
                         <BookingSectionTitle>
                           {t("your_reservation")}
                         </BookingSectionTitle>
                         <Grid container spacing={2}>
                           <Grid item xs={12}>
                             <SectionContainer elevation={2}>
                               <BookingDateContainer>
                                 <DateRich>
                                   {dayjs(ticketsTo?.departureDate)
                                     .locale(i18n.language)
                                     .format("DD MMM")}
                                 </DateRich>
                                 <GreyEastIcon />
                                 <DateSimpl>
                                   {dayjs(ticketsTo?.arrivalDate)
                                     .locale(i18n.language)
                                     .format("DD MMM")}
                                 </DateSimpl>
                               </BookingDateContainer>

                               <PoitFromWrapper>
                                 <Box>
                                   <RichTitle>
                                     {t(`cityKey-${ticketsTo?.pointFromId}`)}
                                   </RichTitle>
                                   <PointText>
                                     {t(`pointKey-${ticketsTo?.pointFromId}`)}
                                   </PointText>
                                 </Box>
                                 <RichTitle>
                                   {ticketsTo?.departureTime}
                                 </RichTitle>
                               </PoitFromWrapper>
                               <PoitToWrapper>
                                 <Box>
                                   <RichTitle>
                                     {t(`cityKey-${ticketsTo?.pointToId}`)}
                                   </RichTitle>
                                   <PointText>
                                     {t(`pointKey-${ticketsTo?.pointToId}`)}
                                   </PointText>
                                 </Box>
                                 <RichTitle>{ticketsTo?.arrivalTime}</RichTitle>
                               </PoitToWrapper>
                             </SectionContainer>

                             <PaymentContentWarapper>
                               <PassCountText>
                                 {formData?.children && formData?.children > 0
                                   ? `${t("Adults")} ${formData?.adult}, ${t(
                                       "Children"
                                     )} ${formData?.children}`
                                   : `${t("Adults")} ${formData?.adult}`}
                               </PassCountText>
                               <PassCountPrice>{`${totalPrice(
                                 values.passengers,
                                 "to"
                               )},00 UAH`}</PassCountPrice>
                             </PaymentContentWarapper>
                             <PaymentContentWarapper>
                               <PassCountText>
                                 {`${t("seat_s")} ${values.passengers
                                   .filter((e) => e.seatNumberTo !== "")
                                   .map((e) => e.seatNumberTo)
                                   .join(",")}`}
                               </PassCountText>
                             </PaymentContentWarapper>
                             <SpacingDivider />
                           </Grid>
                           {!!ticketsFrom && (
                             <Grid item xs={12}>
                               <SectionContainer elevation={2}>
                                 <BookingDateContainer>
                                   <DateRich>
                                     {dayjs(ticketsFrom?.departureDate)
                                       .locale(i18n.language)
                                       .format("DD MMM")}
                                   </DateRich>
                                   <GreyEastIcon />
                                   <DateSimpl>
                                     {dayjs(ticketsFrom?.arrivalDate)
                                       .locale(i18n.language)
                                       .format("DD MMM")}
                                   </DateSimpl>
                                 </BookingDateContainer>
                                 <PoitFromWrapper>
                                   <Box>
                                     <RichTitle>
                                       {t(
                                         `cityKey-${ticketsFrom?.pointFromId}`
                                       )}
                                     </RichTitle>
                                     <PointText>
                                       {t(
                                         `pointKey-${ticketsFrom?.pointFromId}`
                                       )}
                                     </PointText>
                                   </Box>
                                   <RichTitle>
                                     {ticketsFrom?.departureTime}
                                   </RichTitle>
                                 </PoitFromWrapper>

                                 <PoitToWrapper>
                                   <Box>
                                     <RichTitle>
                                       {t(`cityKey-${ticketsFrom?.pointToId}`)}
                                     </RichTitle>
                                     <PointText>
                                       {t(`pointKey-${ticketsFrom?.pointToId}`)}
                                     </PointText>
                                   </Box>
                                   <RichTitle>
                                     {ticketsFrom?.arrivalTime}
                                   </RichTitle>
                                 </PoitToWrapper>
                               </SectionContainer>

                               <PaymentContentWarapper>
                                 <PassCountText>
                                   {formData?.children && formData?.children > 0
                                     ? `${t("Adults")} ${formData?.adult}, ${t(
                                         "Children"
                                       )} ${formData?.children}`
                                     : `${t("Adults")} ${formData?.adult}`}
                                 </PassCountText>

                                 <PassCountPrice>{`${totalPrice(
                                   values.passengers,
                                   "from"
                                 )},00 UAH`}</PassCountPrice>
                               </PaymentContentWarapper>
                               <PaymentContentWarapper>
                                 <PassCountText>
                                   {`${t("seat_s")} ${values.passengers
                                     .filter((e) => e.seatNumberFrom !== "")
                                     .map((e) => e.seatNumberFrom)
                                     .join(",")}`}
                                 </PassCountText>
                               </PaymentContentWarapper>
                               <SpacingDivider />
                             </Grid>
                           )}
                         </Grid>
                       </Grid>

                       <Grid item xs={12}>
                         <PaymentContentWarapper>
                           <PaymentTitle>
                             {t("total")} <span>{t("incl_vat")}</span>
                           </PaymentTitle>
                           <PaymentTitle>{`${
                             ticketsFrom
                               ? totalPrice(values.passengers, "to") +
                                 totalPrice(values.passengers, "from")
                               : totalPrice(values.passengers, "to")
                           },00 UAH`}</PaymentTitle>
                         </PaymentContentWarapper>
                       </Grid>

                       <Grid item xs={12}>
                         <PrivacyPolicyWrapper>
                           <Box>
                             <Checkbox
                               disableRipple
                               onChange={() => {
                                 setTermsChecked((p) => !p);
                               }}
                               checked={termsChecked}
                             />
                           </Box>

                           <PrivacyPolicyText
                             onClick={() => {
                               setTermsChecked((p) => !p);
                             }}
                           >
                             {t("i_confirm_that_i_have_read")}{" "}
                             <span>
                               {
                                 <Link target="_blank" to={`/privacypolicy`}>
                                   {t("privacy_policy")}
                                 </Link>
                               }
                             </span>
                             . {t("agree_terms")}
                           </PrivacyPolicyText>
                         </PrivacyPolicyWrapper>
                       </Grid>
                       <Grid item xs={12}>
                         <LoadingButtonStyle
                           loading={isLoading}
                           fullWidth
                           size="large"
                           disableFocusRipple
                           disableRipple
                           variant="contained"
                           color="info"
                           type="submit"
                           disabled={!termsChecked}
                         >
                           {values.payment === "nopay" ? t("book") : t("pay")}
                         </LoadingButtonStyle>
                       </Grid>
                     </Grid>
                   </StickyGridItem>
                 </Grid>
               </Form>
             </>
           );
        }}
      </Formik>
    </Container>
  );
}
