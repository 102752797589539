import { Grid } from "@mui/material";
import { nanoid } from "nanoid";

import {
  AdressTitle,
  ContactsItemWrapper,
  ContactsListItem,
  ContactsListLink,
  ContactsText,
  SectionContainer,
} from "../style";
import { сontactsPraha } from "../constans";

export default function PrahaContacts() {
  return (
    <SectionContainer props={{ contacts: true }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AdressTitle>MUSIL TOUR, s.r.o. Praha</AdressTitle>
        </Grid>

        <Grid item xs={12}>
          <ContactsText>Křižíkova 344/6</ContactsText>
        </Grid>

        <Grid item xs={12}>
          <ContactsText>18600 Praha, Karlín</ContactsText>
        </Grid>

        <Grid item xs={12}>
          <ContactsText>IČ: 44794363</ContactsText>
        </Grid>

        {сontactsPraha.map((e) => (
          <Grid item xs={12} key={nanoid()}>
            <ContactsListItem>
              {typeof e.icon !== "string" ? (
                <ContactsItemWrapper>{e.icon}</ContactsItemWrapper>
              ) : (
                <ContactsItemWrapper>
                  <img
                    src={e.icon}
                    alt={`Contact icon for ${e.label}`}
                    width={20}
                    height={20}
                  />
                </ContactsItemWrapper>
              )}
              <ContactsListLink props={{ contacts: true }} to={e.link}>
                {e.label}
              </ContactsListLink>
            </ContactsListItem>
          </Grid>
        ))}
      </Grid>
    </SectionContainer>
  );
}
