import { IStore } from "../../store/store";

export const selectReviews = (state: IStore) => state.reviews.reviews;
export const selectError = (state: IStore) => state.reviews.error;
export const selectLoadingReviews = (state: IStore) =>
  state.reviews.loadingReviews;
export const selectLoadingNextPage = (state: IStore) =>
  state.reviews.loadingNextPage;
export const selectLastVisible = (state: IStore) => state.reviews.lastVisible;
export const selectHasMore = (state: IStore) => state.reviews.hasMore;
