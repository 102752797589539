import { Grid } from "@mui/material";
import { contactData } from "components/NavBar/constans";
import { nanoid } from "nanoid";

import {
  ContactsItemWrapper,
  ContactsListItem,
  ContactsListLink,
  ContactUsTitle,
  SectionContainer,
} from "../style";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const { t } = useTranslation();

  return (
    <SectionContainer>
      <Grid container spacing={1}>
        <Grid item>
          <ContactUsTitle>{t("how_to_contact_us")}</ContactUsTitle>
        </Grid>

        {contactData.map((e) => (
          <Grid item xs={12} key={nanoid()}>
            <ContactsListItem>
              {typeof e.icon !== "string" ? (
                <ContactsItemWrapper>{e.icon}</ContactsItemWrapper>
              ) : (
                <ContactsItemWrapper>
                  <img
                    src={e.icon}
                    alt={`Contact icon for ${e.label}`}
                    width={20}
                    height={20}
                  />
                </ContactsItemWrapper>
              )}
              <ContactsListLink to={e.link} target="_blank">
                {e.label}
              </ContactsListLink>
            </ContactsListItem>
          </Grid>
        ))}
      </Grid>
    </SectionContainer>
  );
}
