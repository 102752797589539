import KryvyiRihKassa from "./images/Kryvyi-Rih-Kassa.jpg";
import MusilTourKassa9 from "./images/MusilTour-Kassa-9.jpeg";
import MusilTourKassa8 from "./images/MusilTour-Kassa-8.jpeg";
import Zaporizhzhia from "./images/Zaporizhzhia.jpg";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

interface IImage {
  src: string;
  alt: string;
  width: number;
  height: number;
}

interface IPosition {
  lat: number;
  lng: number;
}

export interface IAddressData {
  address: string;
  workingHours: string[];
  contacts: string[];
  image: IImage;
  position: IPosition;
}

export interface ContactItem {
  icon: JSX.Element;
  link: string;
  label: string;
}

export const addressData: IAddressData[] = [
  {
    address: "ukraine_zaporizhzhia_bus_station",
    workingHours: ["monday_sunday"],
    contacts: ["+380673948045"],
    image: {
      src: Zaporizhzhia,
      alt: "Zaporizhzhia-Kassa",
      width: 250,
      height: 250,
    },
    position: { lat: 47.80461445036507, lng: 35.18497857605157 },
  },

  {
    address: "kryvyi_rih_bus_station",
    workingHours: ["monday_sunday"],
    contacts: ["+380675650062"],
    image: {
      src: KryvyiRihKassa,
      alt: "Kryvyi-Rih-Kassa",
      width: 250,
      height: 250,
    },
    position: { lat: 47.90984949351324, lng: 33.42222683068456 },
  },
  {
    address: "prague_florenc_bus_station_9",
    workingHours: ["monday_sunday"],
    contacts: ["+420221895615", "+420777719524"],
    image: {
      src: MusilTourKassa9,
      alt: "Kryvyi-Rih-Kassa",
      width: 250,
      height: 250,
    },
    position: { lat: 50.08972307293278, lng: 14.440771460287944 },
  },
  {
    address: "prague_florenc_bus_station_6",
    workingHours: ["monday_sunday"],
    contacts: ["+420221895615", "+420777719524"],
    image: {
      src: MusilTourKassa8,
      alt: "Kryvyi-Rih-Kassa",
      width: 250,
      height: 250,
    },
    position: { lat: 50.08972307293278, lng: 14.440771460287944 },
  },
];

export const сontactsPraha: ContactItem[] = [
  {
    link: `tel:${+420777719524}`,
    label: "+420777719524",
    icon: <CallIcon color="action" fontSize="small" />,
  },

  {
    link: `tel:${+420776109386}`,
    label: "+420776109386",
    icon: <CallIcon color="action" fontSize="small" />,
  },

  {
    link: `mailto:rezervacejizdenek@musiltour.cz`,
    label: "rezervacejizdenek@musiltour.cz",
    icon: <AlternateEmailIcon color="action" fontSize="small" />,
  },
];
