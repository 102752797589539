import { IconButton, Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'; import { setPayment } from "services/functions";
import { red } from "@mui/material/colors";

interface IChangeStatusProps {
  paymentId: string;
}

export default function ChangeStatus({ paymentId }: IChangeStatusProps) {
  const [loading, setLoading] = useState(false);
 
  const handlClick = (paymentId: string) => {
    setLoading(true);
    setPayment(paymentId);
  };

  return (
    <Box>
      <IconButton
        disabled={loading}
        onClick={() => handlClick(paymentId)}
        color="success"
      >
        {!loading ? (
          <CurrencyExchangeIcon sx={{color:red[800]}} />
        ) : (
          <CircularProgress color="secondary" size={24} />
        )}
      </IconButton>
    </Box>
  );
}
