import { useEffect } from "react";
import { useReviews } from "features/reviews/useReviews";
import FeedbackCart from "components/FeedbackCart";
import { CenterContainer, SectionSubTitle, SectionTitle } from "../style";
import CircularLoading from "components/CircularLoading";
import { Grid } from "@mui/material";
import LoadMoreBtn from "components/LoadMoreBtn";
import { useTranslation } from "react-i18next";

export default function Feedbacks() {
  const { t } = useTranslation();
  const {
    fetchReviews,
    reviews,
    loadingReviews,
    error,
    revoke,
    hasMore,
    fetchNextPageOfReviews,
    loadingNextPage,
  } = useReviews();

  useEffect(() => {
    fetchReviews();
    return () => revoke();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!error && !loadingReviews && (
        <>
          <SectionTitle>{t("reviews_")}</SectionTitle>
          <Grid container spacing={2}>
            {reviews &&
              Object.keys(reviews)?.map((id) => {
                const e = reviews[id];
                return (
                  e && (
                    <FeedbackCart key={e?.docId} isAdmin={true} feedback={e} />
                  )
                );
              })}
          </Grid>
        </>
      )}

      {loadingReviews && <CircularLoading size={80} />}

      {!error && !loadingReviews && hasMore && (
        <LoadMoreBtn
          handleClick={fetchNextPageOfReviews}
          loading={loadingNextPage}
        />
      )}

      {error && (
        <CenterContainer>
          <SectionSubTitle>{t("no_reviews_found")}</SectionSubTitle>
        </CenterContainer>
      )}
    </>
  );
}
