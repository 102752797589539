import { useEffect, useState } from "react";

export const useLocalStorage = <T>(key: string, defaultValue?: T) => {
  const [value, setValue] = useState<T>(() => {
    const valueFromStorage = localStorage.getItem(key);
    return valueFromStorage ? JSON.parse(valueFromStorage) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};
