import { Box, Container } from "@mui/material";
import { useNews } from "features/news/useNews";
import parse from "html-react-parser";
import { GlobalStyles } from "./style";
import { useEffect } from "react";
import BackBtn from "components/BackBtn";

export default function NewsDetail() {
  const { сurrentNews, revokeCurrent } = useNews();

  useEffect(() => {
    return () => {
      revokeCurrent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={"section"}>
      <Container maxWidth="md">
        <BackBtn />
        <GlobalStyles>{parse(сurrentNews?.text || "")}</GlobalStyles>
      </Container>
    </Box>
  );
}
