import { useSelector } from "react-redux";
import { IUseOrders } from "./types";
import {
  deleteOrderById,
  fetchNextPageOfOrders,
  fetchOrders,
  fetchOrdersByPaymentId,
  fetchOrdersByPhoneNumber,
} from "./operation";
import {
  selectOrdersData,
  selectError,
  selectLoadingOrders,
  selectLoadingNextPage,
  selectHasMore,
  selectLastVisible,
  selectOrderByPaymentId,
} from "./selector";

import { useAppDispatch } from "store/hooks";
import { revoke } from "./slice";

export const useOrders = (): IUseOrders => {
  const orders = useSelector(selectOrdersData);
  const error = useSelector(selectError);
  const loadingOrders = useSelector(selectLoadingOrders);
  const lastVisible = useSelector(selectLastVisible);
  const loadingNextPage = useSelector(selectLoadingNextPage);
  const hasMore = useSelector(selectHasMore);
  const OrderByPaymentId = useSelector(selectOrderByPaymentId);

  const dispatch = useAppDispatch();

  return {
    orders,
    loadingOrders,
    error,
    lastVisible,
    hasMore,
    loadingNextPage,
    OrderByPaymentId,
    revoke: () => {
      dispatch(revoke());
    },
    fetchOrders: () => {
      dispatch(fetchOrders());
    },
    fetchNextPageOfOrders: () => {
      dispatch(fetchNextPageOfOrders());
    },
    fetchOrdersByPhoneNumber: (phoneNumber) => {
      dispatch(fetchOrdersByPhoneNumber(phoneNumber));
    },

    fetchOrdersByPaymentId: (paymentId) => {
      dispatch(fetchOrdersByPaymentId(paymentId));
    },

    deleteOrderById: (paymentId) => {
      dispatch(deleteOrderById(paymentId));
    },
  };
};
