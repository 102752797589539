import { Box, Container, Grid } from "@mui/material";
import { useNews } from "features/news/useNews";
import { nanoid } from "nanoid";
import NewsCard from "components/NewsCard";
import { useUser } from "features/user/useUser";
import { SectionTitle } from "../style";

export default function NewsList() {
  const { newsData } = useNews();
  const { role } = useUser();
  const userRole = role === "admin";
 
  return (
    <Box component={"section"}>
      <Container maxWidth="md">
        <SectionTitle>Новини</SectionTitle>

        <Grid container spacing={1} alignItems={"center"}>
          {newsData &&
            Object.keys(newsData).map((id) => {
              const e = newsData[id];

              return (
                e && (
                  <Grid item xs={12} sm={6} key={e.docId}>
                    <NewsCard news={e} key={nanoid()} isAdmin={userRole} />
                  </Grid>
                )
              );
            })}
        </Grid>
      </Container>
    </Box>
  );
}
