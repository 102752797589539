import { Drawer as MuiDrawer } from "@mui/material";
import { IPassengers, IRow } from "../types";
import { ReactNode, memo, useState } from "react";
import DrawerSeatsScreen from "./DrawerSeatsScreen";
import DrawerFirstScreen from "./DrawerFirstScreen";

interface IMainDrawerProps {
  children?: ReactNode;
  drawerState: boolean;
  passengers: IPassengers[];
  seatsListTo: IRow[] | null;
  seatsListFrom: IRow[] | null;
  handleSeatSelect: (seatId: string, direction: string) => void;

  toggleDrawer: (
    inOpen: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

function Drawer({
  handleSeatSelect,
  toggleDrawer,
  seatsListTo,
  seatsListFrom,
  passengers,
  drawerState,
}: IMainDrawerProps) {
  const [content, setContent] = useState({
    main: true,
    to: false,
    from: false,
  });

  const allSeats = passengers.length;
  const reservedSetasTo = passengers.filter(
    (e) => e.seatNumberTo !== ""
  ).length;
  const reservedSetasFrom = passengers.filter(
    (e) => e.seatNumberFrom !== ""
  ).length;

  const handleContentDrawer = (name: string) => {
    switch (name) {
      case "main":
        return setContent((p) => ({
          ...p,
          main: true,
          from: false,
          to: false,
        }));
      case "to":
        return setContent((p) => ({ ...p, main: false, to: true }));

      case "from":
        return setContent((p) => ({ ...p, main: false, from: true }));

      default:
        return content;
    }
  };

  return (
    <MuiDrawer anchor="right" open={drawerState} onClose={toggleDrawer(false)}>
      {content.main && (
        <DrawerFirstScreen
          toggleDrawer={toggleDrawer}
          toggleContent={handleContentDrawer}
          allSeats={allSeats}
          reservedSetasTo={reservedSetasTo}
          reservedSetasFrom={reservedSetasFrom}
          totalReserved={reservedSetasFrom + reservedSetasTo}
        />
      )}
      {content.to && (
        <DrawerSeatsScreen
          toggleDrawer={toggleDrawer}
          handleSeatSelect={handleSeatSelect}
          seatsList={seatsListTo}
          toggleContent={handleContentDrawer}
          allSeats={allSeats}
          reservedSetasTo={reservedSetasTo}
          reservedSetasFrom={reservedSetasFrom}
        />
      )}
      {content.from && (
        <DrawerSeatsScreen
          backDrawer
          toggleDrawer={toggleDrawer}
          handleSeatSelect={handleSeatSelect}
          seatsList={seatsListFrom}
          toggleContent={handleContentDrawer}
          allSeats={allSeats}
          reservedSetasTo={reservedSetasTo}
          reservedSetasFrom={reservedSetasFrom}
        />
      )}
    </MuiDrawer>
  );
}

export default memo(Drawer);
