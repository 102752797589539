import { IconButton, Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { getPodPdf } from "services/ticket";

interface ILoadTicketBtnProps {
  paymentId: string;
}

export default function LoadTicketBtn({ paymentId }: ILoadTicketBtnProps) {
  const [loading, setLoading] = useState(false);

  const handlClick = () => {
    setLoading(true);
    getPodPdf({ paymentRefId: paymentId }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Box>
      <IconButton
        disabled={loading}
        onClick={() => handlClick()}
        color="success"
      >
        {!loading ? (
          <DownloadingIcon />
        ) : (
          <CircularProgress color="secondary" size={24} />
        )}
      </IconButton>
    </Box>
  );
}
