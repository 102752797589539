import { Accordion, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import { useState } from "react";
import {
  ExpandedSummary,
  Question,
  QuestionSubTitle,
  QuestionTitle,
  SectionContainer,
} from "../style";
import useFaqData from "../constans"; // Corrected the import

export default function QuestionList() {
  const [selected, setSelected] = useState<string | false>("panel1");
  const [subSelected, setSubSelected] = useState<string | false>("subpanel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSelected(isExpanded ? panel : false);
    };

  const handleSubChange =
    (subPanel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSubSelected(isExpanded ? subPanel : false);
    };

  const faqData = useFaqData().general;

  return (
    <SectionContainer>
      <Grid container>
        {faqData.map(({ id, question, answer }) => (
          <Grid item xs={12} key={id}>
            <Accordion
              disableGutters
              elevation={0}
              expanded={selected === id}
              onChange={handleChange(id)}
            >
              <ExpandedSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content`}
                id={`${id}-header`}
              >
                <QuestionTitle>{question}</QuestionTitle>
              </ExpandedSummary>
              <AccordionDetails>
                {answer.map(({ id: subId, subQuestion, subAnswer }) => (
                  <Accordion
                    key={subId}
                    disableGutters
                    elevation={0}
                    expanded={subSelected === subId}
                    onChange={handleSubChange(subId)}
                  >
                    <ExpandedSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${subId}-content`}
                      id={`${subId}-header`}
                    >
                      <QuestionSubTitle>{subQuestion}</QuestionSubTitle>
                    </ExpandedSummary>
                    <AccordionDetails>
                      <Question>{subAnswer}</Question>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </SectionContainer>
  );
}
